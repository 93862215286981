<template>
  <div>
    <h1 class="ma-2 ml-0">Pagamentos</h1>
    <v-card>
      <v-card-title
        class="d-flex align-center justify-space-between py-sm-2 py-6"
      >
        <div class="row">
          <v-text-field
            v-model="searchQuery"
            placeholder="Pesquisar"
            outlined
            hide-details
            dense
            class="user-search col-sm-6 pa-2"
            v-debounce:1000ms="search"
          >
          </v-text-field>
        </div>
        <div :class="{ 'w-full py-2': isMobile }">
          <v-btn
            color="success"
            class="ma-sm-2 my-2 text-capitalize"
            :block="isMobile"
            @click="newPayment()"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Nova Fatura</span>
          </v-btn>

          <v-btn
            color="secondary"
            class="ma-sm-2 my-2 text-capitalize"
            :block="isMobile"
          >
            <v-icon size="17" class="me-1">
              {{ icons.mdiExportVariant }}
            </v-icon>
            <span>Exportar</span>
          </v-btn>
        </div>
      </v-card-title>

      <v-data-table
        :items-per-page="15"
        v-model="selectedRows"
        :search="searchQuery"
        :headers="tableColumns"
        :items="payments"
        :loading="loading"
        :server-items-length="total"
        :options.sync="options"
        loading-text="Carregando Tabela..."
      >
        <template v-slot:item.invoice_url="{ item }">
          <a :href="item.invoice_url">{{ item.invoice_url }}</a>
        </template>

        <template v-slot:item.amount="{ item }">
          <span>{{ (item.amount / 100) | moneyBr }} </span>
        </template>

        <!-- status -->
        <template #[`item.status`]="{ item }">
          <v-chip
            small
            :color="resolvepaymentstatusVariant(item.status)"
            :class="`${resolvepaymentstatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            <div v-if="item.status == 'paid'">Pago</div>
            <div v-if="item.status == 'pending'">Pendente</div>
            <div v-if="item.status == 'canceled'">Cancelado</div>
            <div v-if="item.status == 'expired'">Expirado</div>
            <div v-if="item.status == 'refunded'">Reembolsado</div>
            <div v-if="item.status == 'chargeback'">Chargeback</div>
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                v-if="item.status == 'pending'"
                @click="duplicateInvoice(item.request)"
              >
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Segunda via</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="updateInvoice(item.request)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiReload }}
                  </v-icon>
                  <span>Atualizar fatura</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.status == 'pending'"
                @click="cancelInvoice(item.request)"
              >
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Cancelar</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item
                v-if="item.status == 'paid'"
                @click="refundInvoice(item.request)"
              >
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiCashRefund }}
                  </v-icon>
                  <span>Reembolsar</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <PaymentForm ref="paymentForm" />
  </div>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiDnsOutline,
  mdiPencilOutline,
  mdiPlus,
  mdiExportVariant,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiHome,
  mdiReload,
  mdiCashRefund,
} from "@mdi/js";
import Filter from "../../util/Filters";
import PaymentForm from "@/components/payments/forms/PaymentForm.vue";

export default {
  components: {
    PaymentForm,
  },

  data() {
    return {
      selectedRows: [],
      payments: [],
      roles: "",
      searchQuery: null,
      statusFilter: null,
      totalPaymentsTable: null,
      loading: false,

      options: {},
      page: null,
      total: null,
      pageCount: null,

      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiAccountCheckOutline,
        mdiAccountOutline,
        mdiAccountPlusOutline,
        mdiAccountRemoveOutline,
        mdiCogOutline,
        mdiDatabaseOutline,
        mdiDnsOutline,
        mdiPencilOutline,
        mdiExportVariant,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiHome,
        mdiReload,
        mdiCashRefund,
      },

      tableColumns: [
        { text: "Empresa", value: "company_name", sortable: false },
        { text: "Link", value: "invoice_url" },
        { text: "Data", value: "updated_at" },
        { text: "Valor", value: "amount" },
        { text: "Status", value: "status", sortable: false },
        {
          text: "ACTIONS",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
    };
  },

  created() {
    this.getpayments();
  },

  computed: {
    isMobile() {
      return window.innerWidth <= 600;
    },
  },

  watch: {
    options(newValue, oldValue) {
      let page = newValue.page;

      if (oldValue.page) {
        this.getpayments(page);
      }
    },
  },

  methods: {
    async getpayments(page = 1) {
      this.loading = true;
      await this.$http
        .index("/payments?page=" + page)
        .then((response) => {
          this.page = response.data.current_page;
          this.pageCount = response.data.last_page;
          this.total = response.data.total;
          this.payments = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    resolvepaymentstatusVariant(status) {
      if (status == "paid") return "success";
      if (status == "pending") return "warning";
      if (status == "canceled") return "error";

      return "primary";
    },

    editUser(content) {
      this.$refs.userForm.open(content.id);
    },

    search() {
      this.$http
        .index("/payments", { q: this.searchQuery })
        .then((response) => {
          this.payments = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateInvoice(json) {
      const requestJson = JSON.parse(json);
      this.$http
        .update(`invoice/update`, requestJson.id)
        .then((response) => {
          this.getpayments();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    cancelInvoice(json) {
      const requestJson = JSON.parse(json);
      this.$http
        .update(`invoice/cancel`, requestJson.id)
        .then((response) => {
          this.getpayments();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    duplicateInvoice(json) {
      const requestJson = JSON.parse(json);
      this.$http
        .store(`invoice/duplicate`, {
          id: requestJson.id,
          due_date: new Date(),
        })
        .then((response) => {
          this.getpayments();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    refundInvoice(json) {
      const requestJson = JSON.parse(json);
    },

    newPayment() {
      this.$refs.paymentForm.open();
    },
  },
};
</script>

<style></style>
