<template>
  <v-card flat>
    <v-card-actions class="d-flex align-center">
      <v-spacer></v-spacer>

      <v-file-input
        class="col-4"
        outlined
        label="Importar arquivo de vendas..."
        @change="fileLoaded($event)"
        hide-details
      ></v-file-input>
    </v-card-actions>
    <v-card-text>
      <v-data-table
        :headers="tableColumns"
        :items="processedOrders"
        v-model="selectedOrders"
        :loading="loading"
        no-data-text="Selecione um arquivo compativel (.xlsx)"
        dense
        loading-text="Processando Arquivo de Vendas..."
        show-select
        item-key="B"
        @toggle-select-all="filterSelected()"
      >
        <template #[`item.data-table-select`]="{ item, isSelected, select }">
          <v-simple-checkbox
            v-if="checkCEP(item.I) && !Object.values(item).includes(null)"
            :value="isSelected"
            @input="select($event)"
            :ripple="false"
          ></v-simple-checkbox>
        </template>
        <template #[`item.A`]="{ item }">
          <div :class="{'invalid d-flex': !item.B}">
            <span v-if="item.A">
              {{ item.A }}
            </span>
            <span v-else style="opacity: 0.3">
              Vazio
            </span>
          </div>
        </template>

        <template #[`item.B`]="{ item }">
          <div :class="{'invalid d-flex': !item.B}">
            <span v-if="item.B">
              {{ item.B }}
            </span>
            <span v-else style="opacity: 0.3">
              Vazio
            </span>
          </div>
        </template>

        <template #[`item.C`]="{ item }">
          <div :class="{'invalid d-flex': !item.C}">
            <span v-if="item.C">
              {{ item.C }}
            </span>
            <span v-else style="opacity: 0.3">
              Vazio
            </span>
          </div>
        </template>

        <template #[`item.D`]="{ item }">
          <div :class="{'invalid d-flex': !item.D}">
            <span v-if="item.E">
              <span v-if="item.D.toString().length > 11">{{item.D | cnpj}}</span>
              <span v-if="item.D.toString().length == 11">{{item.D | cpf}}</span>
              <span v-if="item.D.toString().length < 11 ">{{ item.D }}</span>
            </span>
            <span v-else style="opacity: 0.3">
              Vazio
            </span>
          </div>
        </template>

        <template #[`item.E`]="{ item }">
          <div :class="{'invalid d-flex': !item.E}">
            <span v-if="item.E">
              {{ item.E }}
            </span>
            <span v-else style="opacity: 0.3">
              Vazio
            </span>
          </div>
        </template>

        <template #[`item.F`]="{ item }">
          <div :class="{'invalid d-flex': !item.F}">
            <span v-if="item.F">
              {{ item.F }}
            </span>
            <span v-else style="opacity: 0.3">
              Vazio
            </span>
          </div>
        </template>

        <template #[`item.G`]="{ item }">
          <div :class="{'invalid d-flex': !item.G}">
            <span v-if="item.G">
              {{ item.G }}
            </span>
            <span v-else style="opacity: 0.3">
              Vazio
            </span>
          </div>
        </template>

        <template #[`item.H`]="{ item }">
          <div :class="{'invalid d-flex': !item.H}">
            <span v-if="item.H">
              {{ item.H }}
            </span>
            <span v-else style="opacity: 0.3">
              Vazio
            </span>
          </div>
        </template>

        <template #[`item.I`]="{ item }">
          <div :class="{'invalid d-flex': !item.I}">
            <span v-if="item.I">
              <v-tooltip bottom v-if="!checkCEP(item.I)">
                <template v-slot:activator="{on, attrs}">
                  <div class="invalid" v-bind="attrs" v-on="on">
                    <span>{{ item.I }}</span>
                  </div>
                </template>
                <span> CEP Invalido </span>
              </v-tooltip>
              <span v-else>
                {{ item.I | cep}}
              </span>
            </span>
            <span v-else style="opacity: 0.3">
              Vazio
            </span>
          </div>
        </template>

        <template #[`item.J`]="{ item }">
          <div :class="{'invalid d-flex': !item.J}">
            <span v-if="item.J">
              {{ item.J }}
            </span>
            <span v-else style="opacity: 0.3">
              Vazio
            </span>
          </div>
        </template>

        <template #[`item.K`]="{ item }">
          <div :class="{'invalid d-flex': !item.K}">
            <span v-if="item.K">
              {{ item.K | dateBR }}
            </span>
            <span v-else style="opacity: 0.3">
              Vazio
            </span>
          </div>
        </template>

        <template #[`item.L`]="{ item }">
          <div :class="{'invalid d-flex': !item.L}">
            <span v-if="item.M">
              {{ item.L | dateBR }}
            </span>
            <span v-else style="opacity: 0.3">
              Vazio
            </span>
          </div>
        </template>

        <template #[`item.M`]="{ item }">
          <div :class="{'invalid d-flex': !item.M}">
            <span v-if="item.M">
              {{ item.M | moneyBr }}
            </span>
            <span v-else style="opacity: 0.3">
              Vazio
            </span>
          </div>
        </template>

        <template #[`item.N`]="{ item }">
          <div :class="{'invalid d-flex': !item.N}">
            <span v-if="item.N">
              {{ item.N }}
            </span>
            <span v-else style="opacity: 0.3">
              Vazio
            </span>
          </div>
        </template>

      </v-data-table>
    </v-card-text>
    <v-card-actions class="d-flex align-center">
      <v-spacer></v-spacer>
      <span>As entradas com campos marcados em vermelho indicam valores invalidos e não podem ser importadas ao sistema.</span>
      <v-spacer></v-spacer>
      <v-btn color="success" :disabled="!selectedOrders.length" @click="importSelectedOrders" class="mx-4">
        Importar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import Filters from '@/util/Filters'

export default {
  name: "ImportOrdersTab",
  data() {
    return {
      loading: false,
      tableReady: false,
      file: {},
      tableColumns: [
        { text: "Tipo", value: "A" },
        { text: "Transação", value: "B" },
        { text: "Nome", value: "C" },
        { text: "Documento", value: "D" },
        { text: "Producto", value: "E" },
        { text: "Id do Produto", value: "F" },
        { text: "Lougradouro", value: "G" },
        { text: "Bairro", value: "H" },
        { text: "CEP", value: "I" },
        { text: "Numero", value: "J" },
        { text: "Data da Venda", value: "K" },
        { text: "Data da Fatura", value: "L" },
        { text: "Valor", value: "M" },
        { text: "Plataforma", value: "N" },
      ],
      processedOrders: [],
      selectedOrders: [],
    };
  },

  methods: {
    fileLoaded(event) {
      this.selectedOrders = [];
      this.processedOrders = [];
      if (event) {
        this.file = event;
        this.loading = true;

        let formData = new FormData();

        formData.append("file", this.file);
        
        // TODO implementar conexão ao backend como admin

        // this.$http.$post("/order-load", formData).then((response) => {
        //   for (var result in response) {
        //     this.processedOrders.push(response[result]);
        //     this.loading = false;
        //   }
        // });
      }
    },

    importSelectedOrders(){
      console.log(this.selectedOrders);
      this.$emit('import')

      // TODO implementar conexão ao backend como admin

      // this.$http.$post('/order-import', this.selectedOrders).then(res => {
      //   console.log(res);
      // });
    },

    filterSelected(){
      if (this.selectedOrders.length == 0) {
        this.selectedOrders = this.processedOrders.filter( e => {
          return this.checkCEP(e.I) && !Object.values(e).includes(null)
        })        
      }else{
        this.selectedOrders = []
      }
    },

  
    checkCEP(cep){
      if (!cep) {
        return false
      }
      return /^[0-9]{8}$/.test(cep)
    },

    checkCNPJ(entry) {
      if (!entry) {
        return false
      }
      let cnpj = entry.toString().replace(/[^\d]+/g,'');

      if(cnpj == '') return false;
      
      if (cnpj.length != 14){
          return false;
      }

      if (cnpj in ["00000000000000", "11111111111111", "22222222222222", "33333333333333", "44444444444444", "55555555555555", "66666666666666", "77777777777777", "88888888888888", "99999999999999"])
        return false

      let tamanho = cnpj.length - 2
      let numeros = cnpj.substring(0,tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
              pos = 9;
      }
      let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(0))
          return false;
          
      tamanho = tamanho + 1;
      numeros = cnpj.substring(0,tamanho);
      soma = 0;
      pos = tamanho - 7;
      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2)
              pos = 9;
      }
      resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
      if (resultado != digitos.charAt(1))
            return false;

      return true;
    }

  },
};
</script>

<style >

.invalid{
  border: solid red 3px;
  border-style: dashed;
  padding: 10px;
}

</style>