<template>
    <v-dialog v-model="isDialogVisible" persistent width="500">
        <v-card>
            <v-card-title class="headline">
                Atenção
            </v-card-title>
            <v-card-text>Você está prestes a logar como {{ user.name }} .</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" outlined @click="isDialogVisible = false">
                    Cancelar
                </v-btn>

                <v-btn color="success" @click="impersonateUser">
                    Logar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
  
<script>
export default {
    data() {
        return {
            isDialogVisible: false,
            user: {},
        }
    },

    methods: {
        open(user) {
            this.user = user
            this.isDialogVisible = true
        },

        impersonateUser() {
            this.$emit('impersonate', this.user.tenant_id)
            this.isDialogVisible = false
        }

    }
}
</script>