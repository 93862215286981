
/*
* Constants of options to inserting in selects of UI
*
*
*/

  export const OPTIONS_CRT = [
    { text: 'Simples Nacional', value: '1' },
    { text: 'Simples Nacional – excesso de sublimite da receita bruta', value: '2' },
    { text: 'Regime Normal', value: '3' },
  ]
  
  export const OPTIONS_UF = [
    { text: 'Acre', value: 'AC' },
    { text: 'Alagoas', value: 'AL' },
    { text: 'Amapá', value: 'AP' },
    { text: 'Amazonas', value: 'AM' },
    { text: 'Bahia', value: 'BA' },
    { text: 'Ceará', value: 'CE' },
    { text: 'Distrito Federal', value: 'DF' },
    { text: 'Espirito Santo', value: 'ES' },
    { text: 'Goiás', value: 'GO' },
    { text: 'Maranhão', value: 'MA' },
    { text: 'Mato Grosso', value: 'MT' },
    { text: 'Mato Grosso do Sul', value: 'MS' },
    { text: 'Minas Gerais', value: 'MG' },
    { text: 'Pará', value: 'PA' },
    { text: 'Paraíba', value: 'PB' },
    { text: 'Paraná', value: 'PR' },
    { text: 'Pernambuco', value: 'PE' },
    { text: 'Piauí', value: 'PI' },
    { text: 'Rio de Janeiro', value: 'RJ' },
    { text: 'Rio Grande do Norte', value: 'RN' },
    { text: 'Rio Grande do Sul', value: 'RS' },
    { text: 'Rondônia', value: 'RO' },
    { text: 'Roraima', value: 'RR' },
    { text: 'Santa Catarina', value: 'SC' },
    { text: 'São Paulo', value: 'SP' },
    { text: 'Sergipe', value: 'SE' },
    { text: 'Tocantins', value: 'TO' },
  ];
  
  export const OPTIONS_INTERVAL_TYPE = [
    { value: "months", text: "Mensal" },
    { value: "weeks", text: "Semanas" },
  ];

  export const OPTIONS_PARCELS = [
    { value: "Ilimitado", text: "Ilimitado" },
    { value: "1", text: "1" },
    { value: "2", text: "2" },
    { value: "3", text: "3" },
    { value: "4", text: "4" },
    { value: "5", text: "5" },
    { value: "6", text: "6" },
    { value: "7", text: "7" },
    { value: "8", text: "8" },
    { value: "9", text: "9" },
    { value: "10", text: "10" },
    { value: "11", text: "11" },
    { value: "12", text: "12" },
  ];

  export const OPTIONS_CONTRACT = [
    { text: "Ativo", value: "active" },
    { text: "Inativo", value: "inactive" },
    { text: "Cancelado", value: "canceled" }
  ];
  
  export const options = [{
    uf: OPTIONS_UF,
    crt: OPTIONS_CRT,
    intervalType: OPTIONS_INTERVAL_TYPE,
    parcels: OPTIONS_PARCELS,
    contract: OPTIONS_CONTRACT,
  }];
  