var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Pesquisar","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-spacer')],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.users,"options":_vm.options,"server-items-length":_vm.totalUserListTable,"loading":_vm.loading,"loading-text":"Carregando Tabela...","show-select":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:item.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.avatar ? '' : 'primary',"size":"32"}},[(item.avatar)?_c('v-img',{attrs:{"src":require(("@/assets/images/avatars/" + (item.avatar)))}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.name)))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name: 'users', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('small',[_vm._v("@"+_vm._s(item.name))])],1)],1)]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:("v-avatar-light-bg " + (_vm.resolveUserRoleVariant(item.roles[0].name)) + "--text me-3"),attrs:{"size":"30","color":_vm.resolveUserRoleVariant(item.roles[0].name)}},[_c('v-icon',{attrs:{"size":"18","color":_vm.resolveUserRoleVariant(item.roles[0].name)}},[_vm._v(" "+_vm._s(_vm.resolveUserRoleIcon(item.roles[0].name))+" ")])],1),_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.roles[0].name))])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveUserStatusVariant(item.status)) + "--text"),attrs:{"small":"","color":_vm.resolveUserStatusVariant(item.status)}},[(item.status === 1)?_c('div',[_vm._v("Ativo")]):_vm._e(),(item.status === 0)?_c('div',[_vm._v("Inativo")]):_vm._e()])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.changeStatus(item.id)}}},[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Inativar")])],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }