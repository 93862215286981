<template>
  <v-card>
    <v-card-title class="align-start">
      <span> Empresas Inadimplentes </span>
    </v-card-title>

    <v-card-text>
      <v-data-table
        :items-per-page="5"
        :page.sync="pageCount"
        :headers="tableColumns"
        :items="users"
        hide-default-footer
      >

      <template v-slot:item.company="{ item }">
        <div class="d-flex align-center">
          <v-avatar
            color="error"
            class="v-avatar-light-bg error--text"
            size="32"
          >
            <v-img :src="require(`@/assets/images/pages/overdueCompany.png`)"></v-img>
          </v-avatar>

          <div class="d-flex flex-column ms-3">
            {{ item.company.name }}
          </div>
        </div>
      </template>

        <template v-slot:item.value="{ item }">
          <span>{{ item.value | moneyBr }}</span>
        </template>

        <template #[`footer`] class="d-flex">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCalendarBlankOutline, mdiDotsVertical } from "@mdi/js";
import Filter from "@/util/Filters";

export default {
  props: {
    initialData: Array,
  },

  data() {
    return {
      selectedRows: [],
      users: [],
      options: {
        sortBy: ["id"],
        sortDesc: [true],
      },
      page: null,
      pageCount: null,

      tableColumns: [
        { text: "NOME", value: "company" },
        { text: "EMAIL", value: "company.email" },
        { text: "VALOR", value: "value", sortable: false },
      ],

      icons: {
        mdiDotsVertical,
        mdiCalendarBlankOutline,
      },
    };
  },

  watch: {
    async initialData(val) {
      this.users = await val;
    },
  },
};
</script>
