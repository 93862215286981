<template>
    <v-card>
        <v-card-text class="d-flex align-center flex-wrap pb-0">
            <v-text-field 
                v-model="searchQuery"
                placeholder="Pesquisar"
                outlined hide-details dense
                class="user-search me-3 mb-4"
            ></v-text-field>
            <v-spacer></v-spacer>
        </v-card-text>
        <v-data-table
            :headers="tableColumns"
            :items="logs"
            :loading="loading"
            loading-text="Carregando Tabela..."
        >
        </v-data-table>
    </v-card>
</template>

<script>
export default {
    name: 'LogsTab',
    data(){
        return {
            searchQuery: null,

            tableColumns: [
                {text: 'Nome', value: 'name'},
                {text: 'Ação', value: 'action'},
                {text: 'Registro', value: 'register'},
                {text: 'Status', value: 'status'}
            ],

            logs: [],
            loading: false
        }
    },

    created(){
        this.getLogs()
    },

    methods:{
        // TODO implementar conexão ao backend como admin
        getLogs(){
            console.log('getLogs');
            this.loading = true
            this.logs = [
                {name: 'Nome', action: 'Falha hein...', register:'id:2', status:'status'}
            ]
            this.loading = false
        }
    }

}
</script>

<style>

</style>