import Vue from 'vue'
import VueRouter from 'vue-router'
import authMiddleware from './middlewares/authMiddleware'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home',
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/empresas',
    name: 'companies',
    component: () => import('@/views/Companies/Companies.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/planos',
    name: 'plans',
    component: () => import('@/views/Plans/Plan.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/contratos',
    name: 'contracts',
    component: () => import('@/views/Contracts/Contract.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/modelos-contratos',
    name: 'contracts-model',
    component: () => import('@/views/ContractsModels/ContractModel.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/empresa/:id',
    name: 'company',
    component: () => import('@/views/Companies/Company.vue'),
    meta: {
      layout: 'content',
    },
  },  
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard/Dashboard.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/logs-falhas',
    name: 'logs-failures',
    component: () => import('@/views/Failures/Failures.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/pagamentos',
    name: 'payments',
    component: () => import('@/views/Payments/Payments.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/emails',
    name: 'emails',
    component: () => import('@/views/Emails/Emails.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/filas-pendentes',
    name: 'queue-pending',
    component: () => import('@/views/Queues/PendingJobs/PendingJobs.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/filas-falhando',
    name: 'queue-failures',
    component: () => import('@/views/Queues/FailuresJobs/FailuresJobs.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/relatorios',
    name: 'reports',
    component: () => import('@/views/Reports/Reports.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Auth/Login.vue'),
    meta: {
      layout: 'blank', auth: 'guest',
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/users/User.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/service-code',
    name: 'service-code',
    component: () => import('@/views/serviceCode/ServiceCode.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  authMiddleware(to, from, next)
})

export default router
