<template>
  <v-dialog v-model="dialog" max-width="500px" origin="center center">
    <v-card>
      <v-card-title>
        <span> Filtrar Vendas </span>
        <v-progress-circular indeterminate v-if="loading" class="ma-2" color="secondary"></v-progress-circular>
      </v-card-title>
      <v-card-text>
        <div class="row">
          <div class="col-12">
            <v-text-field v-model="filter.email" label="E-mail do Admin" item-text="name" dense filled outlined
              hide-details="auto"></v-text-field>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="submitFilter()"> Aplicar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Filter from "@/util/Filters";
import moneyInput from "@/components/app/ui/moneyInput.vue";
import datePicker from "@/components/app/ui/DatePicker.vue";
import formMixin from "@/util/mixins/formMixin";

export default {
  components: {
    moneyInput,
    datePicker,
  },

  mixins: [formMixin],

  data() {
    return {
      dialog: false,
      loading: false,

      date: null,

      filter: {
        email: null,
        is_filter: true,
      },

      touched: false,
    };
  },

  watch: {
    filter: {
      deep: true,
      handler() {
        this.touched = true;
      },
    },
  },

  methods: {
    open() {
      this.filter = {
        email: null,
        is_filter: true,
      };
      this.touched = false;
      this.dialog = true;
    },

    submitFilter() {
      if (this.touched) {
        this.$emit("filter", this.filter);
      }

      this.dialog = false;
      this.loading = false;
    },
  },
};
</script>

<style>
.v-text-field__prefix {
  margin-top: 0px !important;
}
</style>
