import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './app'
import auth from './modules/app/auth'
import errorHandler from './modules/app/errorHandler'
import notification from './modules/app/notification'
import companies from './modules/companies'
import codeService from './modules/codeService'
import plans from './modules/plans'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    auth,
    errorHandler,
    notification,
    companies,
    plans,
    codeService
  },
})
