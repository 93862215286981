<template>
  <v-menu v-model="menuData" :close-on-content-click="!range" absolute>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        outlined
        dense
        block
        class="text-capitalize"
        :color="value ? 'success' : 'secondary'"
      >
        {{ value ? valueLabel : label }}
      </v-btn>
    </template>
    <v-card>
      <v-card-text class="pa-0">
        <v-date-picker
          @input="handleInput"
          scrollable
          :range="range"
          v-model="value"
          locale="pt-BR"
        ></v-date-picker>
      </v-card-text>
      <v-card-actions class="pa-2" v-if="range">
        <v-btn block color="secondary" @click="() => {menuData = false}">
          ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    range: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "Data",
    },
    hidden: {},
  },
  model: {
    prop: "hidden",
    event: "input",
  },
  data() {
    return {
      menuData: null,
      value: null,
    };
  },
  computed: {
    valueParsed() {
      if (this.range) {
        if (!this.value[1]) {
          return [
            this.value[0].replaceAll("-", "/"),
            this.value[0].replaceAll("-", "/"),
          ];
        } else {
          return [
            this.value[0].replaceAll("-", "/"),
            this.value[1].replaceAll("-", "/"),
          ];
        }
      }
      return this.value.replaceAll("-", "/");
    },
    valueLabel() {
      if (this.range) {
        if (this.value.length > 1) {
          return (
            this.value[0].substr(5, 5) + " á " + this.value[1].substr(5, 5)
          );
        } else {
          return this.value[0].substr(5, 5);
        }
      } else {
        return this.value.substr(5);
      }
    },
  },
  methods: {
    handleInput(value) {
      if (value != null) {
        this.value = value;
        return this.$emit("input", this.valueParsed);
      }
    },
    reset(value) {
      this.value = value ? value : null;
    },
  },
};
</script>
