<template>
  <div>
    <h1 class="ma-2 ml-0">Modelos de Contrato</h1>
    <v-card>
      <v-card-title
        class="d-flex align-center justify-space-between py-sm-2 py-6"
      >
        <div class="row">
          <v-text-field
            v-model="searchQuery"
            placeholder="Pesquisar"
            outlined
            hide-details
            dense
            class="user-search col-sm-6 pa-2"
          >
          </v-text-field>
        </div>

        <div :class="{ 'w-full py-2': isMobile }">
          <v-btn
            color="success"
            class="ma-sm-2 my-2 text-capitalize"
            :block="isMobile"
            @click="openContractModelsForm()"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Novo Plano</span>
          </v-btn>
        </div>
      </v-card-title>

      <v-data-table
        :items-per-page="25"
        :page.sync="pageCount"
        v-model="selectedRows"
        :search="searchQuery"
        :headers="tableColumns"
        :items="contractModels"
        loading-text="Carregando Tabela..."
      >
        <template v-slot:item.actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="openContractModelsForm(item.id)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Editar</span>
                </v-list-item-title>
              </v-list-item>
                 <v-list-item @click="deleteContractModel(item.id)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiTrashCanOutline }}
                  </v-icon>
                  <span>Excluir</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <ContractModelForm ref="contractModelForm" @onSubmit="getContractModels" />
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiTrashCanOutline,
} from "@mdi/js";

import ContractModelForm from "@/components/contractModels/forms/ContractModelForm.vue";
import Filter from "@/util/Filters";

export default {
  components: { ContractModelForm },

  data() {
    return {
      selectedRows: [],
      contractModels: [],
      searchQuery: null,
      page: null,
      pageCount: null,

      icons: {
        mdiPlus,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiTrashCanOutline
      },

      tableColumns: [
        { text: "HASH", value: "hash", sortable: false },
        { text: "NOME", value: "name", sortable: false },
        {
          text: "AÇÃO",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
    };
  },

  created() {
    this.getContractModels();
  },

  computed: {
    isMobile() {
      return window.innerWidth <= 600;
    },
  },

  methods: {
    getContractModels() {
      this.loading = true;
      this.$http
        .index("/contract-models")
        .then((response) => {
          this.contractModels = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteContractModel(id){
      console.log(id);
      this.$http
        .$delete(`/contract-models/${id}`)
        .then((response) => {
          this.getContractModels();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    openContractModelsForm(id = null) {
      this.$refs.contractModelForm.open(id);
    },
  },
};
</script>

<style></style>
