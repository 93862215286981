<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="800px"
      origin="center center"
      persistent
      :fullscreen="isMobile"
    >
      <v-card>
        <v-card-title
          >{{ isUpdating ? "Editar" : "Cadastrar" }} Plano</v-card-title
        >
        <v-divider></v-divider>
        <v-card-title>
          <small>Dados</small>
        </v-card-title>
        <div v-if="loading" class="d-flex justify-center">
          <v-progress-circular
            indeterminate
            color="secondary"
          ></v-progress-circular>
        </div>
        <v-form v-if="!loading" v-model="formValid" ref="form">
          <v-card-text>
            <div class="row col-xs">
              <div class="col-md-12">
                <v-text-field
                  v-model="planForm.integration_plan_id"
                  label="Id do plano na Iugu"
                  outlined
                  hide-details="auto"
                  dense
                  disabled
                />
              </div>
            </div>
            <div class="row col-xs">
              <div class="col-md-12">
                <v-select
                  v-model="planForm.contract_model_hash"
                  :items="contractModels"
                  item-text="name"
                  item-value="hash"
                  label="Modelo de contrato"
                  outlined
                  hide-details="auto"
                  dense
                />
              </div>
            </div>
            <div class="row col-xs">
              <div class="col-md-8">
                <v-text-field
                  v-model="planForm.name"
                  label="Nome"
                  :rules="[rules.required]"
                  outlined
                  hide-details="auto"
                  dense
                />
              </div>
              <div class="col-md-4">
                <v-text-field
                  v-model="planForm.identifier"
                  label="Identificador Iugu"
                  outlined
                  hide-details="auto"
                  dense
                />
              </div>
            </div>
            <div class="row col-xs">
              <div class="col-md-6">
                <v-select
                  v-model="planForm.interval_type"
                  :items="intervalTypeOptions"
                  label="Tipo de Intervalo"
                  outlined
                  hide-details="auto"
                  dense
                />
              </div>
              <div class="col-md-6">
                <v-text-field
                  v-model="planForm.installments"
                  type="number"
                  label="Intervalo"
                  outlined
                  hide-details="auto"
                  dense
                />
              </div>
            </div>

            <div class="row col-xs">
              <div class="col-md-4">
                <money-input
                  ref="firstInstallmentRef"
                  :value="planForm.value_first_installment"
                  v-model="planForm.value_first_installment"
                  label="Valor primeira parcela"
                  outlined
                  hide-details="auto"
                  dense
                />
              </div>
              <div class="col-md-4">
                <money-input
                  ref="otherInstallmentsRef"
                  :value="planForm.value_others_installments"
                  v-model="planForm.value_others_installments"
                  label="Valor outras parcelas"
                  outlined
                  hide-details="auto"
                  dense
                />
              </div>
              <div class="col-md-4">
                <money-input
                  ref="valueRef"
                  :value="planForm.value"
                  v-model="planForm.value"
                  label="Valor total"
                  outlined
                  hide-details="auto"
                  dense
                />
              </div>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-title>
            <small>Recursos</small>
          </v-card-title>
          <v-card-text>
            <div class="row col-xs">
              <div class="col-md-4">
                <v-select
                  v-model="planForm.productors"
                  :items="options"
                  label="Produtores"
                  outlined
                  hide-details="auto"
                  dense
                />
              </div>
              <div class="col-md-4">
                <v-select
                  v-model="planForm.users"
                  :items="options"
                  label="Usuarios"
                  outlined
                  hide-details="auto"
                  dense
                />
              </div>
              <div class="col-md-4">
                <v-select
                  v-model="planForm.invoices"
                  :items="options"
                  label="Notas Fiscais por ano"
                  outlined
                  hide-details="auto"
                  dense
                />
              </div>
            </div>
            <v-row>
              <v-col cols="12" sm="4" md="4">
                <v-switch
                  v-model="planForm.irpf"
                  label="IRPF"
                  color="success"
                  value="1"
                  hide-details
                ></v-switch>
                <v-switch
                  v-model="planForm.irpj"
                  label="IRPJ"
                  color="success"
                  value="1"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-switch
                  v-model="planForm.tax_domicile"
                  label="Domicilio fiscal"
                  color="success"
                  :value="1"
                  hide-details
                ></v-switch>
                <v-switch
                  v-model="planForm.open_company"
                  label="Abertura de empresa"
                  color="success"
                  :value="1"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="4" md="4">
                <v-switch
                  v-model="planForm.suport_whatsapp"
                  label="Suporte whatsapp"
                  color="success"
                  :value="1"
                  hide-details
                ></v-switch>
                <v-switch
                  v-model="planForm.suport_phone"
                  label="Suporte telefone"
                  color="success"
                  :value="1"
                  hide-details
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            class="text-capitalize"
            @click="close"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="text-capitalize"
            color="success"
            @click="callSubscription()"
          >
            <v-icon>{{ icons.mdiContentSave }}</v-icon>
            Salvar Informações
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiContentSave } from "@mdi/js";
import { OPTIONS_INTERVAL_TYPE, OPTIONS_PARCELS } from "@/constants/option";
import moneyInput from "@/components/app/ui/moneyInput.vue";
import { th } from 'date-fns/locale';

export default {
  components:{
    moneyInput
  },

  data() {
    return {
      dialog: false,

      icons: {
        mdiContentSave,
      },

      intervalTypeOptions: OPTIONS_INTERVAL_TYPE,
      options: OPTIONS_PARCELS,

      planForm: {
        id: null,
        name: null,
        identifier: null,
        integration_plan_id: null,
        contract_model_hash: null,
        interval: 1,
        interval_type: null,
        installments: null,
        value_first_installment: true,
        value_others_installments: null,
        value: null,
        productors: null,
        users: null,
        invoices: null,
        irpf: null,
        irpj: null,
        tax_domicile: null,
        open_company: null,
        suport_whatsapp: null,
        suport_phone: null,
        suport_email: null,
        payable_with: "all",
      },

      contractModels: [],

      isUpdating: false,
      formValid: null,
      loading: false,

      rules: {
        required: (v) => !!v || "Campo obrigatório.",
      },
    };
  },

  computed: {
    isMobile() {
      return window.innerWidth <= 600;
    },
  },

  watch:{
    dialog(){
      if(! this.dialog){
        this.$refs.firstInstallmentRef.reset()
        this.$refs.otherInstallmentsRef.reset()
        this.$refs.valueRef.reset()
        this.$refs.form.reset()
        this.isUpdating = false
      }
    }
  },

  methods: {
    open(id) {
      if (id) {
        this.planForm.id = id;
        this.isUpdating = true;
        this.getPlanData(id);
      }

      this.getContractModels();
      this.dialog = true;
    },

    close(){
      this.dialog = false
      this.$refs.form.reset()
    },

    async getPlanData(id) {
      await this.$http
        .$get(`/plans/${id}`)
        .then((response) => {
          if(response.value){
            response.value = Number(response.value.split('.')[0])
          }

          if(response.value_first_installment){
            response.value_first_installment = Number(response.value_first_installment.split('.')[0])
          }

          if(response.value_others_installments){
            response.value_others_installments = Number(response.value_others_installments.split('.')[0])
          }

          this.planForm = response;
         
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getContractModels() {
      this.loading = true;
      this.$http
        .index("/contract-models")
        .then((response) => {
          this.contractModels = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    callSubscription() {
      this.isUpdating == true ? this.updateForm() : this.submitForm();
    },

    submitForm() {
      this.$http
        .$post(`/plans`, this.planForm)
        .then((response) => {
          this.dialog = false
          window.location.reload()
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateForm() {
      this.$http
        .$put(`/plans/${this.planForm.id}`, this.planForm)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
