import Vue from "vue";

export default {
  namespaced: true,

  state: () => ({
    codeServices: [],
    codeServiceType:[
        {"name":"Afiliado", "value":"AFFILIATE"},
        {"name":"Produtor", "value":"SELLER"},
        {"name":"Dropshipper", "value":"DROPSHIPPER"},
    ]
  }),

  mutations: {
    setCodeServices(state, data) {
      state.codeServices = data;
    },
    appendCodeServices(state, data) {
        state.codeServices.push(data);
    },
    unsetCodeService(state,data){
        state.codeServices = state.codeServices.filter(service => service.id !== data);
    }
  },

  actions: {
    getCodeServices({ commit, state }) {
      Vue.$http
        .index("/code-service")
        .then((response) => {
          commit("setCodeServices", response.data.data);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    storeCodeService({ commit, state }, data) {
      Vue.$http
        .$post(`/code-service`, data)
        .then((response) => {
          this.dialog = false;
          commit("appendCodeServices", response.response)
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteCodeService({ commit, state }, id) {
        Vue.$http
          .$delete(`/code-service/${id}`,)
          .then((response) => {
            commit("unsetCodeService", id)
          })
          .catch((error) => {
            console.log(error);
          });
      },
  },
};
