<template>
  <div>
    <h1 class="ma-2 ml-0">Usuários</h1>
    <v-card>
      <v-card-title
        class="d-flex align-center justify-space-between py-sm-2 py-6"
      >
        <div class="row">
          <v-text-field
            v-model="searchQuery"
            placeholder="Pesquisar"
            outlined
            hide-details
            dense
            class="user-search col-sm-6 pa-2"
          >
          </v-text-field>
        </div>
        <div :class="{ 'w-full py-2': isMobile }">
          <v-btn
            color="success"
            class="ma-sm-2 my-2 text-capitalize"
            :block="isMobile"
            @click="newUser()"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Novo Usuário</span>
          </v-btn>
        </div>
      </v-card-title>

      <v-data-table
        v-model="selectedRows"
        :search="searchQuery"
        :headers="tableColumns"
        :items="users"
        :options.sync="options"
        :loading="loading"
        loading-text="Carregando Tabela..."
        show-select
        :hide-default-footer="true"
      >
        <!-- name -->
        <template #[`item.name`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img
                v-if="item.avatar"
                :src="require(`@/assets/images/avatars/${item.avatar}`)"
              ></v-img>
              <span v-else class="font-weight-medium">{{
                avatarText(item.name)
              }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'users', params: { id: item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.name }}
              </router-link>
              <small>@{{ item.name }}</small>
            </div>
          </div>
        </template>

        <!-- role -->
        <template #[`item.roles`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar
              size="30"
              :color="resolveUserRoleVariant(item.roles[0].name)"
              :class="`v-avatar-light-bg ${resolveUserRoleVariant(
                item.roles[0].name
              )}--text me-3`"
            >
              <v-icon
                size="18"
                :color="resolveUserRoleVariant(item.roles[0].name)"
              >
                {{ resolveUserRoleIcon(item.roles[0].name) }}
              </v-icon>
            </v-avatar>

            <span class="text-capitalize">{{ item.roles[0].name }}</span>
          </div>
        </template>

        <!-- status -->
        <template #[`item.status`]="{ item }">
          <v-chip
            small
            :color="resolveUserStatusVariant(item.status)"
            :class="`${resolveUserStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            <div v-if="item.status === 1">Ativo</div>
            <div v-if="item.status === 0">Inativo</div>
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="editUser(item)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Editar</span>
                </v-list-item-title>
              </v-list-item>

              <template v-if="item.status == true">
                <v-list-item @click="openAlertConfirmationInactive(item)">
                  <v-list-item-title>
                    <v-icon size="20" class="me-2">
                      {{ icons.mdiDeleteOutline }}
                    </v-icon>
                    <span> Inativar</span>
                  </v-list-item-title>
                </v-list-item>
              </template>

              <template v-else>
                <v-list-item @click="openAlertConfirmationInactive(item)">
                  <v-list-item-title>
                    <v-icon size="20" class="me-2">
                      {{ icons.mdiCheckOutline }}
                    </v-icon>
                    <span>Ativar</span>
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <div class="d-flex justify-end">
        <v-pagination
          class="paginate"
          v-model="currentPage"
          :length="lastPage"
          total-visible="6"
        ></v-pagination>
      </div>
    </v-card>
    <UserForm ref="userForm" @onSubmit="getUsers" />

    <Alert
      ref="alert"
      @inactive="inactiveUser($event)"
      @active="activeUser($event)"
    />
  </div>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiAccountCheckOutline,
  mdiCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiDnsOutline,
  mdiPencilOutline,
  mdiPlus,
  mdiExportVariant,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiHome,
} from "@mdi/js";

import UserForm from "@/components/user/forms/UserForm";
import Alert from "@/components/app/alert/Alert";

export default {
  components: { UserForm, Alert },

  data() {
    return {
      selectedRows: [],
      users: [],
      roles: "",
      isinactive: false,
      searchQuery: null,
      roleFilter: null,
      planFilter: null,
      statusFilter: null,
      totalUserListTable: null,
      loading: false,
      inactivateDialog: false,
      options: {
        sortBy: ["id"],
        sortDesc: [true],
      },

      currentPage: null,
      lastPage: null,

      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiAccountCheckOutline,
        mdiCheckOutline,
        mdiAccountOutline,
        mdiAccountPlusOutline,
        mdiAccountRemoveOutline,
        mdiCogOutline,
        mdiDatabaseOutline,
        mdiDnsOutline,
        mdiPencilOutline,
        mdiExportVariant,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiHome,
      },

      tableColumns: [
        { text: "USER", value: "name" },
        { text: "EMAIL", value: "email" },
        { text: "PERFIL", value: "roles", sortable: false },
        { text: "STATUS", value: "status", sortable: false },
        {
          text: "ACTIONS",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],

      userModal: false,
    };
  },

  created() {
    this.getUsers();
  },

  computed: {
    isMobile() {
      return window.innerWidth <= 600;
    },
  },

  watch: {
    currentPage(newValue) {
      this.getUsers();
    },
  },

  methods: {
    getUsers() {
      this.loading = true;
      this.$http
        .index(`/users?page=${this.currentPage}`, {
          tenant_id: this.$store.state.auth.user.user.tenant_id,
        })
        .then((response) => {
          this.users = response.data.data;
          this.totalUserListTable = response.data.total;
          this.loading = false;
          this.currentPage = response.data.current_page;
          this.lastPage = response.data.last_page;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },

    resolveUserRoleVariant(role) {
      if (role === "accountant") return "primary";
      if (role === "admin") return "warning";
      if (role === "user") return "success";

      return "primary";
    },

    resolveUserRoleIcon(role) {
      if (role === "accountant") return mdiAccountOutline;
      if (role === "admin") return mdiCogOutline;
      if (role === "user") return mdiDatabaseOutline;

      return mdiAccountOutline;
    },

    resolveUserStatusVariant(status) {
      if (status === 1) return "success";
      if (status === 0) return "secondary";

      return "primary";
    },

    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");

      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },

    newUser() {
      this.$refs.userForm.open();
    },

    editUser(content) {
      this.$refs.userForm.open(content.id);
    },

    openAlertConfirmationInactive(item) {
      this.$refs.alert.open(item);
    },

    inactiveUser(user) {
      this.$http
        .$put(`/users/${user.id}`, user)
        .then(() => {
          this.getUsers();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    activeUser(user) {
      this.$http
        .$put(`/users/${user.id}`, user)
        .then(() => {
          this.getUsers();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    changeStatus(id) {
      console.log("muda status", id);
    },
  },
};
</script>

<style>
.paginate {
  float: right;
}
</style>
