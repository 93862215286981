<template>
  <v-row justify="center">
    <v-dialog v-model="dialog">
      <v-card>
        <v-card-title>
          <span class="text-h5">Payload</span>
        </v-card-title>
        <v-card-text>
          <vue-code-highlight language="json">
                {{payload}} 
          </vue-code-highlight>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {component as VueCodeHighlight} from "vue-code-highlight";

export default {
  components: {
    VueCodeHighlight,
  },

  data() {
    return {
      payload: [],
      dialog: false,
    };
  },
  methods: {
    open(payload) {
      this.payload = payload;
      this.dialog = true;
    }
  },
};
</script>