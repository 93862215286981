import Swal from "sweetalert2";

export default {

  /**
   * Mostra apenas uma mensagem de erro
   * @param msg
   */
  showError(msg) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      background: "error",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "error",
      title: this.extractErrorMessage(msg),
    });
  },

  /**
   * Mostra apenas uma mensagem de sucesso
   * @param msg
   */
  showSuccessMsg(msg) {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      background: "success",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "success",
      title: this.extractSuccessMessage(msg),
    });
  },

  extractErrorMessage(msg) {
    if (msg.response.data.code === 402) {
      window.location.href = `https://app-staging.ezcont.com/subscribed/?${msg}`;
    }

    if (msg.response.status === 401) {
      return msg.response.data.error;
    }

    if (msg.response) {
      return msg.response.data.message;
    }
    return msg;
  },

  extractSuccessMessage(msg) {
    if (msg.data) {
      return msg.data.message;
    }
    return msg;
  },
};
