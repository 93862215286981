import Vue from 'vue'
import Http from '../api/Http.js'

const baseURL = (process.env.NODE_ENV === 'production') ? 'https://api.ezcont.com.br/admin/' : 'http://ezcont.test/admin/'

Vue.use({
  install(Vue) {
    const http = new Http({
      baseURL,
    })

    Vue.prototype.$http = http
    Vue.$http = http
  },
})
export default (_) => { }
