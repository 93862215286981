
import Vue from 'vue'

export default {
  namespaced: true,

  state: () => ({
    plans: [],
    plan: {},
  }),

  mutations: {
    setPlans(state, data) {
      state.plans = data;
    },

    setPlan(state, data) {
        state.plan = data;
      },
  },

  actions: {
    getPlans({ commit, state }) {
        Vue.$http
        .index("/plans")
        .then((response) => {
            commit('setPlans', response.data);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    getPlan({ commit, state }, id) {
        Vue.$http
        .show("/plans", id)
        .then((response) => {
            commit('setPlan', response.data);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    }
  },
};
