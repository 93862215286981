var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"ma-2 ml-0"},[_vm._v("Pagamentos")]),_c('v-card',[_c('v-card-title',{staticClass:"d-flex align-center justify-space-between py-sm-2 py-6"},[_c('div',{staticClass:"row"},[_c('v-text-field',{directives:[{name:"debounce",rawName:"v-debounce:1000ms",value:(_vm.search),expression:"search",arg:"1000ms"}],staticClass:"user-search col-sm-6 pa-2",attrs:{"placeholder":"Pesquisar","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('div',{class:{ 'w-full py-2': _vm.isMobile }},[_c('v-btn',{staticClass:"ma-sm-2 my-2 text-capitalize",attrs:{"color":"success","block":_vm.isMobile},on:{"click":function($event){return _vm.newPayment()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Nova Fatura")])],1),_c('v-btn',{staticClass:"ma-sm-2 my-2 text-capitalize",attrs:{"color":"secondary","block":_vm.isMobile}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiExportVariant)+" ")]),_c('span',[_vm._v("Exportar")])],1)],1)]),_c('v-data-table',{attrs:{"items-per-page":15,"search":_vm.searchQuery,"headers":_vm.tableColumns,"items":_vm.payments,"loading":_vm.loading,"server-items-length":_vm.total,"options":_vm.options,"loading-text":"Carregando Tabela..."},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.invoice_url",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.invoice_url}},[_vm._v(_vm._s(item.invoice_url))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("moneyBr")((item.amount / 100)))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolvepaymentstatusVariant(item.status)) + "--text"),attrs:{"small":"","color":_vm.resolvepaymentstatusVariant(item.status)}},[(item.status == 'paid')?_c('div',[_vm._v("Pago")]):_vm._e(),(item.status == 'pending')?_c('div',[_vm._v("Pendente")]):_vm._e(),(item.status == 'canceled')?_c('div',[_vm._v("Cancelado")]):_vm._e(),(item.status == 'expired')?_c('div',[_vm._v("Expirado")]):_vm._e(),(item.status == 'refunded')?_c('div',[_vm._v("Reembolsado")]):_vm._e(),(item.status == 'chargeback')?_c('div',[_vm._v("Chargeback")]):_vm._e()])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[(item.status == 'pending')?_c('v-list-item',{on:{"click":function($event){return _vm.duplicateInvoice(item.request)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Segunda via")])],1)],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.updateInvoice(item.request)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiReload)+" ")]),_c('span',[_vm._v("Atualizar fatura")])],1)],1),(item.status == 'pending')?_c('v-list-item',{on:{"click":function($event){return _vm.cancelInvoice(item.request)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Cancelar")])],1)],1):_vm._e(),(item.status == 'paid')?_c('v-list-item',{on:{"click":function($event){return _vm.refundInvoice(item.request)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCashRefund)+" ")]),_c('span',[_vm._v("Reembolsar")])],1)],1):_vm._e()],1)],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1),_c('PaymentForm',{ref:"paymentForm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }