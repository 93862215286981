<template>
  <div>
    <h1 class="ma-2 ml-0">Empresas</h1>
    <v-card>
      <v-card-title
        class="d-flex align-center justify-space-between py-sm-2 py-6"
      >
    <div class="row">
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field :items-per-page="100" v-model="searchQuery" :placeholder="filterSelect ? 'Pesquisar por Atributo...' : 'Pesquisar...'
          " outlined dense hide-details class="my-3 ml-0 mx-2 col-4" />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-badge color="success" :content="filteredOrders.length" :value="filterApply && filteredOrders.length > 0"
              overlap>
              <v-btn icon @click="applyFilter()" v-bind="attrs" v-on="on">
                <v-icon :color="filterApply ? 'success' : 'secondary'">{{
                  icons.mdiFilter
                }}</v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span>{{ filterApply ? "Retirar Filtro" : "Aplicar Filtro" }}</span>
        </v-tooltip>
        <v-tooltip bottom v-if="filterSelect">
          https://chrome.google.com/webstore/detail/dark-reader/eimadpbcbfnmbkopoojfekhnkhdbieeh
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="() => (filterSelect = null)">
              <v-icon>
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </template>
          <span> Limpar filtro </span>
        </v-tooltip>
        <v-spacer></v-spacer>
      </v-card-text>
    </div>
    <div>
      <v-btn
            color="success"
            class="ma-sm-2 my-2 text-capitalize"
            @click="openNewCompanyForm()"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Nova empresa</span>
          </v-btn>
    </div>
  </v-card-title>


      <v-data-table :items-per-page="25" :items="companies" item-key="id" :loading="loading" :search="searchQuery"
        :headers="tableColumns" loading-text="Carregando dados...">
        <template #[`item.actions`]="{ item }">
          <v-btn color="primary" x-small @click="openDetail(item.id)">
            <span> Ver Empresa </span>
          </v-btn>
        </template>

        <template #[`item.id`]="{ item }">
          <v-btn rounded v-if="item.status == 1" color="error" x-small @click="inactiveCompany(item.id)">
            <span> Inativar </span>
          </v-btn>

          <v-btn rounded v-if="item.status == 0" color="info" x-small @click="activeCompany(item.id)">
            <span> Ativar </span>
          </v-btn>
        </template>

        <template #[`item.status`]="{ item }">
          <v-chip small :color="resolveUserStatusVariant(item.status)"
            :class="`${resolveUserStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize">
            <div v-if="item.status === 1">Ativo</div>
            <div v-if="item.status === 0">Inativo</div>
          </v-chip>
        </template>

        <template #[`item.impersonate`]="{ item }">
          <v-btn color="info" x-small @click="openAlertConfirmationImpersonate(item)">
            <span> Logar </span>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
    <companies-filter-component ref="filterForm" @filter="filters"></companies-filter-component>
    <NewCompanyForm ref="newCompanyForm"></NewCompanyForm>
    <impersonate-alert ref="alert" @impersonate=impersonateUser($event) />
  </div>
</template>

<script>
import CompaniesFilterComponent from "@/components/app/filters/companies/companiesFilterComponent.vue";
import NewCompanyForm from "@/components/company/forms/NewCompanyForm.vue"
import ImpersonateAlert from '@/components/app/alert/ImpersonateDialog.vue'

import {
  mdiClose, 
  mdiFilter,
  mdiPlus 
} from "@mdi/js";

export default {
  components: {
    CompaniesFilterComponent,
    NewCompanyForm,
    ImpersonateAlert
  },

  data() {
    return {
      searchQuery: null,
      filteredCompanies: [],
      loading: null,
      filterSelect: null,

      tableColumns: [
        { text: "Empresa", value: "name" },
        { text: "CNPJ", value: "cnpj" },
        { text: "Email", value: "email" },
        { text: "Status", value: "status" },
        { text: "Mudar status", value: "id" },
        { text: "Ações", value: "actions" },
        { text: "Logar Como", value: "impersonate" }
      ],

      icons: {
        mdiPlus,
        mdiClose,
        mdiFilter,
      },

      filterApply: false,
      filteredOrders: [],


      url: (process.env.NODE_ENV === 'production') ? "https://app.ezcont.com.br/impersonate" : 'http://localhost:8080/impersonate',
      urlApp:null,
    };
  },

  beforeMount() {
    this.getCompanies();
  },

  computed: {
    companies() {
      return this.$store.state.companies.companies;
    },
  },

  methods: {
    async getCompanies() {
      await this.$store.dispatch("companies/getCompanies");
    },

    getCompaniesFilter() {
      this.$http.index(`/companies`, this.filterQuery)
        .then((res) => {
          this.$store.commit('companies/setCompanies', [res.data])
        })
      this.loading = false
    },

    // TODO implementar conexão ao backend como admin
    applyFilter() {
      if (this.filterApply) {
        this.filterApply = false;
      } else {
        this.$refs.filterForm.open();
      }
    },

    async activeCompany(id) {
      await this.$store.dispatch("companies/activeCompany", id, {
        params: { status: 1 },
      });

      this.getCompanies();
    },

    async inactiveCompany(id) {
      await this.$store.dispatch("companies/inactiveCompany", id);

      this.getCompanies();
    },

    openDetail(id) {
      this.$router.push("/empresa/" + id);
    },

    resolveUserStatusVariant(status) {
      if (status === 1) return "success";
      if (status === 0) return "secondary";
      return "primary";
    },

    openAlertConfirmationImpersonate(item) {
      this.$refs.alert.open(item)
    },

    impersonateUser(tenantId) {
      this.$http.store("/impersonate/" + tenantId)
        .then((res) => {
          window.open(this.makeUrlImpersonate(res.access_token, res.expires_in), '_blank')
        })
    },

    makeUrlImpersonate(access_token, expires_in){
      return this.urlApp = this.url+'?token='+access_token+'&expires_in='+expires_in
    },

    filters(event) {
      this.filterQuery = event;
      this.getCompaniesFilter();
    },

    openNewCompanyForm() {
      this.$refs.newCompanyForm.open();
    },
  },
};
</script>

<style></style>
