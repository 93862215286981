<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    origin="center center"
    persistent
    :fullscreen="isMobile"
  >
    <v-card>
      <v-card-title>
        {{ isUpdating ? "Editar" : "Criar " }} Chave
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValid" ref="form">
          <div class="row col-xs">
            <div class="col">
              <!-- :items="platforms" -->
              <v-select
                v-model="form.platform"
                :rules="[rules.required]"
                :items="platforms"
                item-text="platform"
                item-value="platform"
                label="Plataforma"
                outlined
                dense
                hide-details="auto"
              ></v-select>
            </div>
          </div>
          <div class="row col-xs">
            <div class="col-md-4">
              <v-text-field
                v-model="form.client_id"
                label="Id do Cliente"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </div>
            <div class="col-md">
              <v-text-field
                v-model="form.client_secret"
                label="Segredo"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </div>
          </div>
          <div class="row col-xs">
            <div class="col-md-8">
              <v-text-field
                v-model="form.token"
                :rules="[rules.required]"
                label="Token de Acesso"
                outlined
                dense
                hide-details="auto"
              ></v-text-field>
            </div>
            <div class="col-md-4">
              <v-select
                v-model="form.type"
                :items="integrationType"
                :rules="[rules.required]"
                item-text="value"
                label="Tipo"
                outlined
                dense
                hide-details="auto"
              ></v-select>
            </div>
          </div>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" class="text-capitalize" @click="close()">
          Cancelar
        </v-btn>
        <v-btn class="text-capitalize" color="success" @click="submitForm">
          <v-icon>
            {{ icons.mdiContentSave }}
          </v-icon>
          Salvar Informações
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiContentSave } from "@mdi/js";

export default {
  data() {
    return {
      dialog: false,
      isUpdating: false,
      formValid: true,

      form: {
        tenant_id: null,
        company_id: null,
        client_id: null,
        client_secret: "",
        token: "",
        platform: "",
        type: null,
      },

      platforms: [
        { id: 1, platform: "Hotmart" },
        { id: 2, platform: "Edduz" },
        { id: 3, platform: "Monettize" },
        { id: 4, platform: "Braip" },
        { id: 5, platform: "Shopify" },
      ],

      integrationType: [{ value: "API" }, { value: "Webhook" }],

      icons: {
        mdiContentSave,
      },

      rules: {
        required: (v) => !!v || "Campo obrigatório.",
      },
    };
  },

  computed: {
    isMobile() {
      return window.innerWidth <= 600;
    },
  },

  methods: {
    open(id = null, companyId) {
      if (id) {
        this.getIntegration(id);
        this.isUpdating = true;
      }
      this.form.company_id = companyId;
      this.getCompany();
      this.dialog = true;
    },

    close() {
      this.$refs.form.reset();
      this.dialog = false;
    },

    getCompany() {
      this.$store.dispatch("companies/getCompany", this.form.company_id);
    },

    getIntegration(id) {
      this.$http
        .$get("integration/" + id)
        .then((response) => {
          this.form = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    submitForm() {
      if (this.isUpdating == true) {
        this.updateForm();
      } else {
        this.storeForm();
      }

      this.close();
    },

    storeForm() {
      this.form.tenant_id = this.$store.state.companies.company.tenant_id;
      if (this.$refs.form.validate()) {
        this.$http.$post("/integration", this.form).then((res) => {
          this.dialog = false;
          this.$emit("onSubmit");
        });
      }
    },

    updateForm() {
      this.form.tenant_id = this.$store.state.companies.company.tenant_id;
      if (this.$refs.form.validate()) {
        this.$http
          .$put(`/integration/${this.form.id}`, this.form)
          .then((response) => {
            this.dialog = false;
            this.$emit("onSubmit");
          });
      }
    },
  },
};
</script>
