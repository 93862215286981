var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-actions',{staticClass:"d-flex align-center"},[_c('v-spacer'),_c('v-file-input',{staticClass:"col-4",attrs:{"outlined":"","label":"Importar arquivo de vendas...","hide-details":""},on:{"change":function($event){return _vm.fileLoaded($event)}}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.processedOrders,"loading":_vm.loading,"no-data-text":"Selecione um arquivo compativel (.xlsx)","dense":"","loading-text":"Processando Arquivo de Vendas...","show-select":"","item-key":"B"},on:{"toggle-select-all":function($event){return _vm.filterSelected()}},scopedSlots:_vm._u([{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [(_vm.checkCEP(item.I) && !Object.values(item).includes(null))?_c('v-simple-checkbox',{attrs:{"value":isSelected,"ripple":false},on:{"input":function($event){return select($event)}}}):_vm._e()]}},{key:"item.A",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'invalid d-flex': !item.B}},[(item.A)?_c('span',[_vm._v(" "+_vm._s(item.A)+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.B",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'invalid d-flex': !item.B}},[(item.B)?_c('span',[_vm._v(" "+_vm._s(item.B)+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.C",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'invalid d-flex': !item.C}},[(item.C)?_c('span',[_vm._v(" "+_vm._s(item.C)+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.D",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'invalid d-flex': !item.D}},[(item.E)?_c('span',[(item.D.toString().length > 11)?_c('span',[_vm._v(_vm._s(_vm._f("cnpj")(item.D)))]):_vm._e(),(item.D.toString().length == 11)?_c('span',[_vm._v(_vm._s(_vm._f("cpf")(item.D)))]):_vm._e(),(item.D.toString().length < 11 )?_c('span',[_vm._v(_vm._s(item.D))]):_vm._e()]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.E",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'invalid d-flex': !item.E}},[(item.E)?_c('span',[_vm._v(" "+_vm._s(item.E)+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.F",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'invalid d-flex': !item.F}},[(item.F)?_c('span',[_vm._v(" "+_vm._s(item.F)+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.G",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'invalid d-flex': !item.G}},[(item.G)?_c('span',[_vm._v(" "+_vm._s(item.G)+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.H",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'invalid d-flex': !item.H}},[(item.H)?_c('span',[_vm._v(" "+_vm._s(item.H)+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.I",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'invalid d-flex': !item.I}},[(item.I)?_c('span',[(!_vm.checkCEP(item.I))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"invalid"},'div',attrs,false),on),[_c('span',[_vm._v(_vm._s(item.I))])])]}}],null,true)},[_c('span',[_vm._v(" CEP Invalido ")])]):_c('span',[_vm._v(" "+_vm._s(_vm._f("cep")(item.I))+" ")])],1):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.J",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'invalid d-flex': !item.J}},[(item.J)?_c('span',[_vm._v(" "+_vm._s(item.J)+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.K",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'invalid d-flex': !item.K}},[(item.K)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateBR")(item.K))+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.L",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'invalid d-flex': !item.L}},[(item.M)?_c('span',[_vm._v(" "+_vm._s(_vm._f("dateBR")(item.L))+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.M",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'invalid d-flex': !item.M}},[(item.M)?_c('span',[_vm._v(" "+_vm._s(_vm._f("moneyBr")(item.M))+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}},{key:"item.N",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'invalid d-flex': !item.N}},[(item.N)?_c('span',[_vm._v(" "+_vm._s(item.N)+" ")]):_c('span',{staticStyle:{"opacity":"0.3"}},[_vm._v(" Vazio ")])])]}}],null,true),model:{value:(_vm.selectedOrders),callback:function ($$v) {_vm.selectedOrders=$$v},expression:"selectedOrders"}})],1),_c('v-card-actions',{staticClass:"d-flex align-center"},[_c('v-spacer'),_c('span',[_vm._v("As entradas com campos marcados em vermelho indicam valores invalidos e não podem ser importadas ao sistema.")]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-4",attrs:{"color":"success","disabled":!_vm.selectedOrders.length},on:{"click":_vm.importSelectedOrders}},[_vm._v(" Importar ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }