<template>
<v-expansion-panels focusable>
  <v-expansion-panel>
    <v-expansion-panel-header>
      <v-card-title>
        <v-icon class="ma-1">
          {{ icons.mdiFountainPen  }}
        </v-icon>
          Assinatura
      </v-card-title>
    </v-expansion-panel-header>
  <v-expansion-panel-content>
    <div>
      <v-card-text class="d-flex align-center flex-wrap">
        <div v-for="row in subscription" :key="row.id" class="row my-3">
          <v-card class="col ma-0 mr-3" elevation="1">
            <v-card-text class="d-flex" style="font-size: small">
              <div>
                <span style="font-size: small">
                  Identificador Iugu
                </span>
                <br>
                <span style="font-size: dense">
                  {{ row.iugu_consumer_id }} 
                </span>
              </div>
              <v-spacer></v-spacer>
              <v-icon color="accent">
                {{ icons.mdiAccount }}
              </v-icon>
            </v-card-text>
          </v-card>
          <v-card class="col ma-0 mx-3" elevation="1">
            <v-card-text class="d-flex" style="font-size: small">
              <div>
                <span style="font-size: small">
                  Assinatura da Iugu
                </span>
                <br>
                <span style="font-size: dense">
                  {{ row.iugu_subscription_id }}
                </span>
              </div>
              <v-spacer></v-spacer>
              <v-icon color="warning">
                {{ icons.mdiFileDocumentEdit   }}
              </v-icon>
            </v-card-text>
          </v-card>
          <v-card class="col ma-0 ml-3" elevation="1">
            <v-card-text class="d-flex" style="font-size: small">
              <div>
                <span style="font-size: small">
                  Assinatura
                </span>
                <br>
                <span style="font-size: dense">
                  {{ row.active ? 'Ativo' : 'Inativo' }}
                </span>
              </div>
              <v-spacer></v-spacer>
              <v-icon :color="subscription.active == 1 ? 'success' : 'error'">
                {{ icons.mdiAccountCheck }}
              </v-icon>
            </v-card-text>
          </v-card>
        </div>
        <v-card-text class="px-0">
          <v-card flat >
            <v-card-text class="px-0">
              <v-data-table
                :headers="integrationsTableColumns"
                :items="payments[0]"
                :loading="loading"
                :items-per-page="25"
                :page.sync="pageCount"
                no-data-text="Nenhuma assinatura encontrada."
              >
                <template #[`footer`] class="d-flex">
                  <v-pagination
                    v-model="page"
                    :length="pageCount"
                  ></v-pagination>
                </template>
                <template v-slot:item.amount={item} > 
                  {{item.amount | moneyBr}} 
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card-text>
    </div>
  </v-expansion-panel-content>
  </v-expansion-panel>
</v-expansion-panels>
</template>

<script>
import {
  mdiAccount,
  mdiAccountCheck,
  mdiFileDocumentEdit,
  mdiFountainPen
} from "@mdi/js";

export default {
  props: {
    initialData: Array,
  },

  data() {
    return {
      loading: false,
      page: null,
      pageCount: null,

      icons: {
        mdiAccount,
        mdiAccountCheck,
        mdiFileDocumentEdit,
        mdiFountainPen
      },

      integrationsTableColumns: [
        { text: "Fatura", value: "invoice_url" },
        { text: "Valor", value: "amount" },
        { text: "Status do pagamento", value: "payment_method" },
        { text: "Status", value: "status" },
      ],

      subscription: [],

      payments:[]
    };
  },

  watch:{
      initialData(subscription){
      this.subscription = subscription
      this.payments = this.subscription.map(row =>{
        return row.payments
      })
    }
  },
};
</script>

<style>
</style>