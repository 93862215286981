var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"ma-2 ml-0"},[_vm._v("Empresas")]),_c('v-card',[_c('v-card-title',{staticClass:"d-flex align-center justify-space-between py-sm-2 py-6"},[_c('div',{staticClass:"row"},[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"my-3 ml-0 mx-2 col-4",attrs:{"items-per-page":100,"placeholder":_vm.filterSelect ? 'Pesquisar por Atributo...' : 'Pesquisar...',"outlined":"","dense":"","hide-details":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"color":"success","content":_vm.filteredOrders.length,"value":_vm.filterApply && _vm.filteredOrders.length > 0,"overlap":""}},[_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.applyFilter()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.filterApply ? 'success' : 'secondary'}},[_vm._v(_vm._s(_vm.icons.mdiFilter))])],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.filterApply ? "Retirar Filtro" : "Aplicar Filtro"))])]),(_vm.filterSelect)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function () { return (_vm.filterSelect = null); }}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)]}}],null,false,411477165)},[_vm._v(" https://chrome.google.com/webstore/detail/dark-reader/eimadpbcbfnmbkopoojfekhnkhdbieeh "),_c('span',[_vm._v(" Limpar filtro ")])]):_vm._e(),_c('v-spacer')],1)],1),_c('div',[_c('v-btn',{staticClass:"ma-sm-2 my-2 text-capitalize",attrs:{"color":"success"},on:{"click":function($event){return _vm.openNewCompanyForm()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Nova empresa")])],1)],1)]),_c('v-data-table',{attrs:{"items-per-page":25,"items":_vm.companies,"item-key":"id","loading":_vm.loading,"search":_vm.searchQuery,"headers":_vm.tableColumns,"loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","x-small":""},on:{"click":function($event){return _vm.openDetail(item.id)}}},[_c('span',[_vm._v(" Ver Empresa ")])])]}},{key:"item.id",fn:function(ref){
var item = ref.item;
return [(item.status == 1)?_c('v-btn',{attrs:{"rounded":"","color":"error","x-small":""},on:{"click":function($event){return _vm.inactiveCompany(item.id)}}},[_c('span',[_vm._v(" Inativar ")])]):_vm._e(),(item.status == 0)?_c('v-btn',{attrs:{"rounded":"","color":"info","x-small":""},on:{"click":function($event){return _vm.activeCompany(item.id)}}},[_c('span',[_vm._v(" Ativar ")])]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveUserStatusVariant(item.status)) + "--text"),attrs:{"small":"","color":_vm.resolveUserStatusVariant(item.status)}},[(item.status === 1)?_c('div',[_vm._v("Ativo")]):_vm._e(),(item.status === 0)?_c('div',[_vm._v("Inativo")]):_vm._e()])]}},{key:"item.impersonate",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"info","x-small":""},on:{"click":function($event){return _vm.openAlertConfirmationImpersonate(item)}}},[_c('span',[_vm._v(" Logar ")])])]}}],null,true)})],1),_c('companies-filter-component',{ref:"filterForm",on:{"filter":_vm.filters}}),_c('NewCompanyForm',{ref:"newCompanyForm"}),_c('impersonate-alert',{ref:"alert",on:{"impersonate":function($event){return _vm.impersonateUser($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }