<template>
    <div>
      <v-dialog
        v-model="dialog"
        max-width="800px"
        origin="center center"
        persistent
      >
        <v-card>
          <v-card-title> Novo Código de Serviço </v-card-title>
          <v-card-text class="mt-4">
            <v-form ref="form">
              <v-card-text>
                <v-row>
                  <v-col cols="12 py-0">
                    <v-select
                      dense
                      outlined
                      v-model="codeService.type"
                      :items="codeServicesType"
                      item-text="name"
                      item-value="value"
                      label="Tipo"
                    />
                  </v-col>
  
                  <v-col cols="12 py-0">
                    <v-text-field
                      dense
                      outlined
                      label="Código de serviço"
                      v-model="codeService.code"
                    />
                  </v-col>
  
                  <v-col cols="12 py-0">
                    <v-text-field
                      dense
                      outlined
                      v-model="codeService.description"
                      label="Descrição do código de serviço"
                    />
                  </v-col>
  
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="secondary"
                  class="text-capitalize"
                  @click="dialog = false"
                >
                  Cancelar
                </v-btn>
                <v-btn class="text-capitalize" color="success" @click="store()">
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        dialog: false,
    
        codeService: {
          code:null,
          type:null,
          description:null
        },
      };
    },
  
    computed: {
        codeServicesType() {
            return this.$store.state.codeService.codeServiceType;
        },
    },
  
    methods: {
      open() {
        this.dialog = true;
      },

      close(){
        this.$refs.form.reset();
        this.dialog = false;
      },
  
     async store() {  
       await this.$store.dispatch("codeService/storeCodeService", this.codeService);
       this.close()
      },
    },
  };
  </script>
  
  <style></style>
  