<template>
  <v-expansion-panels focusable>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-card-title class="pb-0">
          <v-icon class="ma-1 mb-2">{{ icons.mdiAccountEdit }}</v-icon>
          <span class="mr-8">Informações</span>
        </v-card-title>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div :class="dense ? 'row d-flex' : 'col'">
          <div :class="dense ? 'col-md-4' : 'row py-3'">
            <v-text-field
              v-model="Companyform.cnpj"
              v-mask="'##.###.###/####-##'"
              outlined
              label="CNPJ"
              :rules="[rules.required, rules.cnpj]"
              hide-details="auto"
              @blur="checkCNPJ"
              :dense="dense"
            ></v-text-field>
          </div>
          <div :class="dense ? 'col-md' : 'row py-3'">
            <v-text-field
              v-model="Companyform.name"
              outlined
              label="Razão Social"
              :rules="[rules.required]"
              hide-details="auto"
              :dense="dense"
            ></v-text-field>
          </div>
        </div>

        <div :class="dense ? 'row d-flex' : 'col'">
          <div :class="dense ? 'col-md' : 'row py-3'">
            <v-text-field
              v-model="Companyform.inscricao_estadual"
              outlined
              label="Inscrição Estadual"
              hide-details="auto"
              :dense="dense"
            ></v-text-field>
          </div>

          <div :class="dense ? 'col-md' : 'row py-3'">
            <v-text-field
              v-model="Companyform.alias"
              outlined
              label="Nome Fantasia"
              :rules="[rules.required]"
              hide-details="auto"
              :dense="dense"
            ></v-text-field>
          </div>
        </div>

        <div :class="dense ? 'row d-flex' : 'col'" class="mb-2">
          <div :class="dense ? 'col-md-12' : 'row py-3'">
            <v-text-field
              v-model="Companyform.inscricao_municipal"
              outlined
              label="Inscrição Municipal"
              hide-details="auto"
              :dense="dense"
            ></v-text-field>
          </div>
          <div>
            <v-checkbox
              v-model="Companyform.inscricao_estadual_isento"
              label="Isento de Inscrição Estadual"
              hide-details="auto"
              :dense="dense"
            ></v-checkbox>
          </div>
        </div>

        <div>
          <v-divider></v-divider>
          <v-row class="mt-3">
            <v-card-title class="pb-0">
              <v-icon class="ma-1">{{ icons.mdiPhone }}</v-icon> Contato
            </v-card-title>
          </v-row>

          <v-row class="mb-3">
            <div class="row col-xs">
              <div class="col-md pa-md-3 pb-0">
                <v-text-field
                  outlined
                  label="Telefone"
                  v-model="Companyform.phone"
                  v-mask="'(##) # ####-####'"
                  hide-details="auto"
                  class="pa-3 pa-md-4"
                  dense
                  @change="update"
                ></v-text-field>
                <v-text-field
                  outlined
                  label="WhatsApp"
                  hide-details="auto"
                  v-model="Companyform.whatsapp"
                  v-mask="'(##) # ####-####'"
                  class="pa-3 pa-md-4"
                  dense
                  @change="update"
                ></v-text-field>
              </div>
              <div class="col-md pa-md-3 pt-0">
                <v-text-field
                  outlined
                  label="E-Mail"
                  hide-details="auto"
                  v-model="Companyform.email"
                  class="pa-3 pa-md-4"
                  dense
                  @change="update"
                ></v-text-field>
                <v-text-field
                  outlined
                  label="Site"
                  hide-details="auto"
                  v-model="Companyform.site"
                  class="pa-3 pa-md-4"
                  dense
                  @change="update"
                ></v-text-field>
              </div>
            </div>
          </v-row>
          <v-divider></v-divider>
          <div class="row">
            <div class="col-md">
              <v-card-title>
                <v-icon class="ma-1">{{ icons.mdiMapMarker }}</v-icon>
                Endereço
              </v-card-title>
              <AddressComponent
                ref="address"
                :initialData="Companyform.main_address"
                :dense="true"
                @update="handleAddress"
              />
            </div>
          </div>
        </div>

        <v-card-actions class="mt-6 ml-6 pr-0">
          <v-spacer></v-spacer>
          <v-btn color="success" class="text-capitalize" @click="submitCompany">
            <v-icon class="ma-1">
              {{ icons.mdiContentSave }}
            </v-icon>
            Salvar Informações
          </v-btn>
        </v-card-actions>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
const axios = require("axios");
import {
  mdiPhone,
  mdiMapMarker,
  mdiInformation,
  mdiAccountEdit,
  mdiContentSave,
} from "@mdi/js";
import AddressComponent from "@/components/app/address/AddressComponent.vue";

export default {
  props: {
    form: Object,

    dense: Boolean
  },

  components: {
    AddressComponent,
  },

  data() {
    return {
      icons: {
        mdiPhone,
        mdiMapMarker,
        mdiInformation,
        mdiAccountEdit,
        mdiContentSave,
      },
      rules: {
        required: (v) => !!v || "Campo obrigatório.",
        cnpj: (v) =>
          /^\d{2}\.\d{3}\.\d{3}\/\d{4}-?\d{2}$/.test(v) ||
          "Insira um CNPJ valido",
      },
      company_id: this.$route.params.id,

      Companyform:{}
    };
  },

  watch:{
    form(){
      this.Companyform = this.form
            console.log(this.Companyform)
    }
  },

  methods: {
    checkCNPJ() {
      let parsedcnpj = this.form.cnpj
        .replace(".", "")
        .replace(".", "")
        .replace("/", "")
        .replace("-", "");

      axios
        .get("https://brasilapi.com.br/api/cnpj/v1/" + parsedcnpj)
        .then((response) => {
          this.Companyform.name = response.data.razao_social;
          this.Companyform.alias = response.data.nome_fantasia;
          this.Companyform.inscricao_estadual = this.form.inscricaoEstadual;
          this.Companyform.inscricao_municipal = this.form.inscricaoMunicipal;
        });
    },

    update() {
      this.dirty = true;
      if (!this.initialDataInput) {
        this.dirty = false;
        this.initialDataInput = true;
      }
      if (this.dirty) {
        this.$emit("touched");
      }
    },

    handleAddress(address) {
      this.Companyform.address = address;
    },

    submitCompany() {
      this.$http
        .$put(`companies/${this.company_id}`, this.form)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
