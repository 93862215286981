<template>
  <v-container fluid>
    <v-row class="ma-2">
      <v-spacer></v-spacer>
      <v-btn color="success" class="text-capitalize" @click="documentUpload()">
        <v-icon>{{ icons.mdiUpload }}</v-icon>Importar Documento
      </v-btn>
    </v-row>
    <v-divider></v-divider>
    <div v-if="!loading">
      <v-row v-if="documentList.length" class="pa-2" dense>
        <v-col v-for="row of documentList" :key="row.id" class="col-md-3 pa-2">
          <v-card class="card">
            <v-card-title>
              {{ row.name }}
              <v-spacer></v-spacer>
              <v-btn icon color="error" @click="deleteDocument(row.id)">
                <v-icon>{{ icons.mdiDelete }}</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-subtitle>Valido até {{ row.due_at | dateBR }}</v-card-subtitle>
            <v-card-text class="d-flex justify-center align-center" style="height: 100px">
              <v-icon large>{{ icons.mdiFileDocument }}</v-icon>
            </v-card-text>
            <v-card-actions>
              <v-btn block plain color="accent" @click="getFile(row.id)" outlined>Visualizar</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <div
        v-if="!documentList.length"
        class="d-flex justify-center align-center"
        style="height: 200px"
      >
        <span>Sem documentos.</span>
      </div>
    </div>
    <div v-if="loading" class="d-flex justify-center align-center" style="height: 200px">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <document-form ref="documentForm" @updated="getDocuments()"></document-form>
  </v-container>
</template>

<script>
  import Filter from "@/util/Filters";
  import DocumentForm from "@/components/company/forms/DocumentForm";
  import { mdiFileDocument, mdiUpload, mdiDelete } from "@mdi/js";

  export default {
    name: "DocumentsTab",
    components: { DocumentForm },
    data() {
      return {
        loading: false,
        documentList: [],
        company_id: this.$route.params.id,

        icons: {
          mdiFileDocument,
          mdiUpload,
          mdiDelete,
        },
      };
    },

    created() {
      this.getDocuments();
    },

    methods: {
      getDocuments() {
        this.loading = true;

        this.$http
          .index("/document", {company_id: this.company_id})
          .then((response) => {
            this.documentList = response;
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      },

      getFile(id) { 
        this.$http
          .$get("/document/" + id, {company_id: this.company_id})
          .then((response) => {

            window.open(response, "_blank");


          }, response => {
            console.warn('error from download_contract')
            console.log(response)
            // Manage errors
          })
          .catch((error) => {
            this.loading = false;
          });
      },

      downloadFile(response, filename) {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([response], { type: 'application/pdf' })

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob)
          return
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob)
        var link = document.createElement('a')
        link.href = data
        link.download = filename + '.pdf'
        link.click()
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data)
        }, 100)
      },

      deleteDocument(id) {
        this.$http
        .$delete("/document/" + id)
        .then((res) => {
          console.log(res);
          this.getDocuments();
        })
        .catch((err) => {
          console.log(err);
        });
      },

      documentUpload() {
        this.$refs.documentForm.open();
      },
    },
  };
</script>