<template>
    <v-dialog
        v-model="dialog"
        max-width="500px"
        origin="center center"
    >
        <v-card>
            <v-card-title>
                <span>
                    Filtrar Vendas
                </span>
                <v-progress-circular
                    indeterminate
                    v-if="loading"
                    class="ma-2"
                    color="secondary"
                ></v-progress-circular>
            </v-card-title>
            <v-card-text>
                <div class="row col-xs">
                    <div class="col-md">
                        <v-autocomplete
                            v-model="filter.order_customer"
                            :items="dataItems.order_customer"
                            label="Cliente"
                            item-text="name"
                            dense filled outlined
                            hide-details="auto"
                            @change="filtrate"
                        ></v-autocomplete>
                    </div>
                    <div class="col-md">
                        <v-autocomplete
                            v-model="filter.platform"
                            :items="dataItems.platform"
                            label="Plataforma"
                            dense filled outlined
                            hide-details="auto"
                            @change="filtrate"
                        ></v-autocomplete>                        
                    </div>
                </div>
                <div class="row col-xs px-3 pt-2">
                    <v-menu v-model="menuData">
                        <template v-slot:activator="{ on, attrs }">
                            <v-combobox
                                v-model="filter.ordered_at"
                                v-bind="attrs"
                                v-on="on"
                                outlined dense
                                label="Data"
                                @change="filtrate"
                            ></v-combobox>
                        </template>
                        <v-date-picker
                            v-model="filter.ordered_at"
                            scrollable
                            range
                            @change="filtrate"
                        ></v-date-picker>
                    </v-menu>
                </div>
                <div class="col px-4">
                    <div class="d-flex align-center justify-center mb-5 mt-0">
                        <span style="font-size: large">
                            Valor
                        </span>
                    </div>
                    <div class="row">
                        <span class="px-2">
                            {{ filter.range[0] / 100 | moneyBr }}
                        </span>
                        <v-range-slider @change="filtrate" v-model="filter.range" :min="dataItems ? dataItems['rangeValue'].min : 0" :max="dataItems ? dataItems['rangeValue'].max : 1" ></v-range-slider>
                        <span class="px-2">
                            {{ filter.range[1] / 100 | moneyBr }}
                        </span>
                    </div>
                </div>
                <div class="row col-xs">
                    <div class="col-md">
                        <v-select @change="filtrate" :items="selectBilled" v-model="filter.billed" item-text="name" item-value="value" label="Faturado" dense outlined></v-select>
                    </div>
                    <div class="col-md">
                        <v-select @change="filtrate" :items="selectedStatus" v-model="filter.status" item-text="name" item-value="value" label="Status" dense outlined></v-select>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="loading || !processedData" color="success" @click="submitFilter()">
                    Aplicar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Filter from '@/util/Filters'

export default {
    props: ["data"],
    name: 'OrdersFilter',
    data(){
        return {
            dialog: false,
            loading: false,

            filter: {
                order_customer: null,
                platform: null,
                ordered_at: null,
                range: [0, 0],
                billed: null,
                status: null
            },

            selectBilled: [
                {name: 'Faturado', value: 1},
                {name: 'Não Faturado', value: 0}
            ],

            selectedStatus: [
                {name: 'Rascunho', value: 'draft'},
                {name: 'Pendente', value: 'pendding'},
                {name: 'Processado', value: 'order'},
                {name: 'Cancelado', value: 'Cancelado'}
            ],
            
            menuData: false,

            processedData: null,

        }
    },

    computed: {
        dataItems(){
            let dataItems = {
                number: [],
                order_customer: [],
                platform: [],
                platform_transaction:[],
                ordered_at: [],
                value: [],
                billed: [],
                status: [],
            }
            this.data.forEach(element => {
                Object.keys(dataItems).forEach(chave => {
                    dataItems[chave].push(element[chave])
                })
            });
            dataItems['rangeValue'] = {
                max: Math.max.apply(null, dataItems.value),
                min: Math.min.apply(null, dataItems.value)
            }
            return dataItems
        },
    },

    methods: {
        open(){
            this.filter = {
                range: [this.dataItems['rangeValue'].min, this.dataItems['rangeValue'].max]
            }
            this.dialog = true
        },

        dateCheck(from,to,check) {
            return Date.parse(check) <= Date.parse(to) && Date.parse(check) >= Date.parse(from)
        },

        filtrate(){
            console.log('filtrate');
            // this.loading = true
            // let orders = []
            // this.$http.$get('/order').then(response => {
            //     orders = response.data.data
            //     this.loading = false
            // }).then(() => {
            //     let processedData = orders.filter(item => {
            //         return (this.filter.order_customer ? this.filter.order_customer == item.order_customer.name : true) &&
            //             (this.filter.platform ? this.filter.platform == item.platform : true) &&
            //             (this.filter.billed != null ? this.filter.billed == item.billed : true) &&
            //             (this.filter.status ? this.filter.status == item.status : true) &&
            //             (this.filter.range != [0,0] ? item.value >= this.filter.range[0] && item.value <= this.filter.range[1] : true) &&
            //             (this.filter.ordered_at ? this.dateCheck(this.filter.ordered_at[0], this.filter.ordered_at[1], item.ordered_at) : true)
            //     })
            //     this.processedData = processedData
            // }).catch( err => console.log(err));
        },

        submitFilter(){
            this.$emit('filter', this.processedData)
            this.dialog = false
        }
    }
}
</script>