import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import VueMask from 'v-mask'
import auth from './plugins/auth'
import http from './plugins/http'
import acl from './plugins/acl'
import debounce from './plugins/debounce'


Vue.config.productionTip = false

Vue.use(VueMask);

new Vue({
  router,
  store,
  vuetify,
  auth,
  http,
  acl,
  debounce,
  render: h => h(App),
}).$mount('#app')
