<template>
  <v-dialog v-model="dialog"
    max-width="800px"
    origin="center center"
    persistent>
    <v-card>
      <v-card-title>
        Nova Fatura
      </v-card-title>
      <v-divider></v-divider>
      <v-card-title>
        <v-icon class="mr-1">
          {{ icons.mdiReceipt }}
        </v-icon>
        <small>Dados da fatura</small>
      </v-card-title>
      <div v-if="loading"
        class="d-flex justify-center">
        <v-progress-circular indeterminate
          color="secondary"
          class="ma-2" />
      </div>
      <v-form v-show="!loading"
        v-model="formValid"
        ref="form">
        <v-card-text>
          <div class="row col-xs mt-2">
            <v-col cols="12 py-0">
              <v-select dense
                outlined
                v-model="selectedCompany"
                :items="companies"
                item-text="name"
                item-value="id"
                label="Empresa"
                @change="getCompany" />
            </v-col>
          </div>
          <div class="row col-xs mt-2">
            <v-col cols="12 py-0">
              <v-select dense
                outlined
                v-model="selectedSubscription"
                :items="companySubscriptions"
                item-text="plan_indentifier"
                return-object
                label="Assinaturas"
                @change="selectSubscription" />
            </v-col>
          </div>

          <div class="row col-xs ">
            <div class="col-md py-0">
              <v-text-field v-model="paymentForm.customer_id"
                :rules="[rules.required]"
                label="Id do cliente"
                outlined
                dense
                hide-details="auto" />
            </div>

            <div class="col-md py-0">
              <v-text-field v-model="paymentForm.email"
                :rules="[rules.required]"
                label="Email"
                outlined
                dense
                hide-details="auto" />
            </div>

          </div>
          <div class="row col-xs justify-center ">
            <div class="col-md-6 mt-2">
              <v-text-field v-model="paymentForm.subscription_id"
                :rules="[rules.required]"
                label="Id da assinatura"
                outlined
                dense
                hide-details="auto" />

            </div>
            <div class="col-md-6 mt-2">
              <DatePicker v-model="paymentForm.due_date"
                label="Data de vencimento"
                :min="minDate"
                :max="maxDate"
                />
            </div>
          </div>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary"
          class="text-capitalize"
          @click="close"> Cancelar </v-btn>
        <v-btn class="text-capitalize"
          color="success"
          @click="submitForm">
          <v-icon>{{ icons.mdiContentSave }}</v-icon>
          Salvar Informações
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import { mdiContentSave, mdiShopping, mdiClose, mdiReceipt } from '@mdi/js'
import DatePicker from '@/components/app/ui/DatePicker.vue'
import { format, parse, addYears } from 'date-fns'


export default {
  components: {
    DatePicker,
  },

  data() {
    return {
      selectedCompany: null,
      companySubscriptions: [],
      selectedSubscription: null,
      dialog: false,
      formValid: null,
      isUpdating: false,
      loading: false,
      icons: {
        mdiShopping,
        mdiContentSave,
        mdiClose,
        mdiReceipt,
      },

      paymentForm: {
        company_id: null,
        email: null,
        due_date: null,
        customer_id: null,
        subscription_id: null,
        items: [{
          description: 'Assinatura',
          quantity: 1,
          price_cents: null,
        }],
      },

      rules: {
        required: v => !!v || 'Campo obrigatório.',
      },
    }
  },

  watch: {
    company(newValue, oldValue) {
      this.companySubscriptions = newValue.subscription
      this.paymentForm.email = newValue.email
    }
  },

  computed: {
    companies() {
      return this.$store.state.companies.companies
    },

    company() {
      return this.$store.state.companies.company
    },

    minDate() {
      const min = format(new Date(), 'yyyy-MM-dd')
      return min.toString()
    },

    maxDate() {
      const maxDate = format(addYears(new Date(), 1), 'yyyy-MM-dd')
      return maxDate
    }
  },

  methods: {
    open() {
      this.dialog = true
      this.getCompanies();
    },

    getCompanies() {
      this.$store.dispatch("companies/getCompanies");
    },

    getCompany() {
      this.$store.dispatch("companies/getCompany", this.selectedCompany)
    },

    close() {
      this.dialog = false
    },

    submitForm() {
      this.$http
        .$post('/invoice/store', this.paymentForm)
        .then(response => {
          console.log(response)
          if (response.status == 200) {
            this.dialog = false
          }
        })
    },

    selectSubscription() {
      this.paymentForm.subscription_id = this.selectedSubscription.iugu_subscription_id
      this.paymentForm.customer_id = this.selectedSubscription.iugu_consumer_id
    },


  },
}
</script>