<template>
  <div>
    <h1 class="ma-2 ml-0">Códigos de serviços</h1>
    <v-card>
      <v-card-title
        class="d-flex align-center justify-space-between py-sm-2 py-6"
      >
    <div class="row">
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <v-text-field :items-per-page="100" v-model="searchQuery" :placeholder="filterSelect ? 'Pesquisar por Atributo...' : 'Pesquisar...'
          " outlined dense hide-details class="my-3 ml-0 mx-2 col-4" />
        <v-spacer></v-spacer>
      </v-card-text>
    </div>
    <div>
      <v-btn
            color="success"
            class="ma-sm-2 my-2 text-capitalize"
            @click="openNewServiceCodeForm()"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Novo Código de Serviço</span>
          </v-btn>
    </div>
  </v-card-title>
      <v-data-table :items-per-page="25" :items="companies" item-key="id" :loading="loading" :search="searchQuery"
        :headers="tableColumns" loading-text="Carregando dados...">
        <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <template>
                <v-list-item @click="openAlertConfirmationInactive(item.id)">
                  <v-list-item-title>
                    <v-icon size="20" class="me-2">
                      {{ icons.mdiTrashCanOutline }}
                    </v-icon>
                    <span>Excluir</span>
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </template>

        <template #[`item.type`]="{ item }">
          <v-chip small :color="resolveTypeCodeServiceVariant(item.type)"
            :class="`${resolveTypeCodeServiceVariant(item.type)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize">
            <span>{{item.type}}</span>
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
    <NewCodeService ref="newCodeServiceForm"></NewCodeService>
    <Alert
      ref="alert"
      @destroy="destroy($event)"
    />
  </div>
</template>

<script>
import NewCodeService from "@/components/codeService/forms/NewCodeService.vue"
import Alert from "@/components/app/alert/AlertExcludeCodeService.vue";

import {
  mdiPlus,
  mdiDotsVertical,
  mdiTrashCanOutline
} from "@mdi/js";

export default {
  components: {
    NewCodeService,
    Alert
  },

  data() {
    return {
      searchQuery: null,
      loading: null,
      filterSelect: null,

      tableColumns: [
        { text: "Código", value: "code" },
        { text: "Descrição", value: "description" },
        { text:"Tipo", value:"type"},
        { text: "Ações", value: "actions" },
      ],

      icons: {
        mdiPlus,
        mdiDotsVertical,
        mdiTrashCanOutline
      },
    };
  },

  beforeMount() {
    this.getCodeService();
  },

  computed: {
    companies() {
      return this.$store.state.codeService.codeServices;
    },
  },

  methods: {
    async getCodeService() {
      await this.$store.dispatch("codeService/getCodeServices");
    },

    handleTypeServiceCode(value) {
      switch(value) {
        case 'SELLER':
          return 'Produtor';
        case 'AFFILIATE':
          return 'Afiliado';
        default:
          return 'Dropshipper';
      }
    },

    resolveTypeCodeServiceVariant(type) {
      switch(type) {
        case 'SELLER':
          return 'primary';
        case 'AFFILIATE':
          return 'secondary';
        default:
          return 'info';
      }
    },

    openNewServiceCodeForm() {
      this.$refs.newCodeServiceForm.open();
    },

    openAlertConfirmationInactive(id) {
      this.$refs.alert.open(id);
    },

   async destroy(id){
      await this.$store.dispatch("codeService/deleteCodeService", id);
    }
  },
};
</script>

<style></style>
