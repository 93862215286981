import Vue from "vue";

export default {
  namespaced: true,

  state: () => ({
    companies: [],
    company: {},
  }),

  mutations: {
    setCompanies(state, data) {
      state.companies = data;
    },

    setCompany(state, data) {
      state.company = data;
    },
  },

  actions: {
    getCompanies({ commit, state }) {
      Vue.$http
        .index("/companies")
        .then((response) => {
          commit("setCompanies", response.data.data);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    getCompany({ commit, state }, id) {
      Vue.$http
        .show("/companies", id)
        .then((response) => {
          commit("setCompany", response.data);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },

    activeCompany({ commit, state }, id) {
      Vue.$http
        .$patch(`/company/${id}/status`, 'status=1')
        .then((response) => {
          this.getCompanies();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    inactiveCompany({ commit, state }, id) {
      Vue.$http
        .$patch(`/company/${id}/status`, 'status=0')
        .then((response) => {
          this.getCompanies();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
