var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex"},[_c('v-combobox',{attrs:{"dense":"","label":"Selecionar Conta","items":_vm.accounts,"item-text":"description","item-value":"id","outlined":""},on:{"change":function($event){return _vm.getCashMovimentTransactions()}},model:{value:(_vm.selectedAccount),callback:function ($$v) {_vm.selectedAccount=$$v},expression:"selectedAccount"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"color":"success","content":_vm.filteredMoviments.length,"value":_vm.filterApply && _vm.filteredMoviments.length > 0,"overlap":""}},[_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"icon":"","disabled":_vm.moviments.length == 0},on:{"click":function($event){return _vm.applyFilter()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.filterApply ? 'success' : 'secondary'}},[_vm._v(_vm._s(_vm.icons.mdiMagnify))])],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.filterApply ? 'Retirar Filtro' : 'Aplicar Filtro'))])])],1),_c('v-spacer'),_c('v-btn',{staticClass:"mb-4 me-3 text-capitalize",attrs:{"color":"secondary"},on:{"click":_vm.openConfigs}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiCog)+" ")])],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.movimentShow,"loading":_vm.loading,"loading-text":"Carregando Tabela..."},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [(item.type === 'income')?_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiArrowTopRightThick)+" ")]):_c('v-icon',{staticClass:"d-flex justify-center",attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.icons.mdiArrowBottomLeftThick)+" ")])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',{style:({color: item.type == 'income' ? 'lightgreen' : 'tomato'})},[_vm._v(" "+_vm._s(_vm.parseCurrency(item.value))+" ")])]}},{key:"item.account_plan",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.account_plan.description)+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status == 'processed')?_c('v-chip',{attrs:{"color":"success","small":""}},[_vm._v(" Processado ")]):_vm._e(),(item.status == 'cancelled')?_c('v-chip',{attrs:{"color":"error","small":""}},[_vm._v(" Cancelado ")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.created_at).toLocaleDateString("pt-br"))+" ")]}}],null,true)}),_c('cash-moviments-filter',{ref:"filterForm",attrs:{"data":_vm.moviments},on:{"filter":function (f) {this$1.filteredMoviments = f; _vm.filterApply = true}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }