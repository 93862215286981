<template>
  <div>
    <v-card flat>
      <v-form v-model="phase">
        <v-card-text class="px-0">
          <FormCompany :form="form" />

          <v-divider class="mt-2"></v-divider>

          <IntegrationList
            :initialData="form.integrations"
            @update="handleIntegration"
          />
          <v-divider></v-divider>

          <v-divider class="mt-2"></v-divider>

          <SubscriptionList :initialData="form.subscription" />

          <v-divider class="mt-2"></v-divider>

          <CompanyNfeSettings :initialData="form.company_nfe_config" />

          <v-divider class="mt-2"></v-divider>

          <DigitalCertificateComponent
            :initialData="form.certificate"
            @update="handleDigitalCertificate"
          />

          <v-divider class="mt-2"></v-divider>

          <LogisticSettingsComponent 
            :ezlogStatus="form.ezlog"
            :companyId="form.id"
          />

        </v-card-text>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import IntegrationList from "@/components/company/integrations/list/IntegrationList.vue";
import SubscriptionList from "@/components/company/subscriptions/list/SubscriptionList.vue";
import FormCompany from "@/components/company/forms/companyForm/FormCompany.vue";
import DigitalCertificateComponent from "@/components/company/nfeSettings/DigitalCertificate.vue";
import LogisticSettingsComponent from "@/components/company/logistic/LogisticSettings.vue";

import CompanyNfeSettings from "@/components/company/nfeSettings/CompanyNfeSettings.vue";

export default {
  props: {
    initialData: {},
    dense: true,
  },

  components: {
    IntegrationList,
    SubscriptionList,
    CompanyNfeSettings,
    FormCompany,
    DigitalCertificateComponent,
    LogisticSettingsComponent,
  },

  data() {
    return {
      phase: null,
      initialDataInput: false,
      loading: true,
      form: {
        alias: "",
        certificate: [],
        cnae: null,
        cnpj: "",
        company_nfe_config: {},
        company_plan: [],
        created_at: "",
        crt: null,
        deleted_at: null,
        email: "",
        id: null,
        inscricao_estadual: null,
        inscricao_estadual_isento: false,
        inscricao_municipal: "",
        integrations: [],
        is_affiliated: null,
        is_dropshipper: null,
        is_producer: null,
        main_address: {},
        name: "",
        phone: "",
        status: true,
        subscription: [],
        tenant_id: null,
        updated_at: "",
        whatsapp: "",
      },
      
      ezlog: null,

      file: null,
    };
  },

  watch: {
    phase() {
      this.$emit("valid", this.phase);
    },
    initialData(val) {
      this.form = val;
      this.loading = false;
    },
  },

  methods: {
    handleIntegration(integration) {
      this.$emit("updateCompany");
    },

    handleDigitalCertificate(certificate, file) {
      this.form.certificate = [certificate];
      this.file = file;
      this.update();
    },

    update() {
      let formData = new FormData();

      formData.append("file", this.file);
      formData.append("password", this.form.certificate);
      formData.append("company_id", this.form.id);
      formData.append("filename", this.file.name);

      console.log(this.file.name);

      this.$http
        .$post(`certificate`, formData)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
      this.dialog = false;
    },
  },
};
</script>

<style>
</style>
