<template>
  <span>
      Reports
  </span>
</template>

<script>
export default {

}
</script>

<style>

</style>