<template>
  <v-card flat>
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex">
        <v-text-field
          v-model="searchQuery"
          placeholder="Pesquisar"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4 text-capitalize"
        ></v-text-field>
        <v-spacer></v-spacer>
      </div>
    </v-card-text>
    <v-data-table
      :headers="tableColumns"
      :items="failures"
      :loading="loading"
      :search="searchQuery"
      loading-text="Carregando Tabela..."
    >
      <template #[`item.actions`]="{ item }">
        <v-btn icon @click="getFileContent(item.message)">
          <v-icon>
            {{ icons.mdiMagnify }}
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mdiMagnify } from "@mdi/js";

export default {
  data() {
    return {
      loading: false,
      searchQuery: null,

      icons: {
        mdiMagnify,
      },

      tableColumns: [
        { text: "log", value: "message" },
        { text: "atualização", value: "time" },
        {
          text: "AÇÃO",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],

      failures: [],

      detailedFailure: null,

      detailDialog: false,
    };
  },

  created() {
    this.getFailures();
  },

  methods: {
    getFailures() {
      this.loading = true;

      this.$http
        .index("/logs")
        .then((response) => {
          this.failures = response;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.error(error.response.message);
        });
      this.loading = false;
    },

    getFileContent(file) {
      console.log(file)
      this.loading = true;
      this.$http
        .index("/log-file", {name: file})
        .then((response) => {
          this.failures = response;
          this.detailedFailure = item;
          this.detailDialog = true;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$toast.error(error.response.message);
        });
      this.loading = false;
    },
  },
};
</script>
