<template>
  <div>
    <v-card>
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <div class="d-flex">
          <v-text-field
            v-model="searchQuery"
            placeholder="Pesquisar"
            outlined hide-details dense
            class="user-search me-3 mb-4 text-capitalize"
          >
          </v-text-field>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs}">
              <v-badge
                color="success"
                :content="filteredOrders.length"
                :value="filterApply && filteredOrders.length > 0"
                overlap
              >
                <v-btn :disabled="orders.length == 0" icon @click="applyFilter()" v-bind="attrs" v-on="on">
                  <v-icon :color="filterApply ? 'success' : 'secondary'">{{ icons.mdiMagnify }}</v-icon>
                </v-btn>
              </v-badge>
            </template>
            <span>{{ filterApply ? 'Retirar Filtro' : 'Aplicar Filtro' }}</span>
          </v-tooltip>
        </div>

        <v-spacer></v-spacer>
        <div class="d-flex align-center flex-wrap">
          <v-btn
            color="accent"
            :disabled="selectedOrders.length == 0"
            outlined
            class="mb-4 mr-1 text-capitalize"
            @click="bill()"
          >
            <v-icon size="17" class="me-1">{{ icons.mdiCheck }}</v-icon>
            <span>Faturar</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="mb-4 mr-1 text-capitalize"
            @click="openImportOrders()"
          >
            <v-icon size="17" class="me-1">{{ icons.mdiFileExcel }}</v-icon>
            <span>Importar</span>
          </v-btn>
          <v-btn color="primary" class="mb-4 mr-1 text-capitalize" @click="openSyncOrders()">
            <v-icon size="17" class="me-1">
              {{ icons.mdiSync }}
            </v-icon>
            <span>Sincronizar</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-data-table
        show-select
        item-key="number"
        v-model="selectedOrders"
        :headers="tableColumns"
        :items="orderShow"
        :loading="loading"
        loading-text="Carregando Tabela"
      >
        <!-- header.data-table-select -->
        <template #[`header.data-table-select`]></template>
        <template #[`item.data-table-select`]="{ item, isSelected, select }">
          <v-simple-checkbox
            :value="isSelected"
            :ripple="false"
            :readonly="item.billed == 1"
            :disabled="item.billed == 1"
            :style="{opacity: item.billed ? 0.2 : 1}"
            @input="select($event)"
          ></v-simple-checkbox>
        </template>

        <template #[`item.order_customer`]="{ item }">
          <div class="d-flex align-center">
              <div class="d-flex flex-column ms-3">
              <span>{{ item.order_customer.name }}</span>
            </div>
          </div>
        </template>

        <template #[`item.ordered_at`]="{ item }">
          {{item.ordered_at | dateBR}}
        </template> -->

        <template #[`item.value`]="{ item }">
          {{item.value / 100 | moneyBr}}
        </template>

        <template #[`item.billed`]="{ item }">
          <v-chip
            small
            :color="resolveOrderBilledVariant(item.billed)"
            :class="`${resolveOrderBilledVariant(item.billed)}--text`"
            class="font-weight-semibold text-capitalize"
          >
            <div v-if="item.billed === 1">Faturado</div>
            <div v-if="item.billed === 0">Não Faturado</div>
          </v-chip>
        </template>

        <template #[`item.status`]="{ item }">
          <v-chip
            small
            class="font-weight-semibold text-capitalize success"
            :color='item.status === "order" ? "success" : "secondary"'
          >
            <div v-if="item.status === 'draft'">Rascunho</div>
            <div v-if="item.status === 'pendding'">Pendente</div>
            <div v-if="item.status === 'order'">Processado</div>
            <div v-if="item.status === 'cancelled'">Cancelado</div>
          </v-chip>
        </template>

      </v-data-table>
    </v-card>
    <orders-filter ref="filterForm" :data="orders" @filter="f => {filteredOrders = f; filterApply = true}"></orders-filter>
    <SyncDialogComponent :companyId="companyId" ref="syncOrders"/>
  </div>
</template>

<script>
import Filter from '@/util/Filters'
import OrdersFilter from "@/components/company/filters/ordersFilter.vue";
import SyncDialogComponent from "@/components/company/orders/SyncDialogComponent.vue";
import { mdiExportVariant, mdiInformation, mdiPlus, mdiCurrencyUsd, mdiDotsHorizontal, mdiFileExcel, mdiMagnify } from "@mdi/js";

const formatador = Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
})


export default {
  name: "OrdersTab",
  components: { OrdersFilter, SyncDialogComponent },

  data() {
    return {
      searchQuery: null,
      loading: false,

      icons: {
        mdiMagnify,
        mdiPlus,
        mdiExportVariant,
        mdiInformation,
        mdiDotsHorizontal,
        mdiCurrencyUsd,
        mdiFileExcel
      },

      filterApply: false,
      filteredOrders: [],

      ordersTotal:{
        total: 1000.00,
        title: 'Vendas'
      },

      tableColumns: [
        { text: "#", value: "number" },
        { text: "Cliente", value: "order_customer" },
        { text: "Plataforma", value: "platform" },
        { text: "Transacao", value: "platform_transaction" },
        { text: "Data", value: "ordered_at" },
        { text: "Valor", value: "value" },
        { text: "Faturado", value: "billed" },
        { text: "Situação", value: "status" },
      ],

      orders: [],

      selectedOrders: [],

      companyId: null

    };
  },

  created() {
    this.companyId = this.$route.params.id;
    this.getOrders(this.companyId);
  },

  computed:{
    orderShow(){
      return this.filterApply ? this.filteredOrders : this.orders
    },

     toBillSum(){
        let billedSum = 0
        this.selectedOrders.forEach( item => {
          billedSum += item.value
        })
        return billedSum
      },
  },

  methods: {
    getOrders(id) {

      this.loading = true;

      this.$http.index("/orders", {company_id: id})
      .then(response => {
        this.orders = response.data.data;
         this.loading = false;
      })
      .catch(error => {
        this.loading = false;
        console.error(error);
      });

    },

    openSyncOrders(){
       this.$refs.syncOrders.open();
    },

    exportOrders() {
      console.log("Export");
    },

    resolveOrderBilledVariant(status) {
      if (status === 1) return "success";
      if (status === 0) return "secondary";

      return "primary";
    },

    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");

      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },

    resolveUserTotalIcon(total) {
      if (total === "Total Users")
        return { icon: mdiCurrencyUsd, color: "primary" };
      if (total === "Paid Users")
        return { icon: mdiCurrencyUsd, color: "error" };
      if (total === "Active Users")
        return { icon: mdiCurrencyUsd, color: "success" };
      if (total === "Pending Users")
        return { icon: mdiCurrencyUsd, color: "warning" };

      return { icon: mdiCurrencyUsd, color: "primary" };
    },

    openImportOrders(){
    //  this.$router.push("vendas/importar");
      console.log('openImportOrders');
      this.$emit('import')
    },

    // TODO implementar conexão ao backend como admin
    bill() {
      console.log('bill');
      // this.$http.$post("/order/bill", {
      //   orders: this.selectedOrders
      // }).then((response) => {
      //   if (res.status == 200) {
      //     console.log(response);
      //   }
      // }).catch((error) => {
      //   console.log(error);
      // });
    },

    parseCurrency(num) {
      return formatador.format(num / 100)
    },

    applyFilter(){
      if(this.filterApply){
          this.filterApply = false
      }else{
          this.$refs.filterForm.open()
      }
    }

    },
  };
</script>
