var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"ma-2 ml-0"},[_vm._v("Códigos de serviços")]),_c('v-card',[_c('v-card-title',{staticClass:"d-flex align-center justify-space-between py-sm-2 py-6"},[_c('div',{staticClass:"row"},[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"my-3 ml-0 mx-2 col-4",attrs:{"items-per-page":100,"placeholder":_vm.filterSelect ? 'Pesquisar por Atributo...' : 'Pesquisar...',"outlined":"","dense":"","hide-details":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-spacer')],1)],1),_c('div',[_c('v-btn',{staticClass:"ma-sm-2 my-2 text-capitalize",attrs:{"color":"success"},on:{"click":function($event){return _vm.openNewServiceCodeForm()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Novo Código de Serviço")])],1)],1)]),_c('v-data-table',{attrs:{"items-per-page":25,"items":_vm.companies,"item-key":"id","loading":_vm.loading,"search":_vm.searchQuery,"headers":_vm.tableColumns,"loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[[_c('v-list-item',{on:{"click":function($event){return _vm.openAlertConfirmationInactive(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCanOutline)+" ")]),_c('span',[_vm._v("Excluir")])],1)],1)]],2)],1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveTypeCodeServiceVariant(item.type)) + "--text"),attrs:{"small":"","color":_vm.resolveTypeCodeServiceVariant(item.type)}},[_c('span',[_vm._v(_vm._s(item.type))])])]}}],null,true)})],1),_c('NewCodeService',{ref:"newCodeServiceForm"}),_c('Alert',{ref:"alert",on:{"destroy":function($event){return _vm.destroy($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }