<template>
  <v-expansion-panels focusable>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-card-title>
          <v-icon class="mr-1">{{ icons.mdiKey }}</v-icon> Chaves
        </v-card-title>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card-text class="d-flex align-center flex-wrap pa-0">
          <v-card-text class="px-0">
            <v-card flat>
              <v-card-text class="d-flex align-center flex-wrap pb-md-0 px-0">
                <v-spacer></v-spacer>
                <div class="d-flex align-center flex-wrap">
                  <v-btn
                    color="success text-capitalize mb-4"
                    @click="handleIntegration()"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                    <span>Nova Chave</span>
                  </v-btn>
                </div>
              </v-card-text>
              <v-card-text class="px-0">
                <v-data-table
                  :items-per-page="25"
                  :page.sync="pageCount"
                  :headers="integrationsTableColumns"
                  :items="integrations"
                  @page-count="pageCount = $event"
                >
                  <template #[`item.type`]="{ item }">
                    <div class="d-flex align-center">
                      <v-chip
                        label
                        outlined
                        :color="item.type == 'webhook' ? 'success' : 'accent'"
                      >
                        <v-icon class="mr-1">
                          {{
                            item.type == "webhook"
                              ? icons.mdiBellRing
                              : icons.mdiCog
                          }}
                        </v-icon>
                        <small>
                          {{ item.type }}
                        </small>
                      </v-chip>
                    </div>
                  </template>

                  <template #[`item.actions`]="{ item }">
                    <v-menu bottom left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item @click="handleIntegration(item.id)">
                          <v-list-item-title>
                            <v-icon size="20" class="me-2">
                              {{ icons.mdiFileDocumentOutline }}
                            </v-icon>
                            <span>Editar</span>
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-title
                            @click="deleteIntegrationKey(item.id)"
                          >
                            <v-icon size="20" class="me-2">
                              {{ icons.mdiDeleteOutline }}
                            </v-icon>
                            <span>Excluir</span>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                  <template #[`footer`] class="d-flex">
                    <v-pagination
                      v-model="page"
                      :length="pageCount"
                    ></v-pagination>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card-text>
        <IntegrationForm ref="integrationForm" @onSubmit="update" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiKey,
  mdiPlus,
} from "@mdi/js";

import IntegrationForm from "@/components/company/integrations/form/IntegrationForm.vue";

export default {

  props: {
    initialData: [],
  },

  components: { IntegrationForm },

  data() {
    return {
      companyId: this.$route.params.id,

      icons: {
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiKey,
        mdiPlus,
      },

      integrationsTableColumns: [
        { text: "Plataforma", value: "platform" },
        { text: "Cliente Id", value: "client_id" },
        { text: "Segredo", value: "client_secret" },
        { text: "Token", value: "token" },
        { text: "Tipo", value: "type" },
        { text: "Ações", value: "actions" },
      ],

      integrations: [],
      page: null,
      pageCount: null,
    };
  },

  watch: {
    initialData(integration) {
      this.integrations = integration;
    },
  },

  methods: {


    handleIntegration(row) {
      this.$refs.integrationForm.open(row, this.companyId);
    },

    update() {
      this.$emit("update");
    },

    deleteIntegrationKey(id) {
      this.$http
        .$delete(`/integration/${id}`)
        .then((response) => {
          this.update();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
</style>