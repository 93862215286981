<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="800px"
      origin="center center"
      persistent
    >
      <v-card>
        <v-card-title> Adicionar Contrato </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="form">
            <v-card-text>
              <v-row>
                <v-col cols="12 py-0">
                  <v-select
                    dense
                    outlined
                    v-model="contract.company_id"
                    :items="companies"
                    item-text="name"
                    item-value="id"
                    label="Empresa"
                  />
                </v-col>

                <v-col cols="12 py-0">
                  <v-select
                    dense
                    outlined
                    v-model="contract.plan_id"
                    :items="plans"
                    item-text="name"
                    item-value="id"
                    label="Plano"
                  />
                </v-col>

                <v-col cols="12 py-0">
                  <v-text-field
                    dense
                    outlined
                    label="Nome"
                    v-model="contract.name"
                  />
                </v-col>

                <v-col cols="12 py-0">
                  <v-text-field
                    dense
                    outlined
                    v-model="contract.hash"
                    label="Hash do contrato"
                  />
                </v-col>

                <v-col cols="6 py-0">
                  <v-text-field
                    dense
                    outlined
                    v-model="contract.start_date"
                    label="Data de inicio"
                    type="date"
                  />
                </v-col>

                <v-col cols="6 py-0">
                  <v-text-field
                    dense
                    outlined
                    v-model="contract.end_date"
                    label="Data de encerramento"
                    type="date"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6 py-0">
                  <v-text-field
                    dense
                    outlined
                    v-model="contract.value"
                    label="Valor do contrato"
                  />
                </v-col>
                <v-col cols="6 py-0">
                  <v-select
                    dense
                    outlined
                    v-model="contract.status"
                    :items="status"
                    item-text="text"
                    item-value="value"
                    label="Status"
                  />
                </v-col>
                <v-col cols="12 py-0">
                  <v-switch
                    v-model="contract.has_signature"
                    color="success"
                    label="O contrato está Assinado?"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="secondary"
                class="text-capitalize"
                @click="dialog = false"
              >
                Cancelar
              </v-btn>
              <v-btn class="text-capitalize" color="success" @click="store()">
                Salvar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { OPTIONS_CONTRACT } from "@/constants/option";
export default {
  data() {
    return {
      dialog: false,

      status: OPTIONS_CONTRACT,

      contract: {
        tenant_id: null,
        plan_id: null,
        company_id: null,
        name: null,
        hash: null,
        has_signature: true,
        start_date: null,
        end_date: null,
        value: null,
        status: "active",
      },
    };
  },

  computed: {
    companies() {
      return this.$store.state.companies.companies;
    },

    plans() {
      return this.$store.state.plans.plans;
    },
  },

  methods: {
    open() {
      this.getPlans();
      this.getCompanies();
      this.dialog = true;
    },

    getCompanies() {
      this.$store.dispatch("companies/getCompanies");
    },

    getPlans() {
      this.$store.dispatch("plans/getPlans");
    },

    store() {
      this.contract.tenant_id = this.setTenantId();

      this.$http
        .store("/contracts", this.contract)
        .then((response) => {
          this.$emit("stored");
          this.dialog = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    setTenantId() {
      return this.$store.state.companies.company.tenant_id;
    },
  },
};
</script>

<style></style>
