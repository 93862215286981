<template>
    <v-dialog v-model="isDialogVisible" persistent width="500">
        <v-card>
            <v-card-title class="headline">
                Atenção
            </v-card-title>
            <v-card-text>Você tem certeza que desaja excluir este código de serviço?</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" outlined @click="isDialogVisible = false">
                    Cancelar
                </v-btn>

                <v-btn color="error" @click="deleteCodeService">
                    Excluir
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
  
<script>
export default {
    data() {
        return {
            isDialogVisible: false,
            id: null
        }
    },

    methods: {
        open(id) {
            this.id = id
            this.isDialogVisible = true
        },

        deleteCodeService() {
            this.$emit('destroy', this.id)
            this.isDialogVisible = false
        }
    }
}
</script>