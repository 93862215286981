<template>
    <v-dialog v-model="dialog" max-width="500px" origin="center center">
        <v-card>
            <v-card-title>
                <span>Filtrar Lançamentos</span>
                <v-progress-circular indeterminate v-if="loading" class="ma-2" color="secondary"></v-progress-circular>
            </v-card-title>
            <v-card-text>
                <div class="row col-xs">
                    <div class="col-md pb-0">
                        <v-text-field
                            v-model="searchFilter.description"
                            item-value=""
                            placeholder="Pesquisar"
                            outlined
                            hide-details
                            dense
                            class="user-search me-3 mb-4"
                        />
                    </div>
                    <div class="col-md pb-0">
                        <v-select
                            :items="selectStatus"
                            v-model="searchFilter.status"
                            item-text="text"
                            item-value="value"
                            label="Status"
                            dense
                            outlined
                        ></v-select>
                    </div>
                </div>
                <div class="row col-xs">
                    <div class="col-md">
                        <v-combobox
                            v-model="searchFilter.accountPlan"
                            :items="dataItems.account_plan"
                            item-text="description" item-value="id"
                            label="Plataforma"
                            dense
                            filled
                            outlined
                            hide-details="auto"
                        ></v-combobox>
                    </div>
                </div>
                <div class="col px-4">
                    <div class="d-flex align-center justify-center mb-5 mt-0">
                        <span style="font-size: large">Valor</span>
                    </div>
                    <div class="row">
                        <span class="px-2">{{ searchFilter.range[0] / 100 | moneyBr }}</span>
                        <v-range-slider
                            v-model="searchFilter.range"
                            :min="dataItems ? dataItems['rangeValue'].min : 0"
                            :max="dataItems ? dataItems['rangeValue'].max : 1"
                        ></v-range-slider>
                        <span class="px-2">{{ searchFilter.range[1] / 100 | moneyBr }}</span>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="loading" color="success" @click="filtrate">Aplicar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Filter from '@/util/Filters'

export default {
    props: { data: []},
    name: 'CashMovimentsFilter',
    data() {
      return {
        loading: false,
        dialog: false,

        searchFilter: {
            description: null,
            account_plan: null,
            range: [0,0],
            status: null
        },

        selectStatus: [
          { text: 'Ativo', value: 'processed' },
          { text: 'Inativo', value: 'cancelled' }
        ],

      }
    },

    computed: {
      dataItems() {
        let dataItems = {
          account_plan: [],
          value: []
        }

        this.data.forEach(element => {
          Object.keys(dataItems).forEach(chave => {
            dataItems[chave].push(element[chave])
          })
        });

        dataItems['rangeValue'] = {
          max: Math.max.apply(null, dataItems.value),
          min: Math.min.apply(null, dataItems.value)
        }

        return dataItems
      }
    },

    methods: {
        open() {
            this.filter = {
                range: [this.dataItems['rangeValue'].min, this.dataItems['rangeValue'].max]
            }
                this.dialog = true
        },

        filtrate() {
            this.$emit('filter', [])
            this.dialog = false
        },
    }

}
</script>

<style>

</style>