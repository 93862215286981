<template>
  <div>
    <v-card>
      <v-card-text class="d-flex align-center flex-wrap pb-0" >
        <!-- search -->
        <v-text-field
          v-model="searchQuery"
          placeholder="Pesquisar"
          outlined
          hide-details
          dense
          class="user-search me-3 mb-4"
        >
        </v-text-field>
        <v-spacer></v-spacer>
      </v-card-text>

      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="users"
        :options.sync="options"
        :server-items-length="totalUserListTable"
        :loading="loading"
        loading-text="Carregando Tabela..."
        show-select
      >
        <!-- name -->
        <template #[`item.name`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar
              :color="item.avatar ? '' : 'primary'"
              :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="32"
            >
              <v-img v-if="item.avatar" :src="require(`@/assets/images/avatars/${item.avatar}`)"></v-img>
              <span v-else class="font-weight-medium">{{ avatarText(item.name) }}</span>
            </v-avatar>

            <div class="d-flex flex-column ms-3">
              <router-link
                :to="{ name: 'users', params: { id: item.id } }"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"
              >
                {{ item.name }}
              </router-link>
              <small>@{{ item.name }}</small>
            </div>
          </div>
        </template>

        <!-- role -->
        <template #[`item.roles`]="{ item }">
          <div class="d-flex align-center">
            <v-avatar
              size="30"
              :color="resolveUserRoleVariant(item.roles[0].name)"
              :class="`v-avatar-light-bg ${resolveUserRoleVariant(item.roles[0].name)}--text me-3`"
            >
              <v-icon size="18" :color="resolveUserRoleVariant(item.roles[0].name)">
                {{ resolveUserRoleIcon(item.roles[0].name) }}
              </v-icon>
            </v-avatar>

            <span class="text-capitalize">{{ item.roles[0].name }}</span>
          </div>
        </template>

        <!-- status -->
        <template #[`item.status`]="{ item }">
          <v-chip
            small
            :color="resolveUserStatusVariant(item.status)"
            :class="`${resolveUserStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            <div v-if="item.status === 1">Ativo</div>
            <div v-if="item.status === 0">Inativo</div>
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-btn icon @click="changeStatus(item.id)">
            <v-icon size="20" class="me-2" >
              {{ icons.mdiDeleteOutline }}
            </v-icon>
            <span>Inativar</span>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mdiDeleteOutline,
  mdiAccountCheckOutline,
  mdiAccountOutline,
  mdiAccountPlusOutline,
  mdiAccountRemoveOutline,
  mdiCogOutline,
  mdiDatabaseOutline,
  mdiDnsOutline,
  mdiPencilOutline,
  mdiPlus,
  mdiExportVariant,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiHome,
} from '@mdi/js'


export default {
  props: {tenantId:null},
  name: 'UsersTab',
  data() {
    return {
      selectedRows: [],
      users: [],
      roles: '',
      searchQuery: '',
      roleFilter: null,
      planFilter: null,
      statusFilter: null,
      totalUserListTable: null,
      loading: false,
      options: {
        sortBy: ['id'],
        sortDesc: [true],
      },

      icons: {
        mdiPlus,
        mdiDeleteOutline,
        mdiAccountCheckOutline,
        mdiAccountOutline,
        mdiAccountPlusOutline,
        mdiAccountRemoveOutline,
        mdiCogOutline,
        mdiDatabaseOutline,
        mdiDnsOutline,
        mdiPencilOutline,
        mdiExportVariant,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiHome,
      },

      tableColumns: [
        { text: 'USER', value: 'name' },
        { text: 'EMAIL', value: 'email' },
        { text: 'PERFIL', value: 'roles', sortable: false },
        { text: 'STATUS', value: 'status', sortable: false },
        {
          text: 'ACTIONS',
          value: 'actions',
          align: 'center',
          sortable: false,
        },
      ],

      userModal: false,
    }
  },

  created() {
    this.getUsers()
  },

  methods: {
    getUsers() {
      this.loading = true
      this.$http
        .index('/users', {tenant_id: this.tenantId})
        .then(response => {
            this.users = response.data.data
            this.totalUserListTable = this.users.length
            this.loading = false            
          
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
    },

    resolveUserRoleVariant(role) {
      if (role === 'accountant') return 'primary'
      if (role === 'admin') return 'warning'
      if (role === 'user') return 'success'

      return 'primary'
    },

    resolveUserRoleIcon(role) {
      if (role === 'accountant') return mdiAccountOutline
      if (role === 'admin') return mdiCogOutline
      if (role === 'user') return mdiDatabaseOutline

      return mdiAccountOutline
    },

    resolveUserStatusVariant(status) {
      if (status === 1) return 'success'
      if (status === 0) return 'secondary'

      return 'primary'
    },

    avatarText(value) {
      if (!value) return ''
      const nameArray = value.split(' ')

      return nameArray.map(word => word.charAt(0).toUpperCase()).join('')
    },

    changeStatus(id) {
    },
  },
}
</script>

<style>
</style>
