import { mdiHome, mdiBriefcase, mdiMessageAlert, mdiFileDocument, mdiAccountSettings, mdiAccountCash, mdiAlertCircleCheckOutline, mdiHumanQueue, mdiAlertBox, mdiFileCertificate, mdiEmailBox, mdiEmailMultiple, mdiNotebook } from '@mdi/js'

export default [
  {
    subheader: "ADMIN",
  },
  {
    title: 'Dashboards',
    icon: mdiHome,
    to: 'dashboard',
  },
  {
    title: 'Empresas',
    icon: mdiBriefcase,
    to: 'companies',
  },
  {
    title: 'Planos',
    icon: mdiFileDocument,
    to: 'plans',
  },
  {
    title: 'Contratos',
    icon: mdiFileCertificate,
    to: 'contracts',
  },
  {
    title: 'Modelo Contratos',
    icon: mdiFileCertificate,
    to: 'contracts-model',
  },
  {
    title: 'Pagamentos',
    icon: mdiAccountCash,
    to: 'payments',
  },
  {
    title: 'Emails',
    icon: mdiEmailMultiple,
    to: 'emails',
  },
  {
    subheader: "LOGS",
  },
  {
    title: 'Logs de Erros',
    icon: mdiAlertCircleCheckOutline,
    to: 'logs-failures',
  },
  {
    title: 'Filas Pendentes',
    icon: mdiHumanQueue,
    to: 'queue-pending',
  },
  {
    title: 'Filas Falhando',
    icon: mdiAlertBox,
    to: 'queue-failures',
  },
  {
    title: 'Relatorios',
    icon: mdiFileDocument,
    to: 'reports',
  },
  {
    subheader: "CONFIGURAÇÕES",
  },
  {
    title: 'Usuários',
    icon: mdiAccountSettings,
    to: 'users',
  },
  {
    title: 'Código de servico',
    icon: mdiNotebook,
    to: 'service-code',
  },
]
