<template>
    <v-expansion-panels focusable>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <v-card-title>
              <v-icon class="ma-2">{{ icons.mdiTruckFast }}</v-icon>
              Logística
            </v-card-title>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-switch
                  v-model="ezlogStatus"
                  label="Módulo de logistica."
                  @click="confirmChange()"
                />

          </v-expansion-panel-content>
          <v-dialog  v-model="dialog" persistent width="500">
            <v-card>
                <v-card-title class="headline">
                    Atenção
                </v-card-title>
                <v-card-text>Deseja confirmar a alteração?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" class="text-capitalize" @click="close()">
                        Cancelar
                    </v-btn>
                    <v-btn class="text-capitalize" color="success" @click="submit()">
                        <v-icon>
                          {{ icons.mdiContentSave }}
                        </v-icon>
                        Salvar Informações
                      </v-btn>
                </v-card-actions>
            </v-card>  
          </v-dialog>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>

import {
    mdiTruckFast
} from "@mdi/js"

export default {
    props: {
        ezlogStatus: Number,
        companyId: Number,
    },
    
    data() {
        return {
            dialog: false,
            icons: {
                mdiTruckFast,
            },
        }
    },
    
    methods: {
        confirmChange() {
            this.dialog = true
        },
        
        submit() {
            this.$http
                .$put(`/company/update-ezlog-status/${this.companyId}`, {
                    status: this.ezlogStatus
                }).then((response) => {
                    if (response.status == 200) {
                        this.dialog = false
                    }
                })
        },

        close() {
            this.ezlogStatus = !this.ezlogStatus
            this.dialog = false
        },
    },
}
</script>

<style>

</style>