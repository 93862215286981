<template>
  <v-expansion-panels focusable>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-card-title>
          <v-icon class="mr-2">{{ icons.mdiCog }}</v-icon>
          Configurações Fiscais
        </v-card-title>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div>
          <v-card-text class="d-flex align-center flex-wrap px-0">
            <v-form v-model="fiscalSettingsSide" class="row col-xs">
              <div class="col-md">
                <v-card flat>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-radio-group v-model="initialData.ambiente_nf" row>
                          <v-radio label="Homologaçao" value="1"></v-radio>
                          <v-radio label="Produçao" value="2"></v-radio>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col md="3">
                        <v-select
                          v-model="initialData.crt"
                          outlined
                          :items="crt"
                          label="Codigo de Regime Tributario"
                          hide-details="auto"
                          dense
                        >
                        </v-select>
                      </v-col>

                      <v-col md="3">
                        <v-text-field
                          v-model="initialData.aliquota"
                          outlined
                          label="Aliquota"
                          hide-details="auto"
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col md="3">
                        <v-text-field
                          v-model="initialData.ccm"
                          outlined
                          label="Inscriçao Municipal (CCM)"
                          :rules="[rules.required]"
                          hide-details="auto"
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col md="3">
                        <v-text-field
                          v-model="initialData.password"
                          outlined
                          label="Senha Prefeitura"
                          :rules="[rules.required]"
                          hide-details="auto"
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col md="6">
                        <v-text-field
                          v-model="initialData.code_service"
                          outlined
                          label="Código de serviço"
                          hide-details="auto"
                          dense
                        ></v-text-field>
                      </v-col>

                       <v-col md="6">
                        <v-text-field
                          v-model="initialData.code_tax_city"
                          outlined
                          label="Código de tributação do Municipio"
                          hide-details="auto"
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col md="12">
                        <v-text-field
                          v-model="initialData.token_emit_nfse"
                          outlined
                          label="Token Emissor"
                          hide-details="auto"
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-card-text>

                  <v-card-text class="d-flex justify-md-end justify-center">
                    <v-btn
                      color="success"
                      class="text-capitalize mt-3"
                      @click="updateSettings"
                    >
                      <v-icon class="ma-1">
                        {{ icons.mdiContentSave }}
                      </v-icon>
                      Salvar Informações
                    </v-btn>
                  </v-card-text>
                </v-card>
                <v-divider></v-divider>
              </div>
            </v-form>
          </v-card-text>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mdiTextBox, mdiCog, mdiContentSave } from "@mdi/js";
import { OPTIONS_CRT } from "@/constants/option";

export default {
  props: {
    initialData: Object,
  },
  data() {
    return {
      icons: {
        mdiTextBox,
        mdiCog,
        mdiContentSave,
      },

      crt: OPTIONS_CRT,

      menus: {
        emissao: false,
        vencimento: false,
      },

      certificateSide: null,
      fiscalSettingsSide: null,

      rules: {
        required: (v) => !!v || "Campo obrigatório.",
      },
    };
  },

  methods: {
    handleDigitalCertificate(e) {
      this.certificateForm = e;
      this.$emit("updateCertificate", this.certificateForm);
    },

    updateSettings() {
      this.$http
        .$put(
          `company-settings/${this.initialData.company_id}`,
          this.initialData
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style>
</style>


