<template>
  <v-dialog
    v-model="isDialogVisible"
    persistent
    width="500"
  >
    <v-card>
      <v-card-title class="headline">
        Atenção
      </v-card-title>
      <v-card-text>Deseja realmente desativar o usuário {{user.name}} .</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          outlined
          @click="isDialogVisible = false"
        >
          Cancelar
        </v-btn>

        <template v-if="this.user.status == false">
            <v-btn 
            color="success"
            @click="activeUser"
            >
                Ativar
            </v-btn>
        </template>

        <template v-else>
            <v-btn
            color="error"
            @click="inactiveUser"
            >
                Desativar
            </v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
        return {
          isDialogVisible:false,
          user:{

          },
        }
  },

  methods: {
      open(user) {
          this.user = user
          this.isDialogVisible = true
      },

      inactiveUser(){
        this.user.status = false
        this.$emit('inactive', this.user)
         this.isDialogVisible = false
      },

      activeUser(){
        this.user.status = true
        this.$emit('active', this.user)
         this.isDialogVisible = false
      }

  }
}
</script>