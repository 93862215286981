<template>
<div>
    <h1 class="ma-2 ml-0">
      Filas Falhando 
    </h1>
  <v-card>
     <v-card-title class="d-flex align-center justify-space-between py-sm-2 py-6">
        <div class="row">
          <v-text-field
            v-model="searchQuery"
            placeholder="Pesquisar"
            outlined hide-details dense
            class="user-search col-sm-6 pa-2 mt-2 mb-2"
          >
          </v-text-field>
        </div>
     </v-card-title>

    <v-data-table
      :headers="tableColumns"
      :items="jobs"
      item-key="uuid"
      class="elevation-1"
    >

    <template v-slot:item.payload="{ item }">
        <v-btn @click="openPayload(item.exception)">
          Payload
        </v-btn>
    </template>
    </v-data-table>

  </v-card>

   <Payload ref="openPayload"/>
</div>
</template>
<script>
import Payload from '@/views/Queues/Payload/Payload.vue';


export default {
 
  components: { Payload },
  
  data() {
    return {
      expanded: [],
      jobs: [],
      singleExpand: true,
      searchQuery: '',
      statusFilter: null,
      loading: false,
      options: {
        sortBy: ['id'],
        sortDesc: [true],
      },
      page: null,
      pageCount: null,
      
      tableColumns: [
        { text: 'Id', value: 'uuid', sortable: false },
        { text: 'Fila', value: 'queue' },
        { text: 'Tentativas', value: 'payload.attempts' },
         { text: 'Entidade', value: 'payload.tags' },
        { text: 'Data da Falha', value: 'failed_at' },
        {
          text: 'Payload',
          value: 'payload',
          align: 'center',
        },
      ],

    }
  },

  created() {
    this.getPendingJobs()
  },

  computed:{
    isMobile() {
      return window.innerWidth <= 600
    },
  },
  
  methods: {
    getPendingJobs() {
      this.loading = true
      this.$http
        .index('/failed-jobs')
        .then(response => {
            this.jobs = response.data
            this.loading = false            
          
        })
        .catch(error => {
          console.log(error)
        })
    },

    openPayload(payload){
      this.$refs.openPayload.open(payload)
    }
  },
}
</script>

<style>
</style>