var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4 text-capitalize",attrs:{"placeholder":"Pesquisar","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-badge',{attrs:{"color":"success","content":_vm.filteredOrders.length,"value":_vm.filterApply && _vm.filteredOrders.length > 0,"overlap":""}},[_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.orders.length == 0,"icon":""},on:{"click":function($event){return _vm.applyFilter()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":_vm.filterApply ? 'success' : 'secondary'}},[_vm._v(_vm._s(_vm.icons.mdiMagnify))])],1)],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.filterApply ? 'Retirar Filtro' : 'Aplicar Filtro'))])])],1),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{staticClass:"mb-4 mr-1 text-capitalize",attrs:{"color":"accent","disabled":_vm.selectedOrders.length == 0,"outlined":""},on:{"click":function($event){return _vm.bill()}}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(_vm._s(_vm.icons.mdiCheck))]),_c('span',[_vm._v("Faturar")])],1),_c('v-btn',{staticClass:"mb-4 mr-1 text-capitalize",attrs:{"color":"secondary","outlined":""},on:{"click":function($event){return _vm.openImportOrders()}}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(_vm._s(_vm.icons.mdiFileExcel))]),_c('span',[_vm._v("Importar")])],1),_c('v-btn',{staticClass:"mb-4 mr-1 text-capitalize",attrs:{"color":"primary"},on:{"click":function($event){return _vm.openSyncOrders()}}},[_c('v-icon',{staticClass:"me-1",attrs:{"size":"17"}},[_vm._v(" "+_vm._s(_vm.icons.mdiSync)+" ")]),_c('span',[_vm._v("Sincronizar")])],1)],1)],1),_c('v-data-table',{attrs:{"show-select":"","item-key":"number","headers":_vm.tableColumns,"items":_vm.orderShow,"loading":_vm.loading,"loading-text":"Carregando Tabela"},scopedSlots:_vm._u([{key:"header.data-table-select",fn:function(){return undefined},proxy:true},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
var isSelected = ref.isSelected;
var select = ref.select;
return [_c('v-simple-checkbox',{style:({opacity: item.billed ? 0.2 : 1}),attrs:{"value":isSelected,"ripple":false,"readonly":item.billed == 1,"disabled":item.billed == 1},on:{"input":function($event){return select($event)}}})]}},{key:"item.order_customer",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('span',[_vm._v(_vm._s(item.order_customer.name))])])])]}},{key:"item.ordered_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateBR")(item.ordered_at))+" ")]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moneyBr")(item.value / 100))+" ")]}},{key:"item.billed",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-semibold text-capitalize",class:((_vm.resolveOrderBilledVariant(item.billed)) + "--text"),attrs:{"small":"","color":_vm.resolveOrderBilledVariant(item.billed)}},[(item.billed === 1)?_c('div',[_vm._v("Faturado")]):_vm._e(),(item.billed === 0)?_c('div',[_vm._v("Não Faturado")]):_vm._e()])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"font-weight-semibold text-capitalize success",attrs:{"small":"","color":item.status === "order" ? "success" : "secondary"}},[(item.status === 'draft')?_c('div',[_vm._v("Rascunho")]):_vm._e(),(item.status === 'pendding')?_c('div',[_vm._v("Pendente")]):_vm._e(),(item.status === 'order')?_c('div',[_vm._v("Processado")]):_vm._e(),(item.status === 'cancelled')?_c('div',[_vm._v("Cancelado")]):_vm._e()])]}}],null,true),model:{value:(_vm.selectedOrders),callback:function ($$v) {_vm.selectedOrders=$$v},expression:"selectedOrders"}},[_vm._v(" --> ")])],1),_c('orders-filter',{ref:"filterForm",attrs:{"data":_vm.orders},on:{"filter":function (f) {_vm.filteredOrders = f; _vm.filterApply = true}}}),_c('SyncDialogComponent',{ref:"syncOrders",attrs:{"companyId":_vm.companyId}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }