var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"ma-2 ml-0"},[_vm._v("Planos")]),_c('v-card',[_c('v-card-title',{staticClass:"d-flex align-center justify-space-between py-sm-2 py-6"},[_c('div',{staticClass:"row"},[_c('v-text-field',{staticClass:"user-search col-sm-6 pa-2",attrs:{"placeholder":"Pesquisar","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('div',{class:{ 'w-full py-2': _vm.isMobile }},[_c('v-btn',{staticClass:"ma-sm-2 my-2 text-capitalize",attrs:{"color":"success","block":_vm.isMobile},on:{"click":function($event){return _vm.openPlanForm()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Novo Plano")])],1)],1)]),_c('v-data-table',{attrs:{"items-per-page":25,"page":_vm.pageCount,"search":_vm.searchQuery,"headers":_vm.tableColumns,"items":_vm.plans,"loading-text":"Carregando Tabela..."},on:{"update:page":function($event){_vm.pageCount=$event}},scopedSlots:_vm._u([{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm._f("moneyBr")(item.value / 100)))])]}},{key:"item.value_others_installments",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-black"},[_vm._v(_vm._s(_vm._f("moneyBr")(item.value_others_installments / 100)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.openPlanForm(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Editar")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.deletePlan(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCanOutline)+" ")]),_c('span',[_vm._v("Excluir")])],1)],1)],1)],1)]}}]),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1),_c('PlanForm',{ref:"planForm",on:{"onSubmit":_vm.getPlans}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }