<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col lg="8" class="d-none d-lg-block position-relative overflow-hidden pa-0">
        <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="600px"
              class="pa-6"
              :src="require(`@/assets/images/front/front-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
            />
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text>
                  <v-img
                    class="ma-6"
                    max-height="150"
                    max-width="280"
                    src="@/assets/images/svg/cinza - azul.svg"
                  ></v-img>
                </v-card-text>

                <!-- login form -->
                <v-card-text @submit.prevent>
                  <v-form >
                    <v-text-field
                      v-model="form.email"
                      outlined
                      label="E-Mail"
                      placeholder="email"
                      hide-details="auto"
                      class="mb-6"
                      dense
                    ></v-text-field>

                    <v-text-field
                      v-model="form.password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Senha"
                      placeholder="Password"
                      :append-icon="passwordVisibleIcon"
                      hide-details="auto"
                      class="mb-6"
                      @click:append="isPasswordVisible = !isPasswordVisible"
                      dense
                    ></v-text-field>

                    <div class="d-flex align-center justify-space-between flex-wrap">
                      <v-checkbox hide-details label="Lembre de Mim" class="mt-0"> </v-checkbox>

                      <!-- forget link -->
                      <a class="ms-3" @click="forgotPassword"> Esqueceu a senha? </a>
                    </div>

                    <v-btn block color="primary" class="mt-6" type="submit" @submit="login()" @click="login()"> Entrar </v-btn>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <p class="mb-0 me-2">Novo no Ezcont?</p>
                  <a @click="navigateToSignup"> Inscrever-se </a>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import themeConfig from '@themeConfig'

import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'

export default {
  name: 'Signin',
  data() {
    return {
      form: {
        email: null,
        password: null,
      },
      isPasswordVisible: false,
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },

  computed: {
    passwordVisibleIcon() {
      return this.isPasswordVisible ? mdiEyeOffOutline : mdiEyeOutline
    },
  },

  methods: {
    login() {
      console.log('login')
      this.$auth.login(this.form).then(async response => {
        await this.$auth.getLoggedUser()
        this.$router.push('/')
      })
    },

    navigateToSignup(){
       this.$router.push('/signup')
    },

    forgotPassword(){
       this.$router.push('/esqueci-minha-senha')
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';

#logo {
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>
