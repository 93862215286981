<template>
    <v-dialog 
        v-model="dialog"
        max-width="500px"
        rigin="center center"
    >
        <v-card>
            <v-card-title>
                Titulo
                <v-progress-circular indeterminate v-if="loading" class="ma-2" color="secondary"></v-progress-circular>
            </v-card-title>
            <v-card-text>
                <div class="row col-xs">
                    <div class="col-md">
                        <v-autocomplete
                            v-model="searchFilter.company_id"
                            :items="dataItems.company_id"
                            label="Empresa"
                            item-text="name" item-value="id"
                            dense
                            filled
                            outlined
                            hide-details="auto"
                        ></v-autocomplete>
                    </div>
                    <div class="col-md">
                        <v-autocomplete
                            v-model="searchFilter.customer_id"
                            :items="dataItems.customer_id"
                            label="Cliente"
                            item-text="name" item-value="id"
                            dense
                            filled
                            outlined
                            hide-details="auto"
                        ></v-autocomplete>
                    </div>
                </div>
                <div class="col px-4">
                    <div class="d-flex align-center justify-center mb-5 mt-0">
                        <span style="font-size: large">Valor</span>
                    </div>
                    <div class="row">
                        <span class="px-2">{{ searchFilter.range[0] / 100 | moneyBr }}</span>
                        <v-range-slider
                            v-model="searchFilter.range"
                            :min="dataItems ? dataItems['rangeValue'].min : 0"
                            :max="dataItems ? dataItems['rangeValue'].max : 1"
                        ></v-range-slider>
                        <span class="px-2">{{ searchFilter.range[1] / 100 | moneyBr }}</span>
                    </div>
                </div>
                <div class="row col-xs">
                    <div class="col-md">
                        <v-menu v-model="menuData">
                            <template v-slot:activator="{ on, attrs }">
                                <v-combobox
                                    v-model="searchFilter.emited_at"
                                    v-bind="attrs"
                                    v-on="on"
                                    outlined dense
                                    label="Data"
                                    @change="filtrate"
                                ></v-combobox>
                            </template>
                            <v-date-picker
                                v-model="searchFilter.emited_at"
                                scrollable
                                range
                                @change="filtrate"
                            ></v-date-picker>
                        </v-menu>
                    </div>
                    <div class="col-md">
                        <v-select
                            v-model="searchFilter.status"
                            :items="selectStatus"
                            item-text="text" item-value="value"
                            placeholder="Status"
                            outlined dense
                        ></v-select>
                    </div>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :disabled="loading || !processedData" color="success" @click="submitFilter()">
                    Aplicar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import Filter from '@/util/Filters'

export default {
    props: { data: [] },
    name: 'OutgoingInvoicesFilterComponent',
    data(){
        return {
            dialog:  false,
            loading: false,
            processedData: null,
            menuData: false,

            searchFilter: {
                company_id: null,
                customer_id: null,
                range: [0, 1],
                emited_atRange: [0, 0],
                status: null
            },

            selectStatus: [
                {text:'Rascunho', value:'draft'},
                {text: 'Pendente', value: 'pendding'},
                {text: 'Faturado', value: 'billed'},
                {text: 'Cancelado', value: 'cancelled'}
            ]

        }
    },

    computed: {
        dataItems() {
            let dataItems = {
                company_id: [],
                customer_id: [],
                status: [],
                value: [],
            }

            this.data.forEach(element => {
                Object.keys(dataItems).forEach(chave => {
                    dataItems[chave].push(element[chave])
                })
            });

            dataItems['rangeValue'] = {
                max: dataItems.value.length ? Math.max.apply(null, dataItems.value) : 0,
                min: dataItems.value.length ? Math.min.apply(null, dataItems.value) : 100
            }

            return dataItems
        }
    },

    methods:{
        open(){
            this.searchFilter.range = [ this.dataItems['rangeValue'].min, this.dataItems['rangeValue'].max]
            console.log({'searchFilter': this.searchFilter, 'dataItems':this.dataItems});
            this.dialog = true
        },

        filtrate(){
            this.loading = true
            setTimeout(() => {
                let invoices = []
                this.processedData = invoices
                this.loading = false                
            }, 1000);
        },

        submitFilter(){
            this.$emit('filter', this.processedData)
            this.dialog = false
        }
    }
}
</script>