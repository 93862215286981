<template>
  <div>
    <h1 class="ma-2 ml-0">Planos</h1>
    <v-card>
      <v-card-title
        class="d-flex align-center justify-space-between py-sm-2 py-6"
      >
        <div class="row">
          <v-text-field
            v-model="searchQuery"
            placeholder="Pesquisar"
            outlined
            hide-details
            dense
            class="user-search col-sm-6 pa-2"
          >
          </v-text-field>
        </div>

        <div :class="{ 'w-full py-2': isMobile }">
          <v-btn
            color="success"
            class="ma-sm-2 my-2 text-capitalize"
            :block="isMobile"
            @click="openPlanForm()"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Novo Plano</span>
          </v-btn>
        </div>
      </v-card-title>

      <v-data-table
        :items-per-page="25"
        :page.sync="pageCount"
        v-model="selectedRows"
        :search="searchQuery"
        :headers="tableColumns"
        :items="plans"
        loading-text="Carregando Tabela..."
      >
        <template v-slot:item.value="{ item }">
          <span class="font-weight-black">{{ item.value / 100 | moneyBr }}</span>
        </template>

        <template v-slot:item.value_others_installments="{ item }">
          <span class="font-weight-black">{{
            item.value_others_installments / 100 | moneyBr
          }}</span>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="openPlanForm(item.id)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Editar</span>
                </v-list-item-title>
              </v-list-item>
                 <v-list-item @click="deletePlan(item.id)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiTrashCanOutline }}
                  </v-icon>
                  <span>Excluir</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <PlanForm ref="planForm" @onSubmit="getPlans" />
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiTrashCanOutline,
} from "@mdi/js";

import PlanForm from "@/components/plans/forms/PlanForm.vue";
import Filter from "@/util/Filters";

export default {
  components: { PlanForm },

  data() {
    return {
      selectedRows: [],
      plans: [],
      searchQuery: null,
      filteredPlans: [],
      loading: null,
      pageCount: null,


      icons: {
        mdiPlus,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiTrashCanOutline
      },

      tableColumns: [
        { text: "HASH", value: "integration_plan_id",  },
        { text: "NOME", value: "name",  },
        { text: "VALOR TOTAL", value: "value",  },
        {
          text: "PARCELAS",
          value: "installments",
          align: "center",
        },
        {
          text: "VALOR PARCELA",
          value: "value_others_installments",
        },
        {
          text: "AÇÃO",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
    };
  },

  created() {
    this.getPlans();
  },

  computed: {
    isMobile() {
      return window.innerWidth <= 600;
    },
  },

  methods: {
    getPlans() {
      this.loading = true;
      this.$http
        .index("/plans")
        .then((response) => {
          this.plans = response.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deletePlan(id){
      console.log(id);
      this.$http
        .$delete(`/plans/${id}`)
        .then((response) => {
          this.getPlans();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    openPlanForm(id = null) {
      this.$refs.planForm.open(id);
    },
  },
};
</script>

<style></style>
