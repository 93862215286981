<template>
  <v-dialog v-model="dialog" max-width="500px" origin="center center">
    <v-card v-if="loading === false">
      <v-card-title>
        <span>Sincronizar Vendas</span>
      </v-card-title>
      <v-card-text>
        <div class="row col-xs px-3 pt-2 mb-2">
          <v-menu v-model="menuData" :close-on-content-click="false" >
            <template v-slot:activator="{ on, attrs }">
              <DatePicker
                v-model="filter.date"
                label="Data"
                :range="true"
              ></DatePicker>
            </template>
            <v-date-picker v-model="filter.date" scrollable range></v-date-picker>
          </v-menu>
        </div>
        <div>
          <v-autocomplete
            v-model="filter.platform"
            :items="platforms"
            label="Plataforma"
            dense
            filled
            outlined
            hide-details="auto"
          ></v-autocomplete>
        </div>

        <div class="mt-5">
          <span>Total de vendas sincronizadas:{{ transactions.total}}</span>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" class="text-capitalize" @click="getTRansactions()">
          <v-icon size="17" class="me-1">{{ icons.mdiSync }}</v-icon>
          <span>Sincronizar</span>
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="loading === true">
      <v-card-text>
        <div class="text-center">
          <span>
            Estamos buscando sua transações,esse processo pode demorar alguns minutos você pode sair desta página,
            enviaremos notificações. Você pode retornar aqui quando quiser para verificar o status da sincronização.
          </span>
        </div>
        <div class="text-center">
          <v-progress-circular indeterminate color="secondary" class="ma-2"></v-progress-circular>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  import Filters from "@/util/Filters";
  import { mdiSync } from "@mdi/js";
  import DatePicker from "@/components/app/ui/DatePicker.vue"

  export default {
    components: {
      DatePicker
    },
    props:{
      companyId: String
    },

    data() {
      return {
        loading: false,

        dialog: false,

        transactions: [],

        menuData: false,

        filter: {
          platform: null,
          date: null,
        },

        platforms: [
          'Hotmart', 'Braip', 'Eduzz', 'Monetizze'
        ],

        icons: { mdiSync },

      };
    },

    computed: {
      getUrl() {
        if (this.filter.platform === 'Hotmart') {
          return '/sync-hotmart';
        } else if (this.filter.platform === 'Braip') {
          return '/sync-braip';
        } else if (this.filter.platform === 'Eduzz') {
          return '/sync-eduzz';
        } else if (this.filter.platform === 'Monetizze') {
          return '/sync-monetizze';
        }
      },
    },

    methods: {

      open() {
        this.dialog = true;
      },

      getTRansactions() {
        this.loading = true;
        this.$http
          .$get(this.getUrl, {
       
              start: this.filter.date[0],
              end: this.filter.date[1],
              company_id:this.companyId
           
          })
          .then((response) => {
            this.transactions = response;
            this.loading = false;
          })
          .catch((error) => {
            console.error(error);
            this.loading = false;
          });
      },
    },

  };
</script>

<style>
.data-menu{
  display:absolute;
}
</style>
