<template>
  <v-row>
    <v-col cols="12" md="8" class="align-self-end">
      <Dashboard-congratulations></Dashboard-congratulations>
    </v-col>
    <v-col
      cols="12"
      sm="6"
      md="2"
      class="d-flex justify-center aling-items-center"
    >
      <dashboard-companies
        :companiesCount="companiesTotal"
      ></dashboard-companies>
    </v-col>

    <v-col cols="12" sm="6" md="2" class="align-self-end">
      <statistics-card-vertical
        :change="userSystem.change"
        :color="userSystem.color"
        :icon="userSystem.icon"
        :statistics="userSystem.statistics"
        :stat-title="userSystem.statTitle"
        :subtitle="userSystem.subtitle"
      ></statistics-card-vertical>
    </v-col>

    <v-col cols="12" md="4">
      <v-row class="match-height">
        <v-col cols="12">
          <dashboard-statistics-total-sales
            :marginByType="companiesByType"
            :companiesCount="companiesTotal"
          ></dashboard-statistics-total-sales>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="6">
          <statistics-card-vertical
            :change="RevenueOptions.change"
            :color="RevenueOptions.color"
            :icon="RevenueOptions.icon"
            :statistics="RevenueOptions.statistics / 100 | moneyBr"
            :stat-title="RevenueOptions.statTitle"
            :subtitle="RevenueOptions.subtitle"
          ></statistics-card-vertical>
        </v-col>
        <v-col cols="12" sm="6">
          <statistics-card-vertical
            :change="averageTicketOptions.change"
            :color="averageTicketOptions.color"
            :icon="averageTicketOptions.icon"
            :statistics="averageTicketOptions.statistics /100 | moneyBr"
            :stat-title="averageTicketOptions.statTitle"
            :subtitle="averageTicketOptions.subtitle"
          ></statistics-card-vertical>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" md="8">
      <Dashboard-card-transaction
        :iuguPayments="latestIuguPayments"
      ></Dashboard-card-transaction>
    </v-col>

    <v-col cols="12" md="8">
      <Dashboard-card-meeting-schedule
        :initialData="overdueCompanies"
      ></Dashboard-card-meeting-schedule>
    </v-col>

     <v-col cols="12" md="4">
      <Dashboard-user-table
        :initialData="highestTransactions"
      ></Dashboard-user-table>
    </v-col>

  </v-row>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiTrendingUp,
  mdiChartBox,
  mdiAccountGroup,
  mdiContrast,
} from "@mdi/js";

import StatisticsCardVertical from "@/@core/components/statistics-card/StatisticsCardVertical.vue";
import StatisticsCardLineChart from "@core/components/statistics-card/StatisticsCardLineChart.vue";
import StatisticsCardRadialBarChart from "@core/components/statistics-card/StatisticsCardRadialBarChart.vue";

// demos
import DashboardCongratulations from "./DashboardCongratulations.vue";
import DashboardTotalProfit from "./DashboardTotalProfit.vue";
import DashboardStatisticsTotalSales from "./DashboardStatisticsTotalSales.vue";
import DashboardCardTransaction from "./DashboardCardTransaction.vue";
import DashboardNewVisitorChart from "./DashboardNewVisitorChart.vue";
import DashboardCardWebsiteStatistics from "./DashboardCardWebsiteStatistics.vue";
import DashboardUserTable from "./DashboardUserTable.vue";
import DashboardCardMeetingSchedule from "./DashboardCardMeetingSchedule.vue";
import DashboardCompanies from "./DashboardCompanies.vue";
import StatisticsCardAreaChart from "@/@core/components/statistics-card/StatisticsCardAreaChart.vue";
import Filters from "@/util/Filters";

export default {
  components: {
    StatisticsCardVertical,
    StatisticsCardLineChart,
    StatisticsCardRadialBarChart,
    DashboardCongratulations,
    DashboardTotalProfit,
    DashboardStatisticsTotalSales,
    DashboardCardTransaction,
    DashboardNewVisitorChart,
    DashboardCardWebsiteStatistics,
    DashboardUserTable,
    DashboardCardMeetingSchedule,
    StatisticsCardAreaChart,
    DashboardCompanies,
  },
  data() {
    return {
      dashboardData: null,

      companiesTotal: {
        activeCompaniesTotal: null,
        inativeCompaniesTotal: null,
      },

      transactionsOptions: {
        statTitle: "Empresas",
        icon: mdiTrendingUp,
        color: "info",
        subtitle: "Empresas ativas",
        statistics: "1.2k",
        change: "",
      },

      userSystem: {
        statTitle: "Usuários do Sistema",
        icon: mdiAccountGroup,
        color: "success",
        subtitle: "",
        statistics: null,
        change: "",
      },

      RevenueOptions: {
        statTitle: "Receita",
        icon: mdiChartBox,
        color: "success",
        subtitle: "Receita do sistema",
        statistics: "44.1k",
        change: "",
      },

      averageTicketOptions: {
        statTitle: "Ticket médio",
        icon: mdiContrast,
        color: "info",
        subtitle: "Ticket médio do sistema",
        statistics: 0,
        change: "",
      },

      highestTransactions: null,
      overdueCompanies: null,

      companiesByType: null,

      latestIuguPayments: null,
    };
  },

  created() {
    this.getDashboardDatas();
  },

  methods: {
    getDashboardDatas() {
      this.$http.index("/dashboard").then((response) => {
        // Usuarios do sistema
        this.userSystem.statistics =
          response.systemUsersTotal[0].total.toString();

        // Melhores Empresas
        this.highestTransactions = response.highestTransactionsTotal;

        // Empresas inadimplentes
        this.overdueCompanies = response.overdueCompanys;

        // Empresas Divididas Por inativas e Ativas
        this.companiesTotal.activeCompaniesTotal =
          response.activeCompaniesTotal.value;
        this.companiesTotal.inativeCompaniesTotal =
          response.inativeCompaniesTotal.value;

        // Ticket médio
        this.averageTicketOptions.statistics =
          response.averageTicketTotal.average;

        // Receita do sistema
        this.RevenueOptions.statistics = response.totalRevenue.total;

        // Margem por tipo de emmpresas
        this.companiesByType = response.marginByTypeCompany;

        // Ultimas faturas iugu, limitadas nas 5 mais recentes
        this.latestIuguPayments = response.latestIuguPaymentsTotal;
      });
    },
  },
};
</script>
