<template>
  <div>
    <v-card>
        <v-card-text class="d-flex align-center flex-wrap pb-0">
            <div class="d-flex">
                <v-combobox dense label="Selecionar Conta" v-model="selectedAccount" :items="accounts" item-text="description" item-value="id" outlined @change="getCashMovimentTransactions()"></v-combobox>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs}">
                        <v-badge
                            color="success"
                            :content="filteredMoviments.length"
                            :value="filterApply && filteredMoviments.length > 0"
                            overlap
                        >
                            <v-btn icon :disabled="moviments.length == 0" @click="applyFilter()" v-bind="attrs" v-on="on" class="ma-1">
                            <v-icon :color="filterApply ? 'success' : 'secondary'">{{ icons.mdiMagnify }}</v-icon>
                            </v-btn>
                        </v-badge>
                    </template>
                    <span>{{ filterApply ? 'Retirar Filtro' : 'Aplicar Filtro' }}</span>
                </v-tooltip>
            </div>
            <v-spacer></v-spacer>
            <v-btn color="secondary" class="mb-4 me-3 text-capitalize" @click="openConfigs">
                <v-icon>
                    {{ icons.mdiCog }}
                </v-icon>
            </v-btn>
        </v-card-text>
    </v-card>
    <v-data-table
        :headers="tableColumns"
        :items="movimentShow"
        :loading='loading'
        loading-text="Carregando Tabela..."
    >
        <template #[`item.type`]="{item}" >
            <v-icon color="success" v-if="item.type === 'income'" class="d-flex justify-center">
                {{ icons.mdiArrowTopRightThick }}
            </v-icon>
            <v-icon v-else color="error" class="d-flex justify-center">
                {{ icons.mdiArrowBottomLeftThick }}
            </v-icon>
        </template>

        <template #[`item.value`]="{item}">
          <span :style="{color: item.type == 'income' ? 'lightgreen' : 'tomato'}">
            {{ parseCurrency(item.value) }}
          </span>
        </template>

        <template #[`item.account_plan`]="{item}" >
            {{ item.account_plan.description }}
        </template>

        <template #[`item.status`]="{item}" >
            <v-chip color="success" v-if="item.status == 'processed'" small>
                Processado
            </v-chip>
            <v-chip color="error" v-if="item.status == 'cancelled'" small>
                Cancelado
            </v-chip>
        </template>

        <template #[`item.created_at`]="{item}" >
            {{ new Date(item.created_at).toLocaleDateString("pt-br") }}
        </template>
    </v-data-table>
    <cash-moviments-filter ref="filterForm" :data="moviments" @filter="f => {this.filteredMoviments = f; filterApply = true}"></cash-moviments-filter>
  </div>
</template>

<script>
import { mdiArrowBottomLeftThick, mdiArrowTopRightThick, mdiCog, mdiDotsVertical, mdiFileDocumentOutline, mdiDeleteOutline, mdiMagnify } from '@mdi/js';
import CashMovimentsFilter from "@/components/company/filters/cashMovimentsFilter";


const formatador = Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
  minimumFractionDigits: 2,
})

export default {
    props: {companyId:null},
    components: { CashMovimentsFilter },
    data(){
        return {
            tableColumns: [
                {text: '', value: 'type'},
                {text: 'Descrição', value: 'description'},
                {text: 'Plano', value: 'account_plan'},
                {text: 'Status', value: 'status'},
                {text: 'Valor', value: 'value'},
                {text: 'Data', value: 'created_at'}
            ],

            icons: {
                mdiCog,
                mdiArrowBottomLeftThick,
                mdiArrowTopRightThick,
                mdiDotsVertical,
                mdiFileDocumentOutline,
                mdiDeleteOutline,
                mdiMagnify
            },

            loading: false,
            accounts: [],
            filterApply: false,
            moviments: [],
            filteredMoviments: [],
            selectedAccount: null,

        }
    },

    created(){
        this.getCashMovimentAccounts()
    },

    computed: {
        movimentShow(){
            return this.filterApply ? this.filteredMoviments : this.moviments
        }
    },

    methods:{
        // TODO implementar conexão ao backend como admin, trazer contas
        getCashMovimentAccounts(){
            this.loading = true
            this.$http.index('/account', {company_id:this.companyId} ).then((response) => {
                    this.loading = false
                    this.accounts = response.data.data.map((e) => { return {description: e.description , id:e.id}})
                    this.selectedAccount = this.accounts[0]
                    this.getCashMovimentTransactions()
                
            }).catch( err => {
                console.log(err)
                this.loading = false
            })
        },

        // TODO implementar conexão ao backend como admin, trazer movimentações das contas
        getCashMovimentTransactions(){
            this.loading = true
            this.$http.index('/account-transaction', {account_id: this.selectedAccount.id}).then((response) => {
                this.moviments = response.data.data
                this.loading = false

            }).catch( err => {
                console.log(err);
                this.loading = false
            })
        },

        openConfigs(){
            console.log('open configs');
        },

        parseCurrency(num) {
            return formatador.format(num / 100)
        },

        applyFilter(){
            if(this.filterApply){
                this.filterApply = false
            }else{
                this.$refs.filterForm.open()
            }
        }
    }
}
</script>

<style>

</style>