<template>
  <v-card>
    <v-card-title class="align-start">
      <span>
        Melhores empresas
        <v-icon size="30" color="success">
          {{ icons.mdiTrendingUp }}
        </v-icon>
      </span>
    </v-card-title>
    <v-data-table
      :items-per-page="4"
      :page.sync="pageCount"
      :headers="tableColumnHeaders"
      :items="userlist"
      hide-default-footer
    >
      <template v-slot:item.name="{ item }">
        <div class="d-flex align-center">
          <v-avatar
            :color="item.avatar ? '' : 'secondary'"
            :class="item.avatar ? '' : 'v-avatar-light-bg secondary--text'"
            size="32"
          >
            <v-img :src="require(`@/assets/images/pages/company.png`)"></v-img>
            <!-- <span v-else class="font-weight-medium">{{ avatarText(item.name) }}</span> -->
          </v-avatar>

          <div class="d-flex flex-column ms-3">
            {{ item.name }}

            <small>{{ item.email }}</small>
          </div>
        </div>
      </template>

      <template v-slot:item.value_sum="{ item }">
        <span>{{ item.value_sum / 100 | moneyBr }}</span>
      </template>

      <template #[`footer`] class="d-flex">
        <v-pagination v-model="page" :length="pageCount"></v-pagination>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiEmailOutline,
  mdiChartTimelineVariant,
  mdiArrowDown,
  mdiTrendingUp,
} from "@mdi/js";
import { watch } from "vue-demi";

export default {
  props: {
    initialData: Array,
  },

  data() {
    return {
      userlist: [],

      page: null,
      pageCount: null,

      tableColumnHeaders: [
        { text: "EMPRESA", value: "name" },
        { text: "TOTAL", value: "value_sum" },
      ],

      icons: {
        mdiEmailOutline,
        mdiChartTimelineVariant,
        mdiArrowDown,
        mdiTrendingUp,
      },
    };
  },

  watch: {
    async initialData(val) {
      this.userlist = await val;
    },
  },

  methods: {
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");

      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },
  },
};
</script>
