<template>
  <div>
    <h1 class="ma-2 ml-0">
      Empresa: "{{ company.name }}"
      <v-chip small class="ma-1" color="primary">
        {{ companyType }}
      </v-chip>
    </h1>

    <v-card flat>
      <v-tabs v-model="tab" grow>
        <v-tab>Dados</v-tab>
        <v-tab>Vendas</v-tab>
        <v-tab>NFS-e</v-tab>
        <v-tab>Financeiro</v-tab>
        <v-tab>Usuarios</v-tab>
        <v-tab>Documentos</v-tab>
        <v-tab>Logs</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card-text class="pa-6">
            <CompanyFormTab
              @updateCompany="getCompany"
              :initialData="company"
              :dense="true"
            ></CompanyFormTab>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <v-tabs-items v-model="orderTab">
              <v-tab-item>
                <OrdersTab @import="orderTab = 1" ref="orders"></OrdersTab>
              </v-tab-item>
              <v-tab-item>
                <ImportOrdersTab @import="importOrders"></ImportOrdersTab>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <OutgoingInvoicesTab></OutgoingInvoicesTab>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <CashMovimentsTab :companyId="companyId"></CashMovimentsTab>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <UsersTab :tenantId="company.tenant_id"></UsersTab>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <DocumentsTab></DocumentsTab>
          </v-card-text>
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <LogsTab></LogsTab>
          </v-card-text>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import { mdiInformation } from "@mdi/js";

import CompanyFormTab from "@/components/company/tabs/CompanyFormTab.vue";
import OrdersTab from "@/components/company/tabs/OrdersTab.vue";
import ImportOrdersTab from "@/components/company/tabs/ImportOrdersTab.vue";
import CashMovimentsTab from "@/components/company/tabs/CashMovimentsTab.vue";
import UsersTab from "@/components/company/tabs/UsersTab.vue";
import DocumentsTab from "@/components/company/tabs/DocumentsTab.vue";
import LogsTab from "@/components/company/tabs/LogsTab.vue";
import OutgoingInvoicesTab from "@/components/company/tabs/OutgoingInvoicesTab.vue";

export default {
  components: {
    CompanyFormTab,
    OrdersTab,
    ImportOrdersTab,
    CashMovimentsTab,
    UsersTab,
    DocumentsTab,
    LogsTab,
    OutgoingInvoicesTab,
  },
  name: "CompanyScreen",
  data() {
    return {
      tab: 0,
      orderTab: 0,
      companyId: this.$route.params.id,
      icons: {
        mdiInformation,
      },
    };
  },

  beforeMount() {
    this.getCompany();
  },

  computed: {
    company() {
      return this.$store.state.companies.company;
    },

    companyType(){
      if(this.company.is_affiliated == true){
        return "Afiliado"
      }

      if(this.company.is_producer == true){
        return "Produtor"
      }

      if(this.company.is_dropshipper == true){
        return "Dropship"
      }

      return "Não configurado"
    }
  },

  methods: {
    importOrders() {
      this.orderTab = 0;
      this.$refs.orders.getOrders();
    },

    async getCompany() {
      await this.$store.dispatch("companies/getCompany", this.companyId);
    },
  },
};
</script>

<style scoped></style>
