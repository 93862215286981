import axios from 'axios'
import store from './../store/index'
import notification from '../helper/notification'

class Http {
  constructor(options) {
    this.httpClient = axios.create(options)
  }

  setAuthorizationToken(token) {
    this.httpClient.defaults.headers.common.Authorization = token
  }

  $get(url, params) {
    return new Promise((resolve, reject) => {
      this.httpClient.get(url, { params })
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          this.processError(error)
          reject(error)
        })
    })
  }

  $post(url, params) {
    return new Promise((resolve, reject) => {
      this.httpClient.post(url, params, {
        headers: {
          "Access-Control-Allow-Origin": "*",
        }
      })
        .then(response => {
          this.processSucces(response)
          resolve(response.data)
        }).catch(error => {
          this.processError(error)
          reject(error)
        })
    })
  }

  $put(url, params) {
    return new Promise((resolve, reject) => {
      this.httpClient.put(url, params)
        .then(response => {
          this.processSucces(response)
          resolve(response.data)
        }).catch(error => {
          reject(error)
          this.processError(error)
        })
    })
  }

  $patch(url, params) {
    return new Promise((resolve, reject) => {
      this.httpClient.patch(url, params)
        .then(response => {
          this.processSucces(response)
          resolve(response.data)
        }).catch(error => {
          reject(error)
          this.processError(error)
        })
    })
  }

  $delete(url) {
    return new Promise((resolve, reject) => {
      this.httpClient.delete(url)
        .then(response => {
          this.processSucces(response)
          resolve(response.data)
        }).catch(error => {
          reject(error)
          this.processError(error)
        })
    })
  }

  index(url, params = null) {
    return this.$get(url, params)
  }

  show(url, id, params = null) {
    return this.$get(`${url}/${id}`, params)
  }

  store(url, params) {
    return this.$post(url, params)
  }

  update(url, id, params) {
    return this.$put(`${url}/${id}`, params)
  }

  destroy(url, id) {
    this.$delete(`${url}/${id}`)
  }

  processError(error) {
    notification.showError(error)
    store.commit('errorHandler/setHttpError', error)
  }

  processSucces(msg) {
    notification.showSuccessMsg(msg)
  }
}

export default Http
