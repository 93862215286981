<template>
  <v-card>
    <div class="d-flex flex-column px-8">
      <v-card-title class="align-start"> Empresas </v-card-title>

      <vue-apex-charts
        id="chart-stats-total-sales"
        height="140"
        :options="chartOptions"
        :series="chartSeries"
        style="position: relative; bottom: 20px"
      />
    </div>
  </v-card>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  props: {
    companiesCount: [],
  },
  components: {
    VueApexCharts,
  },
  data() {
    return {
      chartSeries: [],

      chartOptions: {
        chart: {
          offsetY: 7,
          type: "donut",
          sparkline: {
            enabled: true,
          },
          animations: {
            enabled: false,
          },
        },
        stroke: {
          colors: ["#fff"],
          width: 8,
        },
        grid: {
          padding: {
            bottom: 10,
          },
        },
        colors: [
          this.$vuetify.theme.currentTheme.primary,
          this.$vuetify.theme.currentTheme.error,
        ],

        labels: ["ativos", "inativos"],

        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: "14px",
                  offsetY: 25,
                },
                value: {
                  offsetY: -13,
                  formatter(value) {
                    return `${value}`;
                  },
                },
                total: {
                  show: true,
                  label: "Cadastradas",
                  formatter(value) {
                    return `${value.config.series[0] + value.config.series[1]}`;
                  },
                },
              },
            },
          },
        },
      },
    };
  },

  watch: {
    companiesCount: {
      handler: function (value, oldValue) {
        this.chartSeries = [
          this.companiesCount.activeCompaniesTotal,
          this.companiesCount.inativeCompaniesTotal,
        ];
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss">
#chart-stats-total-sales {
  max-width: 150px;
  height: auto;
  .apexcharts-pie {
    .apexcharts-pie-series .apexcharts-pie-area {
      stroke-width: 10;
    }
  }
  .apexcharts-canvas {
    .apexcharts-text {
      &.apexcharts-datalabel-value {
        font-weight: 600;
        font-size: 1.25rem;
      }
      &.apexcharts-datalabel-label {
        font-size: 0.75rem;
        margin-bottom: 2rem;
        transform: translateY(-7px);
      }
    }
  }
}

.v-application {
  &.theme--dark {
    #chart-stats-total-sales {
      path {
        stroke: #312d4b;
      }
    }
  }
}
</style>
