var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"ma-2 ml-0"},[_vm._v("Usuários")]),_c('v-card',[_c('v-card-title',{staticClass:"d-flex align-center justify-space-between py-sm-2 py-6"},[_c('div',{staticClass:"row"},[_c('v-text-field',{staticClass:"user-search col-sm-6 pa-2",attrs:{"placeholder":"Pesquisar","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('div',{class:{ 'w-full py-2': _vm.isMobile }},[_c('v-btn',{staticClass:"ma-sm-2 my-2 text-capitalize",attrs:{"color":"success","block":_vm.isMobile},on:{"click":function($event){return _vm.newUser()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Novo Usuário")])],1)],1)]),_c('v-data-table',{attrs:{"search":_vm.searchQuery,"headers":_vm.tableColumns,"items":_vm.users,"options":_vm.options,"loading":_vm.loading,"loading-text":"Carregando Tabela...","show-select":"","hide-default-footer":true},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:item.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.avatar ? '' : 'primary',"size":"32"}},[(item.avatar)?_c('v-img',{attrs:{"src":require(("@/assets/images/avatars/" + (item.avatar)))}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.name)))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name: 'users', params: { id: item.id } }}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('small',[_vm._v("@"+_vm._s(item.name))])],1)],1)]}},{key:"item.roles",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:("v-avatar-light-bg " + (_vm.resolveUserRoleVariant(
              item.roles[0].name
            )) + "--text me-3"),attrs:{"size":"30","color":_vm.resolveUserRoleVariant(item.roles[0].name)}},[_c('v-icon',{attrs:{"size":"18","color":_vm.resolveUserRoleVariant(item.roles[0].name)}},[_vm._v(" "+_vm._s(_vm.resolveUserRoleIcon(item.roles[0].name))+" ")])],1),_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.roles[0].name))])],1)]}},{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveUserStatusVariant(item.status)) + "--text"),attrs:{"small":"","color":_vm.resolveUserStatusVariant(item.status)}},[(item.status === 1)?_c('div',[_vm._v("Ativo")]):_vm._e(),(item.status === 0)?_c('div',[_vm._v("Inativo")]):_vm._e()])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.editUser(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Editar")])],1)],1),(item.status == true)?[_c('v-list-item',{on:{"click":function($event){return _vm.openAlertConfirmationInactive(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v(" Inativar")])],1)],1)]:[_c('v-list-item',{on:{"click":function($event){return _vm.openAlertConfirmationInactive(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckOutline)+" ")]),_c('span',[_vm._v("Ativar")])],1)],1)]],2)],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}}),_c('v-divider'),_c('div',{staticClass:"d-flex justify-end"},[_c('v-pagination',{staticClass:"paginate",attrs:{"length":_vm.lastPage,"total-visible":"6"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1),_c('UserForm',{ref:"userForm",on:{"onSubmit":_vm.getUsers}}),_c('Alert',{ref:"alert",on:{"inactive":function($event){return _vm.inactiveUser($event)},"active":function($event){return _vm.activeUser($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }