<template>
<div>
  <v-dialog v-model="dialog" max-width="800px" origin="center center" persistent :fullscreen="isMobile">
    <v-card >
      <v-card-title>{{ isUpdating ? 'Editar' : 'Cadastrar' }} Usuário</v-card-title>
      <v-divider></v-divider>
      <v-card-title>
        <v-icon class="mr-1">
          {{ icons.mdiAccount }}
        </v-icon>
        <small>Dados</small>
      </v-card-title>
      <div v-if="loading" class="d-flex justify-center">
        <v-progress-circular
          indeterminate
          color="secondary"
        ></v-progress-circular>
      </div>
      <v-form v-if="!loading" v-model="formValid" ref="form">
        <v-card-text>
          <div class="row col-xs">
            <div class="col-md-8">
              <v-text-field
                v-model="userForm.name"
                label="Nome"
                :rules="[rules.required]"
                outlined
                hide-details="auto"
                dense
              />
            </div>
            <div class="col-md-4">
              <v-text-field
                v-model="userForm.cpf"
                label="CPF"
                :rules="[rules.required, rules.cpf]"
                v-mask="'###.###.###-##'"
                outlined
                hide-details="auto"
                dense
              />
            </div>
          </div>
          <div class="row col-xs">
            <div class="col-md-6">
              <v-text-field
                v-model="userForm.email"
                label="email"
                :rules="[rules.required, rules.email]"
                outlined
                hide-details="auto"
                dense
              />
            </div>
            <div class="col-md-6">
              <v-text-field
                v-model="userForm.phone"
                label="Telefone"
                :rules="[rules.required, rules.phoneNumber]"
                v-mask="'(##) # ####-####'"
                outlined
                hide-details="auto"
                dense
              />
            </div>
          </div>
          <div class="row col-xs">
            <div class="col-md-6">
              <v-text-field
                v-model="userForm.password"
                outlined
                :type="isPasswordVisible ? 'text' : 'password'"
                label="Senha"
                placeholder="Password"
                :append-icon="passwordVisibleIcon"
                hide-details="auto"
                @click:append="isPasswordVisible = !isPasswordVisible"
                dense
              />
              <v-select
              v-model="userForm.roleSelect"
              :items="roleSelectItems"
              item-text="text" item-value="value"
              label="Função"
              hide-details="auto"
              outlined
              class="mt-6"
          ></v-select>
            </div>
            <div class="col-md-6">
              <v-text-field
                v-model="userForm.confirmPassword"
                outlined
                :type="isPasswordVisible ? 'text' : 'password'"
                label="Confirmar Senha"
                placeholder="Password"
                :append-icon="passwordVisibleIcon"
                hide-details="auto"
                @click:append="isPasswordVisible = !isPasswordVisible"
                dense
              />
            </div>
          </div>
        </v-card-text>
      </v-form>
      <v-divider></v-divider>
      <v-card-title class="pb-0">
        <v-icon class="mr-1">
          {{ icons.mdiMapMarker }}
        </v-icon>
        <small>Endereço</small>
      </v-card-title>
      <v-card-text class="pa-5 pb-0">
        <div v-if="loading" class="d-flex justify-center">
          <v-progress-circular
            indeterminate
            color="secondary"
          ></v-progress-circular>
        </div>
      </v-card-text>
      <v-card-text>
        <AddressComponent v-if="!loading" ref="address" :initialData="userForm.address" @submit="handleAddress" :dense="true"/>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          class="text-capitalize"
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn class="text-capitalize" color="success"  @click="submitForm">
          <v-icon>{{ icons.mdiContentSave }}</v-icon>
          Salvar Informações
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import AddressComponent from '@/components/app/address/AddressComponent'
import { OPTIONS_UF } from '@/constants/option'
import { mdiAccount, mdiEyeOutline, mdiEyeOffOutline, mdiMapSearch, mdiContentSave, mdiMapMarker } from '@mdi/js'


export default {
  components: { AddressComponent },

  data() {
    return {
      userId:null,
      uf: OPTIONS_UF,
      dialog: false,
      icons: {
        mdiAccount,
        mdiMapSearch,
        mdiContentSave,
        mdiMapMarker
      },
       roleSelectItems: [
        { text: 'contador(a)', value: 'accountant' },
        { text: 'Usuário', value: 'user' },
        { text: 'Administrador', value: 'admin' },
        { text: 'Super Administrador', value: 'super-admin' },
      ],

       userForm: {
        name: null,
        email: null,
        cpf: null,
        phone: null,
        password: null,
        confirmPassword: null,
        status: true,
        roleSelect: null,

        address: {
          cep: null,
          number: null,
          street: null,
          neighborhood: null,
          complement: null,
          state: null,
          city: null,
        }
        },

      isPasswordVisible: false,
      isUpdating: false,
      formValid: null,
      addressFormValid: null,
      loading: false,
      rules: {
        required: v => !!v || 'Campo obrigatório.',
        cpf: v => /^\d{3}.?\d{3}.?\d{3}-?\d{2}$/.test(v) || 'Insira um CPF valido',
        email: v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Insira um e-mail valido.',
        phoneNumber: v => /(\(?\d{2}\)?\s)?(\d{4,5}-?\d{4})/.test(v) || 'Insira um numero de telefone valido.',
      },
    }
  },

  computed: {
    passwordVisibleIcon() {
      return this.isPasswordVisible ? mdiEyeOffOutline : mdiEyeOutline
    },
    isMobile() {
      return window.innerWidth <= 600
    },
  },

  methods: {
    openInactiveDialog(id) {
        this.userId = id
    },
    open(id) {

      if(id){
        this.userId = id
        this.isUpdating = true
        this.getUserData(id)
      }

     this.dialog = true
    },

    async getUserData(id){
      await this.$http.$get(`/users/${id}`)
      .then(response => {
            this.userForm = response.data
            this.loading = false
      })
      .catch(error => {
        console.log(error)
      })
    },

    handleAddress(e) {
      this.userForm.main_address = e
    },

    submitForm() {
      // let formValid = this.$refs.form.validate()
      // let addressValid = this.$refs.address.validate()
      if (this.isUpdating) {
        this.$http.$put(`/users/${this.userId}`, this.userForm).then(res => {
          if (res.status == 200) {
            this.dialog = false
            this.$emit('onSubmit')
          }
        })
      } else {
        this.$http.$post('/users', this.userForm).then(res => {
          if (res.status == 200) {
            this.dialog = false
            this.$emit('onSubmit')
          }
        })
      }
    },

  },
}
</script>

<style>
</style>
