<template>
  <div>
    <h1 class="ma-2 ml-0">Modelos de Contrato</h1>
    <v-card>
      <v-card-title
        class="d-flex align-center justify-space-between py-sm-2 py-6"
      >
        <div class="row">
          <v-text-field
            v-model="searchQuery"
            placeholder="Pesquisar"
            outlined
            hide-details
            dense
            class="user-search col-sm-6 pa-2"
          >
          </v-text-field>
        </div>

        <div :class="{ 'w-full py-2': isMobile }">
          <v-btn
            color="success"
            class="ma-sm-2 my-2 text-capitalize"
            :block="isMobile"
            @click="opencontractsForm()"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>Novo Contrato</span>
          </v-btn>
        </div>
      </v-card-title>

      <v-data-table
        :items-per-page="25"
        :page.sync="pageCount"
        v-model="selectedRows"
        :headers="tableColumns"
        :items="contracts"
        loading-text="Carregando Tabela..."
      >
        <template v-slot:item.plan="{ item }">
          <span>
            {{ item.plan.integration_plan_id }}
          </span>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="opencontractsForm(item.id)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Editar</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteContractModel(item.id)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">
                    {{ icons.mdiTrashCanOutline }}
                  </v-icon>
                  <span>Excluir</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
    </v-card>
    <contract-form ref="contractForm" @stored="getcontracts()"/>
  </div>
</template>

<script>
import {
  mdiPlus,
  mdiDotsVertical,
  mdiFileDocumentOutline,
  mdiTrashCanOutline,
} from "@mdi/js";

import Filter from "@/util/Filters";
import ContractForm from '@/components/contract/form/ContractForm.vue';

export default {
  components: { ContractForm },
  data() {
    return {
      selectedRows: [],
      contracts: [],
      searchQuery: "",
      page: null,
      pageCount: null,

      icons: {
        mdiPlus,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiTrashCanOutline,
      },

      tableColumns: [
        { text: "Plano", value: "plan", sortable: false },
        { text: "Nome", value: "name", sortable: false },
        { text: "Hash", value: "hash", sortable: false },
        { text: "Valor", value: "value", sortable: false },
        { text: "Inicio", value: "start_date", sortable: false },
        { text: "Fim", value: "end_date", sortable: false },
        { text: "Assinado", value: "has_signature", sortable: false },
        { text: "Status", value: "status", sortable: false },
        {
          text: "AÇÃO",
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
    };
  },

  created() {
    this.getcontracts();
  },

  computed: {
    isMobile() {
      return window.innerWidth <= 600;
    },
  },

  methods: {
    getcontracts() {
      this.loading = true;
      this.$http
        .index("/contracts")
        .then((response) => {
          this.contracts = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteContractModel(id) {
      console.log(id);
      this.$http
        .$delete(`/contracts/${id}`)
        .then((response) => {
          this.getcontracts();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    opencontractsForm() {
      this.$refs.contractForm.open();
    },
  },
};
</script>

<style></style>
