<template>
  <v-expansion-panels focusable>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <v-card-title>
          <v-icon class="ma-2">{{ icons.mdiTextBox }}</v-icon>
          Certificado Digital
        </v-card-title>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div>
          <v-data-table
            :items-per-page="25"
            :items="certificate"
            item-key="id"
            :headers="tableColumns"
            loading-text="Carregando dados..."
          >
          </v-data-table>
          <div class="col-3">
            <v-btn
              @click="openDialog()"
              color="success"
              class="text-capitalize"
            >
              <v-icon>
                {{ icons.mdiFileDocumentOutline }}
              </v-icon>
              Enviar Certificado
            </v-btn>
          </div>
          <v-dialog v-model="dialog" max-width="800px" persistent>
            <v-card>
              <v-card-title> Cadastrar Certificado </v-card-title>
              <v-card-text>
                <v-form ref="form">
                  <div class="row col-xs">
                    <div class="col">
                      <v-file-input
                        v-model="file"
                        label="Selecionar arquivo..."
                        outlined
                        dense
                        :rules="[rules.required]"
                        hide-details="auto"
                        @change="setFile($event)"
                      ></v-file-input>
                    </div>
                    <div class="col">
                      <v-text-field
                        v-model="password"
                        outlined
                        dense
                        :type="isPasswordVisible ? 'text' : 'password'"
                        label="Senha"
                        placeholder="Senha"
                        :rules="[rules.required]"
                        :append-icon="passwordVisibleIcon"
                        hide-details="auto"
                        class="mb-6"
                        @click:append="isPasswordVisible = !isPasswordVisible"
                      ></v-text-field>
                    </div>
                  </div>
                </v-form>
              </v-card-text>
              <v-card-actions class="ma-4">
                <v-spacer></v-spacer>
                <v-btn
                  color="secondary"
                  @click="dialog = false"
                  class="text-capitalize"
                >
                  Cancelar
                </v-btn>
                <v-btn
                  color="success"
                  @click="submitCertificate()"
                  class="text-capitalize"
                >
                  <v-icon>
                    {{ icons.mdiContentSave }}
                  </v-icon>
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import {
  mdiContentSave,
  mdiEyeOffOutline,
  mdiEyeOutline,
  mdiFileDocumentOutline,
  mdiTextBox,
} from "@mdi/js";
import { OPTIONS_CRT } from "@/constants/option";

export default {
  props: {
    initialData: {},
  },

  data() {
    return {
      dialog: false,
      isPasswordVisible: false,

      rules: {
        required: (v) => !!v || "Campo obrigatório.",
      },

      icons: {
        mdiFileDocumentOutline,
        mdiContentSave,
        mdiTextBox,
      },

      tableColumns: [
        { text: "Arquivo", value: "original_file_name" },
        { text: "Validade", value: "valid_to_date" },
        { text: "Emissão", value: "valid_from_date" },
      ],

      certificate: [],

      file: null,

      password: null,
    };
  },

  mounted(){
    this.certificate.push(this.initialData);
    this.file = this.initialData.file;
  },

  watch:{
    initialData(newData){
      // this.file = this.newData.file;
      this.certificate.push(newData);
    },
  },

  computed: {
    isMobile() {
      return window.innerWidth <= 600;
    },
    passwordVisibleIcon() {
      return this.isPasswordVisible ? mdiEyeOffOutline : mdiEyeOutline;
    },
  },

  methods: {
    setFile(event) {
      this.file = event;
    },

    submitCertificate() {
      if (this.$refs.form.validate()) {
        this.$emit("update", this.password, this.file);
        this.dialog = false;
      }
    },
    openDialog() {
      this.dialog = true;
    },
  },
};
</script>

<style></style>
