<template>
  <div>
    <v-dialog 
      v-model="dialog" 
        max-width="800px" 
        origin="center center" 
        persistent
      >
      <v-card>
        <v-card-title>Nova empresa</v-card-title>
        <v-divider></v-divider>
        <v-card-title class="mb-0">
          <v-icon class="mr-1">
            {{ icons.mdiAccount }}
          </v-icon>
          <small>Dados</small>
        </v-card-title>
        <div v-if="loading" class="d-flex justify-center">
          <v-progress-circular indeterminate color="secondary"></v-progress-circular>
        </div>
        <v-form v-if="!loading" v-model="formValid" ref="clientForm">
          <v-card-text>
            <div class="row col-xs">
              <div class="col-md-12">
                <v-select 
                  v-model="plan" 
                  :items="plans" 
                  :rules="[rules.required]" 
                  filled 
                  item-text="name"
                  item-value="identifier" 
                  label="Plano" 
                  outlined />
              </div>
            </div>
            <div class="row col-xs">
              <div class="col-md-6">
                <v-text-field 
                  v-model="clientForm.name" 
                    label="Nome completo" 
                    :rules="[rules.required]" 
                    outlined
                    hide-details="auto" 
                    dense
                />
              </div>
              <div class="col-md-6">
                <v-text-field 
                  v-model="clientForm.cpf" 
                  v-mask="'###.###.###-##'" 
                  label="CPF"
                  :rules="[rules.required, rules.cpf]" 
                  outlined 
                  hide-details="auto" 
                  dense
                />
              </div>
            </div>
            <div class="row col-xs">
              <div class="col-md-6">
                <v-text-field 
                  v-model="clientForm.email" 
                  label="Email" 
                  :rules="[rules.required]" 
                  outlined
                  hide-details="auto" 
                  dense
                />
              </div>
              <div class="col-md-6">
                <v-text-field 
                  v-model="clientForm.phone" 
                  v-mask="'(##) # ####-####'" 
                  label="Telefone"
                  :rules="[rules.required]" 
                  outlined 
                  hide-details="auto" 
                  dense
                />
              </div>
            </div>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-title class="pb-0">
          <v-icon class="mr-1">
            {{ icons.mdiMapMarker }}
          </v-icon>
          <small>Endereço</small>
        </v-card-title>
        <v-card-text class="pa-5 pb-0">
          <div v-if="loading" class="d-flex justify-center">
            <v-progress-circular indeterminate color="secondary" />
          </div>
        </v-card-text>
        <v-card-text>
          <address-component ref="clientAddress" @update="handleClientAddress" />
        </v-card-text>
        <div v-if="alreadyHasCompany">
          <v-divider></v-divider>
          <v-card-title class="pb-0">
            <v-icon class="mr-1">
              {{ icons.mdiDomain }}
            </v-icon>
            <small>Dados da empresa</small>
          </v-card-title>
          <v-card-text class="pa-5 pb-0">
            <div v-if="loading" class="d-flex justify-center">
              <v-progress-circular indeterminate color="secondary"></v-progress-circular>
            </div>
          </v-card-text>
          <v-card-text>
            <div class="row col-xs">
              <div class="col-md-6">
                <v-text-field 
                  v-model="companyForm.cnpj" 
                  v-mask="'##.###.###/####-##'" 
                  label="CNPJ" 
                  @blur="checkCNPJ"
                  :rules="[rules.required, rules.cnpj]" 
                  outlined 
                  hide-details="auto" 
                  dense
                />
              </div>
              <div class="col-md-6">
                <v-text-field 
                  v-model="companyForm.razaoSocial" 
                  label="Razão social" 
                  :rules="[rules.required]" 
                  outlined
                  hide-details="auto" 
                  dense
                />
              </div>
            </div>
            <div class="row col-xs">
              <div class="col-md-6">
                <v-text-field 
                  v-model="companyForm.nomeFantasia" 
                  label="Nome fantasia" 
                  :rules="[rules.required]" 
                  outlined
                  hide-details="auto" 
                  dense />
              </div>
              <div class="col-md-6">
                <v-text-field 
                  v-model="companyForm.inscricaoMunicipal" 
                  label="Inscrição municipal"
                  :rules="[rules.required]" 
                  outlined 
                  hide-details="auto" 
                  dense
                />
              </div>
            </div>
            <div class="row col-xs d-flex align-center justify-center">
              <div class="col-md-6">
                <v-text-field 
                  v-model="companyForm.inscricaoEstadual" 
                  v-if="!isentoDeInscriçãoEstadual"
                  label="Inscrição estadual" 
                  :rules="[rules.required]" 
                  outlined 
                  hide-details="auto" 
                  dense
                />
              </div>
              <div class="col-md-6">
                <v-checkbox v-model="isentoDeInscriçãoEstadual" 
                  label="Isento de inscrição estadual."
                />
              </div>
            </div>
          </v-card-text>
          <v-divider></v-divider>
        </div>
        <div v-if="alreadyHasCompany">
          <v-card-title class="pb-0">
            <v-icon class="mr-1">
              {{ icons.mdiMapMarkerOutline }}
            </v-icon>
            <small>Endereço da empresa</small>
          </v-card-title>
          <v-card-text class="pa-5 pb-0">
            <div v-if="loading" class="d-flex justify-center">
              <v-progress-circular 
                indeterminate 
                color="secondary"
              />
            </div>
          </v-card-text>
          <v-card-text>
            <address-component ref="companyAddress" @update="handleCompanyAddress" />
          </v-card-text>
        </div>
        <v-card-text>
          <div class="row col-xs">
            <div class="col-md-6">
              <v-checkbox v-model="alreadyHasCompany" label="Já possui uma empresa." />
            </div>
          </div>
        </v-card-text>
        <div class="text-center">
          <v-dialog v-model="submitSuccess" width="500" persistent>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2"> Sucesso! </v-card-title>

              <v-card-text>
                A inscrição foi enviada sem problemas. O e-mail com o contrato de prestação do
                nosso serviço para assinatura foi enviado!
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="() => this.$router.go()"> Ok! </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
        <div class="text-center">
          <v-dialog v-model="submitFailure" width="500" persistent>
            <v-card>
              <v-card-title class="text-h5 grey lighten-2"> Oops... </v-card-title>
    
              <v-card-text>
                Aparentemente, encontramos problemas tecnicos ao enviar ou processar suas informações no servidor. Tente
                novamente mais tarde.
              </v-card-text>
    
              <v-divider></v-divider>
    
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="() => this.$router.go()"> Entendi. </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" class="text-capitalize" @click="dialog = false">
            Cancelar
          </v-btn>
          <v-btn class="text-capitalize" color="success" @click="submitForm">
            <v-icon>{{ icons.mdiContentSave }}</v-icon>
            Salvar Empresa
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-divider></v-divider>
    </v-dialog>
  </div>
</template>

<script>
import formMixin from '@/util/mixins/formMixin'
const axios = require("axios");
import notification from '@/helper/notification'
import AddressComponent from '@/components/app/address/AddressComponent.vue'
import {
  mdiAccount,
  mdiMapMarker,
  mdiDomain,
  mdiContentSave,
  mdiMapMarkerOutline
} from "@mdi/js"

export default {
  components: { AddressComponent },
  mixins: [formMixin],

  data() {
    return {
      dialog: false,
      loading: false,
      formValid: null,
      plans: [],
      plan: null,
      alreadyHasCompany: false,
      isentoDeInscriçãoEstadual: false,
      submitSuccess: false,
      submitFailure: false,
      payMethod: 'bank_slip',

      icons: {
        mdiAccount,
        mdiMapMarker,
        mdiDomain,
        mdiContentSave,
        mdiMapMarkerOutline
      },

      companyForm: {
        cnpj: null,
        razaoSocial: null,
        nomeFantasia: null,
        inscricaoEstadual: null,
        inscricaoMunicipal: null,
        address: {
          cep: null,
          street: null,
          neighborhood: null,
          number: null,
          complement: null,
          state: null,
          city: null,
        },
      },

      clientForm: {
        name: null,
        email: null,
        cpf: null,
        phone: null,
        address: {
          cep: null,
          number: null,
          street: null,
          neighborhood: null,
          complement: null,
          state: null,
          city: null,
        },
      },

      rules: {
        required: (v) => !!v || "Campo obrigatório.",
        cnpj: (v) =>
          /^\d{2}\.\d{3}\.\d{3}\/\d{4}-?\d{2}$/.test(v) ||
          "Insira um CNPJ valido",
      }
    }
  },

  computed: {
    user() {
      const base = {
        user: this.clientForm,
        plan: this.plan,
        payMethod: this.payMethod
      }
      if (this.alreadyHasCompany) {
        base.alreadyHasCompany = this.alreadyHasCompany
        base.company = this.companyForm
      }

      return base
    },
  },

  mounted() {
    this.getPlans()
  },

  methods: {
    checkCNPJ() {

      let parsedcnpj = this.companyForm.cnpj
        .replace(".", "")
        .replace(".", "")
        .replace("/", "")
        .replace("-", "");

      axios
        .get("https://brasilapi.com.br/api/cnpj/v1/" + parsedcnpj)
        .then((response) => {
          if (response.status == 200) {
            this.form = {
              cnpj: this.companyForm.cnpj,
              name: response.data.razao_social,
              alias: response.data.nome_fantasia,
              inscricao_estadual: this.companyForm.inscricaoEstadual,
              inscricao_municipal: this.companyForm.inscricaoMunicipal
            };
          }
        }).catch(error => {
          notification.showError(error);
        });
    },

    open() {
      this.dialog = true;
    },

    getPlans() {
      this.$http.index('/plans').then(response => {
        this.plans = response.data
      })
    },

    handleClientAddress(e) {
      this.clientForm.address = e
    },

    handleCompany(e) {
      let address = this.companyForm.address
      this.companyForm = e
      this.companyForm.address = address
    },

    handleCompanyAddress(e) {
      this.companyForm.address = e
    },

    async submitForm() {
      let clientValid = this.$refs.clientForm.validate()
      let addressValid = this.$refs.clientAddress.validate()
      let companyAddressValid = this.alreadyHasCompany ? this.$refs.companyAddress.validate() : true

      if (clientValid && addressValid && companyAddressValid) {
        await this.$store
          .dispatch('auth/signup', {
            url: 'auth/signup',
            params: this.user,
          }).then(response => {
            this.submitSuccess = true
          })
          .catch(error => {
            console.log(error)
            this.submitFailure = true
          })
      }
    }
  },
}
</script>

<style></style>