import Vue from "vue";
import format from "date-fns/format";

Vue.filter("dateBR", function(value) {
  
  if (value) {
    return format(new Date(value), "dd/MM/yyyy ");
  }

  return "";
});

//Filter CNPJ
Vue.filter("cnpj", function(value) {
  if (value) {
    return value.toString().replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      "$1.$2.$3/$4-$5"
    );
  }
  return "";
});

//Filter PHONE
Vue.filter("phone", function(value) {
  if (value) {
    value = value.replace(/D/g, ""); //Remove tudo o que não é dígito
    value = value.replace(/^(d{2})(d)/g, "($1) $2"); //Coloca parênteses em volta dos dois primeiros dígitos
    value = value.replace(/(d)(d{4})$/, "$1-$2"); //Coloca hífen entre o quarto e o quinto dígitos
    return value;
  }

  return null;
});

//Filter Dinheiro BR
Vue.filter("moneyBr", function(value) {
  if (!value) {
    var value = 0;
  }

  var parsedValue = parseFloat(value);
  var value = parsedValue.toFixed(2).split(".");

  value[0] = value[0].split(/(?=(?:...)*$)/).join(".");

  return "R$ " + value.join(",");
});

//Filter CPF
Vue.filter("cpf", function(value) {
  if (value) {
    return value.toString().replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  }

  return "";
});

//Filter CEP
Vue.filter("cep", function(value) {
  if (value) {
    value = value.toString().replace(/(\d{5})(\d{3})/g, "$1-$2");
    return value;
  }

  return "";
});
