
export default {
    data(){
        return {
            rules: {
                required: v => !!v || 'Campo obrigatório.',
                email: v => (v ? /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) : null) || 'Insira um e-mail valido.',
                phoneNumber: v => (v ? /(\(?\d{2}\)?\s)?(\d{4,5}-?\d{4})/.test(v) : null) || 'Insira um numero de telefone valido.',
                cnpj: v => /^\d{2}\.\d{3}\.\d{3}\/\d{4}-?\d{2}$/.test(v) || 'Insira um CNPJ valido',
                cpf: v => /^\d{3}.?\d{3}.?\d{3}-?\d{2}$/.test(v) || 'Insira um CPF valido',
                email: (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "Insira um e-mail valido.",
                nonZero: (v) => v != "0,00" || "O lançamento deverá ter algum valor.",
            },

        }
    }

}