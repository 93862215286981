var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-expansion-panels',{attrs:{"focusable":""}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-1"},[_vm._v(_vm._s(_vm.icons.mdiKey))]),_vm._v(" Chaves ")],1)],1),_c('v-expansion-panel-content',[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pa-0"},[_c('v-card-text',{staticClass:"px-0"},[_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-md-0 px-0"},[_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('v-btn',{attrs:{"color":"success text-capitalize mb-4"},on:{"click":function($event){return _vm.handleIntegration()}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v("Nova Chave")])],1)],1)],1),_c('v-card-text',{staticClass:"px-0"},[_c('v-data-table',{attrs:{"items-per-page":25,"page":_vm.pageCount,"headers":_vm.integrationsTableColumns,"items":_vm.integrations},on:{"update:page":function($event){_vm.pageCount=$event},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-chip',{attrs:{"label":"","outlined":"","color":item.type == 'webhook' ? 'success' : 'accent'}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(item.type == "webhook" ? _vm.icons.mdiBellRing : _vm.icons.mdiCog)+" ")]),_c('small',[_vm._v(" "+_vm._s(item.type)+" ")])],1)],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.handleIntegration(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Editar")])],1)],1),_c('v-list-item',[_c('v-list-item-title',{on:{"click":function($event){return _vm.deleteIntegrationKey(item.id)}}},[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Excluir")])],1)],1)],1)],1)]}},{key:"footer",fn:function(){return [_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true}],null,true)})],1)],1)],1)],1),_c('IntegrationForm',{ref:"integrationForm",on:{"onSubmit":_vm.update}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }