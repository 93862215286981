<template>
  <div>
    <v-card-title class="d-flex align-center justify-space-between py-sm-2 py-6">
      <div class="row align-center px-0">
        <v-text-field
          v-model="searchQuery"
          placeholder="Pesquisar"
          outlined hide-details dense
          class="user-search text-capitalize col-sm-4 col-10 py-6"
        ></v-text-field>
        <v-btn icon v-if="invoices.length">
          <v-icon :color="filterApply ? 'success' : 'secondary'" @click="applyFilter">
            {{ icons.mdiFilter }}
          </v-icon>
        </v-btn>
      </div>

      <transition name="slide-fade">
          <div
            class="my-2"
            :class="{ 'w-full py-2': isMobile }"
            v-show="selectedInvoices.length != 0"
          >
            <v-btn
              color="accent"
              class="ma-sm-2 my-2 text-capitalize"
              @click="reSend(getIdsOfSelectedsInvoices())"
              :block="isMobile"
            >
              <v-icon size="17" class="me-1">{{ icons.mdiRestore }}</v-icon>
              <span>Reenviar</span>
            </v-btn>

            <v-btn
              color="secondary"
              class="ma-sm-2 my-2 text-capitalize"
              @click="consult(getIdsOfSelectedsInvoices())"
              :block="isMobile"
            >
              <v-icon size="17" class="me-1">{{ icons.mdiRestore }}</v-icon>
              <span>Consultar</span>
            </v-btn>
          </div>
        </transition>
    </v-card-title>

    <v-data-table
      :items-per-page="25"
      :page.sync="page"
      show-select
      :search="searchQuery"
      item-key="number"
      v-model="selectedInvoices"
      :headers="tableColumns"
      :items="invoices"
      :loading="loading"
      loading-text="Carregando Notas..."
      @page-count="pageCount = $event"
      no-data-text="Nenhuma nota fiscal encontrada."
    >

      <template #[`item.company_id`]="{ item }">
        {{ item.company_id.name }}
      </template>

      <template #[`item.customer`]="{ item }">
        {{ item.customer.name }}
      </template>

      <template #[`item.chave`]="{ item }">
        {{ item.chave }}
      </template>

      <template #[`item.value`]="{ item }">
        <span style="color: green">
          {{ item.value / 100 | moneyBr }}
        </span>
      </template>

      <template #[`item.emited_at`]="{ item }">
        {{ item.emited_at | dateBR }}
      </template>

      <template #[`item.status`]="{ item }">
        <v-chip
          small
          class="font-weight-semibold text-capitalize"
          :color='item.status === "billed" ? "success" : "secondary"'
        >
          <div v-if="item.status === 'draft'">Rascunho</div>
          <div v-if="item.status === 'pendding'">Pendente</div>
          <div v-if="item.status === 'billed'">Faturado</div>
          <div v-if="item.status === 'cancelled'">Cancelado</div>
        </v-chip>
      </template>

         <template #[`item.actions`]="{ item }">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="openJsonDialog(item.message)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">{{
                    icons.mdiConsole
                  }}</v-icon>
                  <span>Ver Logs</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="openDialogCancel(item.id)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">{{
                    icons.mdiDeleteOutline
                  }}</v-icon>
                  <span>Cancelar</span>
                </v-list-item-title>
              </v-list-item>
               <v-list-item @click="consult(item.id)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">{{
                    icons.mdiFilter
                  }}</v-icon>
                  <span>Consultar</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="reSend(item.id)">
                <v-list-item-title>
                  <v-icon size="20" class="me-2">{{ icons.mdiRestore }}</v-icon>
                  <span>Reenviar</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

      <template #[`footer`] class="d-flex">
        <v-pagination
          v-model="page"
          :length="pageCount"
        ></v-pagination>
      </template>

    </v-data-table>
    <OutgoingInvoicesFilterComponent ref="filterForm" :data="invoices" @filter="f => {filteredInvoices = f; filterApply = true}" />
    <json-dialog ref="jsonDialog"/>
    <cancel-form ref="cancelForm" />
  </div>
</template>

<script>
import Filter from '@/util/Filters'
import { mdiDeleteOutline, mdiDotsVertical, mdiFileDocumentOutline, mdiFilter, mdiPlus, mdiConsole, mdiRestore } from "@mdi/js";

import OutgoingInvoicesFilterComponent from '@/components/company/filters/outgoingInvoicesFilterComponent.vue'
import JsonDialog from '@/components/app/JsonDialog.vue';
import cancelForm from '@/components/outgoingInvoice/forms/cancelForm.vue';

const formatador = Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
})

export default {
  components: {
    OutgoingInvoicesFilterComponent,
    JsonDialog,
    cancelForm,
  },
  name: 'OutgoingInvoicesTab',
  data(){
    return {
      icons: {
        mdiFilter,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiFileDocumentOutline,
        mdiPlus,
        mdiConsole,
        mdiRestore
      },

      searchQuery: null,
      filterApply: false,
      loading: false,

      invoices: [],
      filteredInvoices: [],
      selectedInvoices: [],
      page: null,
      pageCount: null,
      

      // vamos filtrar por data valor status e cliente

      tableColumns: [
        { text: "#", value: "number" },
        { text: "chave", value: "chave"},
        { text: 'Cliente', value: 'customer' },
        { text: 'Valor', value: 'value' },
        { text: 'Data de Emissão', value: 'emited_at' },
        { text: 'Link', value: 'link' },
        { text: 'Status', value: 'status' },
        { text: 'Ações', value: 'actions' }
      ],
    }
  },

  computed: {
    invoiceShow(){
      return this.filterApply ? this.filteredInvoices : this.invoices
    },
    isMobile() {
      return window.innerWidth <= 600
    },
  },

  created(){
    this.getInvoices()
  },

  methods: {
    getInvoices(){
      let company_id = this.$route.params.id;
      this.loading = true
      this.$http.index('/nota-fiscal-saida', { company_id: company_id})
      .then(response =>{
        this.invoices = response.data.data
        this.loading = false
      }).catch(error =>{
        console.log(error)
        this.loading = false
      })
      
    },

    openJsonDialog(item){
      this.$refs.jsonDialog.open(item)
    },

    getIdsOfSelectedsInvoices(){
      var id = [];

      this.selectedInvoices.forEach((item) => {
        id.push(item.id);
      });

      return id;
    },  

    openDialogCancel(id) {
      this.$refs.cancelForm.open(id);
    },

    consult(nota_id){
      this.$http
        .$post("/nota-fiscal-consult", {id: nota_id})
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    reSend(nota_id){
      this.$http
        .$post("/nota-fiscal-resend", {id: nota_id})
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    applyFilter(){
      if(this.filterApply){
        this.filterApply = false
      }else{
        this.filterApply = true
        this.$refs.filterForm.open()
      }
    }

  }

}
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
 transition: all 0.3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
{
  transform: translateX(10px);
  opacity: 0;
}
</style>