<template>
  <v-card>
    <v-card-title class="align-start">
      <span>Ultimas Faturas Iugu</span>
    </v-card-title>
    <v-card-text>
      <v-list class="py-0">
        <v-list-item
          v-for="(data, index) in transactionData"
          :key="data.index"
          class="d-flex align-center px-0"
          :class="index > 0 ? 'mt-4' : ''"
        >
          <v-avatar
            size="40"
            rounded
            :color="paymentMethodVariant(data.payment_method).color"
            :class="`v-avatar-light-bg ${
              paymentMethodVariant(data.payment_method).color
            }--text me-3`"
          >
            <v-img
              max-height="20"
              max-width="20"
              contain
              :src="paymentMethodVariant(data.payment_method).img"
            ></v-img>
          </v-avatar>

          <div class="d-flex align-center flex-grow-1 flex-wrap mr-10">
            <div class="me-2">
              <v-list-item-title class="text-sm font-weight-semibold">
                {{ data.name }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-xs">
                <a :href="data.invoice_url" target="_blank"> Link do Boleto </a>
              </v-list-item-subtitle>
            </div>

            <v-spacer></v-spacer>

            <div class="d-flex align-center">
              <h4 class="text-base font-weight-semibold me-1">
                {{ data.amount / 100 | moneyBr }}
              </h4>
            </div>
          </div>
          <div>
            <v-chip
              small
              :color="statusVariant(data.status).color"
              :class="`${statusVariant(data.status).color}--text`"
              class="v-chip-light-bg font-weight-semibold text-capitalize"
            >
              <div>{{ statusVariant(data.status).text }}</div>
            </v-chip>
          </div>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiDotsVertical, mdiChevronUp, mdiChevronDown } from "@mdi/js";

export default {
  props: {
    iuguPayments: [],
  },
  data() {
    return {
      transactionData: [],

      icons: {
        mdiDotsVertical,
        mdiChevronUp,
        mdiChevronDown,
      },
    };
  },

  watch: {
    iuguPayments: {
      handler: function (value, oldValue) {
        this.transactionData = this.iuguPayments;
      },
      deep: true,
    },
  },

  methods: {
    paymentMethodVariant(paymentMethod) {
      if (paymentMethod == "Money")
        return {
          color: "success",
          img: require("@/assets/images/pages/cash02.png"),
        };
      if (paymentMethod == "Card")
        return {
          color: "warning",
          img: require("@/assets/images/pages/atm-card.png"),
        };
      if (paymentMethod == "Pix")
        return {
          color: "info",
          img: require("@/assets/images/pages/pix.png"),
        };

         return {
          color: "info",
          img: require("@/assets/images/pages/cash02.png"),
        };
        
    },

    statusVariant(status) {
      if (status == "draft") return { color: "secondary", text: "Rascunho" };
      if (status == "paid") return { color: "success", text: "Faturado" };
      if (status == "cancelled") return { color: "error", text: "cancelado" };
      if (status == "pendding") return { color: "warning", text: "Pendente" };

      return { color: "secondary", text: "Rascunho" };
    },
  },
};
</script>
