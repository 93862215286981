<template>
  <v-card flat>
    <v-form v-model="phase" ref="form">
      <div :class="dense ? 'row d-flex' : 'row col-xs'">
        <div :class="dense ? 'col-md-3' : 'col-md'">
          <v-text-field
            v-model="form.cep"
            :rules="[rules.required, rules.cep]"
            v-mask="'#####-###'"
            label="CEP"
            outlined
            :dense="dense"
            hide-details="auto"
            @change="checkCep"
          ></v-text-field>
        </div>
        <div class="col-md">
          <v-text-field
            v-model="form.number"
            :rules="[rules.required]"
            label="Numero"
            outlined
            :dense="dense"
            hide-details="auto"
            :class="dense ? '' : ''"
          ></v-text-field>
        </div>
      </div>
      <div :class="dense ? 'row d-flex' : 'col px-0'">
        <v-text-field
          v-model="form.neighborhood"
          :rules="[rules.required]"
          label="Bairro"
          outlined
          :dense="dense"
          hide-details="auto"
          :class="dense ? 'col-md-4 px-3 py-3' : 'row'"
        ></v-text-field>
        <v-text-field
          v-model="form.street"
          :rules="[rules.required]"
          label="Endereço"
          outlined
          :dense="dense"
          hide-details="auto"
          :class="dense ? 'col-md-8 px-3 py-3' : 'row'"
        ></v-text-field>
      </div>
      <div class="row col-xs">
        <v-text-field
          v-model="form.complement"
          label="Complemento"
          outlined
          :dense="dense"
          hide-details="auto"
          class="ma-3"
        ></v-text-field>
      </div>
      <div class="row col-xs">
        <div class="col-md">
          <v-autocomplete
            v-model="form.state"
            :items="uf"
            :rules="[rules.required]"
            item-text="text"
            item-value="value"
            label="Estado"
            :dense="dense"
            hide-details="auto"
            filled
            outlined
          ></v-autocomplete>
        </div>
        <div class="col-md">
          <v-autocomplete
            v-model="form.city"
            :items="cities"
            :rules="[rules.required]"
            item-text="nome"
            item-value="nome"
            label="Cidade"
            :dense="dense"
            hide-details="auto"
            filled
            outlined
          ></v-autocomplete>
        </div>
      </div>
      <slot></slot>
    </v-form>
  </v-card>
</template>

<script>
import notification from "@/helper/notification";
const axios = require("axios");
import { OPTIONS_UF } from "../../../constants/option";

export default {
  props: {
    initialData: Object,
    dense: true,
  },
  data() {
    return {
      uf: OPTIONS_UF,
      cities: [],
      phase: null,
      rules: {
        required: (v) => !!v || "Campo obrigatório.",
        cep: (v) =>
          /^\d{5}-?\d{3}$/.test(v) || "Insira um numero de CEP valido",
      },
      form: {
        cep: null,
        number: null,
        street: null,
        neighborhood: null,
        complement: null,
        state: null,
        city: null,
        city_code: null,
        country: null,
      },
    };
  },

  mounted() {
    if(this.initialData != null){
      this.form = this.initialData;
      this.getCities()
    }
  },

  watch: {
    form(){
      deep:true
      this.$emit("update", this.form);
    }
  },

  methods: {
    update() {
      this.$emit("update", this.form);
      this.$emit("touched");
    },

    checkCep() {
      axios
        .get(
          "https://brasilapi.com.br/api/cep/v1/" +
            this.form.cep.replace("-", "")
        )
        .then((res) => {
          this.form = {
            cep: this.form.cep,
            state: res.data.state,
            city: res.data.city,
            neighborhood: res.data.neighborhood,
            street: res.data.street,
            country: "Brasil",
          };
          this.getCities();
        })
        .catch((error) => {
          notification.showError(error);
        });
      this.$emit("touched");
    },

    getIbge() {
      let city_code = this.cities.filter((row) => row.nome == this.form.city);

      this.form.city_code = city_code[0].id;
    },

    validate() {
      return this.$refs.form.validate();
    },

    getCities() {
      axios
        .get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${this.form.state}/municipios`
        )
        .then((res) => {
          if (res.status == 200) {
            this.cities = res.data;
            this.getIbge();
          }
        });
    }
  },
};
</script>

<style>
</style>
