<template>
  <v-dialog
    v-model="dialog"
    max-width="800px"
    origin="center center"
    persistent
    :fullscreen="isMobile"
  >
    <v-card>
      <v-card-title> Importar Documento </v-card-title>
      <v-divider></v-divider>
      <v-card-text> </v-card-text>
      <v-form v-model="formValid" ref="form" class="pa-6">
        <div class="row">
          <v-file-input
            label="Selecione o documento..."
            :rules="[ruleRequired]"
            v-model="documentForm.file"
            outlined
            dense
            @change="setFile($event)"
          ></v-file-input>
        </div>
        <div class="row">
          <div class="col-md">
            <v-text-field
              v-model="documentForm.name"
              :rules="[ruleRequired]"
              label="Nome"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </div>
          <div class="col-md-4">
            <v-select
              v-model="documentForm.type"
              :items="type"
              :rules="[ruleRequired]"
              item-value="value"
              item-text="text"
              label="Tipo de documento"
              outlined
              dense
            />
          </div>
          <div class="col-md-3 px-0">
            <date-picker
              ref="duePickerRef"
              v-model="documentForm.due_at"
              label="Validade"
            ></date-picker>
          </div>
        </div>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" class="text-capitalize" @click="close()">
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          class="text-capitalize"
          :disabled="!formValid"
          @click="importDocument()"
        >
          Importar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { format } from "date-fns";
import DatePicker from "./../../app/ui/DatePicker.vue";

export default {
  name: "DocumentForm",

  components: {
    DatePicker,
  },
  data() {
    return {
      dialog: false,
      formValid: null,
      ruleRequired: (v) => !!v || "Campo obrigatório.",

      menus: {
        upload_at: false,
        due_at: false,
      },

      type: [
        { value: "financial", text: "Guias para Pagamento" },
        { value: "others", text: "Documentos gerais" },
        { value: "legal", text: "Documentos legais" },
        { value: "certificate", text: "Certidão negativa" },
        { value: "declaration", text: "Declarações e informes" },
      ],

      file: null,

      documentForm: {
        file: null,
        name: null,
        due_at: null,
        company_id: this.$route.params.id,
      },
    };
  },

  watch: {
    documentForm: {
      handler(val) {
        if (val.due_at == null) {
          this.documentForm.due_at = format(new Date(), "yyyy-MM-dd HH:mm:ss");
        }
      },
      deep: true,
    },
  },

  computed: {
    isMobile() {
      return window.innerWidth <= 600;
    },
  },

  methods: {
    open() {
      this.dialog = true;
    },

    close() {
      this.$refs.form.reset();
      this.dialog = false;
    },

    setFile(event) {
      this.documentForm.name = event.name.substring(0, event.name.length - 4);
      this.file = event;
    },

    importDocument(event) {
      this.loading = true;

      let formData = new FormData();

      formData.append("file", this.file);
      formData.append("name", this.documentForm.name);
      formData.append("due_at", this.documentForm.due_at);
      formData.append("company_id", this.documentForm.company_id);
      formData.append("type", this.documentForm.type);

      this.$http
        .$post("/document", formData)
        .then((response) => {
          this.$refs.form.reset();

          this.$emit("updated");

          this.dialog = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>
