<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="800px"
      origin="center center"
      persistent
      :fullscreen="isMobile"
    >
      <v-card>
        <v-card-title
          >{{ isUpdating ? "Editar" : "Cadastrar" }} Modelo de
          contrato</v-card-title
        >
        <v-divider></v-divider>
        <v-card-title>
          <small></small>
        </v-card-title>

        <v-form v-if="!loading" v-model="formValid" ref="form" >
          <v-card-text>
            <v-row>
              <v-col cols="12 py-0">
                <v-text-field
                  dense
                  outlined
                  label="Nome"
                  v-model="contractModel.name"
                />
              </v-col>
              <v-col>
                <v-text-field
                  dense
                  outlined
                  v-model="contractModel.hash"
                  label="Hash do modelo"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            class="text-capitalize"
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            class="text-capitalize"
            color="success"
            @click="callSubscription()"
          >
            <v-icon>{{ icons.mdiContentSave }}</v-icon>
            Salvar Informações
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mdiContentSave } from "@mdi/js";
import { OPTIONS_INTERVAL_TYPE, OPTIONS_PARCELS } from "@/constants/option";

export default {
  data() {
    return {
      dialog: false,
      value: 2131,

      icons: {
        mdiContentSave,
      },

      intervalTypeOptions: OPTIONS_INTERVAL_TYPE,
      options: OPTIONS_PARCELS,

      contractModel: {
        id: null,
        name: null,
        hash: null,
      },

      isUpdating: false,
      formValid: null,
      loading: false,

      rules: {
        required: (v) => !!v || "Campo obrigatório.",
      },
    };
  },

  computed: {
    isMobile() {
      return window.innerWidth <= 600;
    },
  },

  methods: {
    open(id) {
      if (id) {
        this.contractModel.id = id;
        this.isUpdating = true;
        this.getPlanData(id);
      }

      this.dialog = true;
    },

    async getContractModel(id) {
      await this.$http
        .$get(`/contract-models/${id}`)
        .then((response) => {
          this.contractModel = response;
          this.loading = false;
          this.dialog = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    callSubscription() {
      this.isUpdating == true ? this.updateForm() : this.submitForm();
    },

    submitForm() {
      this.$http
        .$post(`/contract-models`, this.contractModel)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    updateForm() {
      this.$http
        .$put(`/contract-models/${this.contractModel.id}`, this.contractModel)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
